import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LANGUAGES from "./supportedLanguages";
import * as CompanyUserLayout from "./CompanyUserLayout/values";
import * as DashboardPage from "./DashboardPage/values";
import * as EditEmailTemplatePage from "./EditEmailTemplatePage/values";
import * as EditProfilePage from "./EditProfilePage/values";
import * as FeedbackGroupQuestionReport from "./FeedbackGroupQuestionReport/values";
import * as ForgotPasswordPage from "./ForgotPasswordPage/values";
import * as LoginPage from "./LoginPage/values";
import * as NormalGroupQuestionReport from "./NormalGroupQuestionReport/values";
import * as NormalIndividualQuestionReport from "./NormalIndividualQuestionReport/values";
import * as NormalIndividualView from "./NormalIndividualView/values";
import * as ReportsPage from "./ReportsPage/values";
import * as ReportsView from "./ReportsView/values";
import * as SettingsPage from "./SettingsPage/values";
import * as SurveyCampaign360Form from "./SurveyCampaign360Form/values";
import * as SurveyCampaignForm from "./SurveyCampaignForm/values";
import * as SurveyCampaignsPage from "./SurveyCampaignsPage/values";
import * as SurveyCampaignView from "./SurveyCampaignView/values";
import * as SurveyResponsePage from "./SurveyResponsePage/values";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      [LANGUAGES.en.nativeName]: {
        translation: {
          ...CompanyUserLayout.englishValues,
          ...DashboardPage.englishValues,
          ...EditEmailTemplatePage.englishValues,
          ...EditProfilePage.englishValues,
          ...FeedbackGroupQuestionReport.englishValues,
          ...ForgotPasswordPage.englishValues,
          ...LoginPage.englishValues,
          ...NormalGroupQuestionReport.englishValues,
          ...NormalIndividualQuestionReport.englishValues,
          ...NormalIndividualView.englishValues,
          ...ReportsPage.englishValues,
          ...ReportsView.englishValues,
          ...SettingsPage.englishValues,
          ...SurveyCampaign360Form.englishValues,
          ...SurveyCampaignForm.englishValues,
          ...SurveyCampaignsPage.englishValues,
          ...SurveyCampaignView.englishValues,
          ...SurveyResponsePage.englishValues,
        },
      },
      [LANGUAGES.my.nativeName]: {
        translation: {
          ...CompanyUserLayout.malayValues,
          ...DashboardPage.malayValues,
          ...EditEmailTemplatePage.malayValues,
          ...EditProfilePage.malayValues,
          ...FeedbackGroupQuestionReport.malayValues,
          ...ForgotPasswordPage.malayValues,
          ...LoginPage.malayValues,
          ...NormalGroupQuestionReport.malayValues,
          ...NormalIndividualQuestionReport.malayValues,
          ...NormalIndividualView.malayValues,
          ...ReportsPage.malayValues,
          ...ReportsView.malayValues,
          ...SettingsPage.malayValues,
          ...SurveyCampaign360Form.malayValues,
          ...SurveyCampaignForm.malayValues,
          ...SurveyCampaignsPage.malayValues,
          ...SurveyCampaignView.malayValues,
          ...SurveyResponsePage.malayValues,
        },
      },
      [LANGUAGES.cnSimplified.nativeName]: {
        translation: {
          ...CompanyUserLayout.chineseSimplifiedValues,
          ...DashboardPage.chineseSimplifiedValues,
          ...EditEmailTemplatePage.chineseSimplifiedValues,
          ...EditProfilePage.chineseSimplifiedValues,
          ...FeedbackGroupQuestionReport.chineseSimplifiedValues,
          ...ForgotPasswordPage.chineseSimplifiedValues,
          ...LoginPage.chineseSimplifiedValues,
          ...NormalGroupQuestionReport.chineseSimplifiedValues,
          ...NormalIndividualQuestionReport.chineseSimplifiedValues,
          ...NormalIndividualView.chineseSimplifiedValues,
          ...ReportsPage.chineseSimplifiedValues,
          ...ReportsView.chineseSimplifiedValues,
          ...SettingsPage.chineseSimplifiedValues,
          ...SurveyCampaign360Form.chineseSimplifiedValues,
          ...SurveyCampaignForm.chineseSimplifiedValues,
          ...SurveyCampaignsPage.chineseSimplifiedValues,
          ...SurveyCampaignView.chineseSimplifiedValues,
          ...SurveyResponsePage.chineseSimplifiedValues,
        },
      },
      [LANGUAGES.cnTraditional.nativeName]: {
        translation: {
          ...CompanyUserLayout.chineseTraditionalValues,
          ...DashboardPage.chineseTraditionalValues,
          ...EditEmailTemplatePage.chineseTraditionalValues,
          ...EditProfilePage.chineseTraditionalValues,
          ...FeedbackGroupQuestionReport.chineseTraditionalValues,
          ...ForgotPasswordPage.chineseTraditionalValues,
          ...LoginPage.chineseTraditionalValues,
          ...NormalGroupQuestionReport.chineseTraditionalValues,
          ...NormalIndividualQuestionReport.chineseTraditionalValues,
          ...NormalIndividualView.chineseTraditionalValues,
          ...ReportsPage.chineseTraditionalValues,
          ...ReportsView.chineseTraditionalValues,
          ...SettingsPage.chineseTraditionalValues,
          ...SurveyCampaign360Form.chineseTraditionalValues,
          ...SurveyCampaignForm.chineseTraditionalValues,
          ...SurveyCampaignsPage.chineseTraditionalValues,
          ...SurveyCampaignView.chineseTraditionalValues,
          ...SurveyResponsePage.chineseTraditionalValues,
        },
      },
    },
    fallbackLng: LANGUAGES.en.nativeName,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
