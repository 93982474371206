import { PlusOutlined } from "@ant-design/icons";
import { Button, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AgreementCampaign, CompanyUser } from "../../../constants/types";
import { getAgreementCampaigns } from "../../../services/api";
import { parseTime } from "../../../util";

export default function AgreementTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [ageementCampaigns, setAgreementCampaigns] = useState<
    AgreementCampaign[] | []
  >();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Progress",
      dataIndex: "_progress",
      render: (_progress) => {
        if (_progress === 0) {
          return <Tag>{_progress} %</Tag>;
        } else if (_progress < 100) {
          return <Tag color="yellow">{_progress} %</Tag>;
        }
        return <Tag color="green">{_progress} %</Tag>;
      },
    },
    {
      title: "Terms and condition",
      dataIndex: "tncFileLink",
      render: (tncFileLink) => <a href={tncFileLink}>Link</a>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (time) => parseTime(time),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      render: (time) => parseTime(time),
    },
    {
      title: "Action",
      fixed: "right" as "right",
      width: 150,
      render: (companyUser: CompanyUser) => {
        return (
          <a href={`agreement/details/?agreementCampaignId=${companyUser._id}`}>
            Details
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    getAgreementCampaigns()
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setAgreementCampaigns(res.agreementCampaigns);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Link to="/agreement/create">
          <Button type="primary" icon={<PlusOutlined />}>
            Create
          </Button>
        </Link>
      </div>

      <Table
        tableLayout={"auto"}
        loading={isLoading}
        rowKey={"_id"}
        columns={columns}
        scroll={{
          x: columns.length * 150,
        }}
        dataSource={ageementCampaigns}
      />
    </div>
  );
}
