import { NormalIndividualViewKeys } from "./keys";

export const englishValues = {
  [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: "Email",
  [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]: "Actions",
  [NormalIndividualViewKeys.normalIndividualView_viewReportLink]: "View report",
  [NormalIndividualViewKeys.normalIndividualView_selectReportString]:
    "Select a report from the left",
};

export const malayValues = {
  [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: "E-mel",
  [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]:
    "Tindakan",
  [NormalIndividualViewKeys.normalIndividualView_viewReportLink]:
    "Tunjukkan laporan",
  [NormalIndividualViewKeys.normalIndividualView_selectReportString]:
    "Pilih laporan di sebelah kiri",
};

export const chineseSimplifiedValues = {
  [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: "电子邮件",
  [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]: "操作",
  [NormalIndividualViewKeys.normalIndividualView_viewReportLink]: "查看报告",
  [NormalIndividualViewKeys.normalIndividualView_selectReportString]:
    "选择左侧的报告",
};

export const chineseTraditionalValues = {
  [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: "電子郵件",
  [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]: "操作",
  [NormalIndividualViewKeys.normalIndividualView_viewReportLink]: "查看報告",
  [NormalIndividualViewKeys.normalIndividualView_selectReportString]:
    "選擇左側的報告",
};
