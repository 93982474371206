import { Button, Layout } from "antd";
import React from "react";
import { COLORS } from "../../constants/misc";
import UnauthenticatedRoutes from "../../routes/UnauthenticatedRoutes/index";
import i18n from "../../services/i8tn";
import LANGUAGES from "../../services/i8tn/supportedLanguages";

export default function UnauthenticatedLayout(props) {
  return (
    <Layout
      style={{
        height: "100vh",
        paddingTop: 20,
      }}
    >
      <Layout.Content
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
          backgroundColor: "transparent",
        }}
      >
        <UnauthenticatedRoutes />
      </Layout.Content>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          backgroundColor: COLORS.BACKGROUND_COLOR,
          color: "white",
        }}
      >
        {Object.values(LANGUAGES).map((lang) => {
          return (
            <Button
              key={lang.nativeName}
              type="link"
              disabled={lang.isDisabled}
              onClick={() => {
                i18n.changeLanguage(lang.nativeName);
              }}
            >
              {lang?.nativeName}
            </Button>
          );
        })}
      </div>
    </Layout>
  );
}
