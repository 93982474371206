import { Input, List, message, PageHeader, Pagination, Typography } from "antd";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../App";
import SurveyCampaignStatusTag from "../../components/SurveyCampaignStatusTag";
import {
  GetSurveyCampaignByCompanyIdRequest,
  GetSurveyCampaignByCompanyIdResponse,
} from "../../constants/apiRequestResponse";
import { SurveyTemplate } from "../../constants/types";
import {
  getSurveyCampaignsByCompanyId,
  getSurveyTemplates,
} from "../../services/api";
import { ReportsPageKeys } from "../../services/i8tn/ReportsPage/keys";
import { SurveyCampaignKeys } from "../../services/i8tn/SurveyCampaignsPage/keys";
import { getQueryParam, parseTime, setAllQueryParams } from "../../util/index";
import styles from "./SurveyCampaignPage.module.css";
import SurveyCampaignView from "./SurveyCampaignView";
import { Link } from "react-router-dom";

export default function SurveyCampaignsPage() {
  const { t } = useTranslation();
  const context = useContext(SessionContext);
  const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[]>([]);
  const [
    getSurveyCampaignsByCompanyIdResponse,
    setGetSurveyCampaignsByCompanyIdResponse,
  ] = useState<GetSurveyCampaignByCompanyIdResponse | undefined>();
  const [req, setReq] = useState<GetSurveyCampaignByCompanyIdRequest>({
    companyId: context?.company?._id,
    limit: Number(getQueryParam("limit")) || 10,
    skip: Number(getQueryParam("skip")) || 0,
    search: getQueryParam("search") || "",
  });
  const [surveyCampaignId, setSurveyCampaignId] = useState(
    getQueryParam("surveyCampaignId")
  );
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(
    (req: GetSurveyCampaignByCompanyIdRequest) => {
      getSurveyCampaignsByCompanyId(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setGetSurveyCampaignsByCompanyIdResponse(res);
        })
        .catch((err) => {
          message.error(err.message);
        });

      if (surveyTemplates.length === 0) {
        getSurveyTemplates()
          .then((res) => {
            if (!res.success) throw new Error(res.message);
            setSurveyTemplates(res.surveyTemplates);
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    },
    [surveyTemplates.length]
  );

  useEffect(() => {
    getData(req);
  }, [getData, req]);

  const dataSource = getSurveyCampaignsByCompanyIdResponse?.surveyCampaigns.map(
    (sc) => {
      return {
        ...sc,
        surveyTemplateName: surveyTemplates.find(
          (st) => st._id === sc.surveyTemplateId
        )?.name,
      };
    }
  );

  return (
    <div className="page-content">
      <PageHeader
        title={t(SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          gap: 20,
        }}
      >
        <div
          className={styles.card}
          style={{
            width: "30%",
            overflow: "scroll",
          }}
        >
          <Input.Search
            loading={isLoading}
            placeholder="Search for survey campaign"
            defaultValue={req.search}
            style={{ marginBottom: 10 }}
            onSearch={(searchString) => {
              const newReq = {
                ...req,
                skip: 0,
                search: searchString,
              };

              setReq(newReq);
              setAllQueryParams(newReq);

              setIsLoading(true);
              getSurveyCampaignsByCompanyId(newReq)
                .then((res) => {
                  if (!res.success) throw new Error(res.message);

                  setGetSurveyCampaignsByCompanyIdResponse(res);
                })
                .catch((err) => {
                  message.error(err.toString());
                })
                .finally(() => setIsLoading(false));
            }}
          />
          <List
            dataSource={dataSource}
            renderItem={(row) => {
              return (
                <Link
                  to={`/surveycampaign?surveyCampaignId=${row._id}`}
                  onClick={() => {
                    setSurveyCampaignId(row._id);
                  }}
                >
                  <List.Item
                    onClick={() => {
                      console.log("hi");
                    }}
                    key={row._id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            gap: 5,
                          }}
                        >
                          <Typography.Paragraph>
                            {row.name || `(No name provided)`}
                          </Typography.Paragraph>
                          <SurveyCampaignStatusTag status={row.status} />
                        </div>

                        <div>
                          <p style={{ color: "grey" }}>
                            {row.surveyTemplateName}
                          </p>
                          <br />
                          <p
                            style={{
                              fontSize: 10,
                              fontStyle: "italic",
                              color: "grey",
                            }}
                          >
                            {t(ReportsPageKeys.reportsPage_lastUpdateString)}
                            {parseTime(row.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                </Link>
              );
            }}
          />

          {getSurveyCampaignsByCompanyIdResponse ? (
            <Fragment>
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
                total={getSurveyCampaignsByCompanyIdResponse.total}
                defaultCurrent={
                  req?.skip && req?.limit ? req.skip / req.limit + 1 : 1
                }
                defaultPageSize={req.limit}
                onChange={(page, pageSize) => {
                  console.log({ page, pageSize });
                  const newReq = {
                    ...req,
                    limit: pageSize,
                    skip: (page - 1) * (pageSize ? pageSize : 0),
                  };
                  setAllQueryParams(newReq);
                  setReq(newReq);

                  setIsLoading(true);
                  getSurveyCampaignsByCompanyId(newReq)
                    .then((res) => {
                      if (!res.success) throw new Error(res.message);

                      setGetSurveyCampaignsByCompanyIdResponse(res);
                    })
                    .catch((err) => {
                      message.error(err.toString());
                    })
                    .finally(() => setIsLoading(false));
                }}
              />
            </Fragment>
          ) : null}
        </div>

        <div
          className={styles.card}
          style={{
            width: "70%",
            overflow: "scroll",
          }}
        >
          <SurveyCampaignView
            surveyCampaignId={surveyCampaignId}
            onSurveyCampaignUpdated={(surveyCampaign) => {
              setGetSurveyCampaignsByCompanyIdResponse((prev) => ({
                ...getSurveyCampaignsByCompanyIdResponse,
                success: true,
                surveyCampaigns:
                  getSurveyCampaignsByCompanyIdResponse?.surveyCampaigns.map(
                    (sc) => {
                      if (sc._id === surveyCampaign._id) {
                        sc = surveyCampaign;
                      }
                      return sc;
                    }
                  ) || [],
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}
