export const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: "x-tdc-access-token",
  REFRESH_TOKEN: "x-tdc-refresh-token",
  REFRESHING_TOKEN_LOCK: "x-tdc-refreshing-token-lock",
};

export const setAccessToken = (token) => {
  return new Promise((res, rej) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token);
    res(token);
  });
};

export const removeAccessToken = () => {
  return new Promise((res, rej) => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    res();
  });
};

export const getAccessToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
};

export const setRefreshToken = (token) => {
  return new Promise((res, rej) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);
    res(token);
  });
};

export const removeRefreshToken = () => {
  return new Promise((res, rej) => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    res();
  });
};

export const getRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

export const setRefreshingTokenLock = () => {
  return new Promise((res, rej) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHING_TOKEN_LOCK, true);
    res();
  });
};

export const removeRefreshingTokenLock = () => {
  return new Promise((res, rej) => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESHING_TOKEN_LOCK);
    res();
  });
};

export const getRefreshingTokenLock = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESHING_TOKEN_LOCK);
};
