import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

export default function NotFoundPage() {
  return (
    <div
      className="page-content"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img alt="" src={logo} height={50} />
      <h1>Oops! We can't seem to find this page.</h1>
      <Link
        style={{
          color: "black",
          textDecoration: "underline",
        }}
        to="/"
      >
        Go to home
      </Link>
    </div>
  );
}
