import React, { useEffect, useState } from "react";
import { getQueryParam, parseTime } from "../../util";

import { Card, Empty, message, PageHeader, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import EditEmailTemplateForm from "../../components/EditEmailTemplate";
import { GetEmailTemplateByEmailTemplateIdRequest } from "../../constants/apiRequestResponse";
import { EmailTemplate } from "../../constants/types";
import { getEmailTemplateByEmailTemplateId } from "../../services/api";
import { EditEmailTemplatePageKeys } from "../../services/i8tn/EditEmailTemplatePage/keys";
export default function EditEmailTemplatePage() {
  const history = useHistory();
  const emailTemplateId = getQueryParam("emailTemplateId");

  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<
    EmailTemplate | undefined
  >();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    const req1: GetEmailTemplateByEmailTemplateIdRequest = {
      emailTemplateId: emailTemplateId,
    };
    getEmailTemplateByEmailTemplateId(req1)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        setEmailTemplate(res.emailTemplate);
      })
      .catch((err) => message.error(err))
      .finally(() => setIsLoading(false));
  }, [emailTemplateId]);

  if (isLoading) {
    return (
      <div className="page-content">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="page-content">
      {emailTemplate ? (
        <Card>
          <PageHeader
            title={t(
              EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle,
            )}
            subTitle={`Last updated: ${parseTime(emailTemplate?.updatedAt)}`}
            onBack={() => history.push("/settings")}
          />
          <EditEmailTemplateForm isShowFooter={true} />
        </Card>
      ) : (
        <Empty />
      )}
    </div>
  );
}
