import { Card, message, Skeleton, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SessionContext } from "../../App";
import { GetSurveyCampaignByCompanyIdRequest } from "../../constants/apiRequestResponse";
import { COLORS } from "../../constants/misc";
import { SurveyCampaign } from "../../constants/types";
import { getSurveyCampaignsByCompanyId } from "../../services/api";
import { DashboardPageKeys } from "../../services/i8tn/DashboardPage/keys";

export default function RecentSurveyCampaigns() {
  const context = useContext(SessionContext);
  const { t } = useTranslation();

  const [isSurveyCampaignsLoading, setIsSurveyCampaignsLoading] =
    useState(false);
  const [surveyCampaigns, setSurveyCampaigns] = useState<
    SurveyCampaign[] | undefined
  >();

  const getData = useCallback(async () => {
    const req: GetSurveyCampaignByCompanyIdRequest = {
      companyId: context?.company?._id,
      limit: 5,
    };
    getSurveyCampaignsByCompanyId(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setSurveyCampaigns(res.surveyCampaigns);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => setIsSurveyCampaignsLoading(false));
  }, [context]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (isSurveyCampaignsLoading) {
    return <Skeleton active />;
  }

  return (
    <Card
      style={{
        backgroundColor: COLORS.BACKGROUND_COLOR,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title
          level={2}
          style={{ color: COLORS.BACKGROUND_COLOR_DARK, fontWeight: "bold" }}
        >
          {t(DashboardPageKeys.dashboardRecentSurvey_recentSurveyString)}
        </Typography.Title>
        <Link to="/surveycampaign">
          <>{t(DashboardPageKeys.dashboardRecentSurvey_viewAllString)}</>
        </Link>
      </div>

      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
        }}
      >
        {surveyCampaigns?.map((sc) => {
          return (
            <Link
              key={sc._id}
              to={`/surveycampaign/?surveyCampaignId=${sc._id}`}
            >
              <div
                style={{
                  marginBottom: 10,
                  backgroundColor: COLORS.BACKGROUND_COLOR,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <p style={{ color: COLORS.PRIMARY_COLOR }}>{sc.name}</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </Card>
  );
}
