import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashboardPage from "../../pages/DashboardPage/index";
import EditEmailTemplatePage from "../../pages/EditEmailTemplatePage";
import EditProfilePage from "../../pages/EditProfilePage";
import NotFoundPage from "../../pages/NotFoundPage";
import ReportsPage from "../../pages/ReportsPage";
import ReportsViewPage from "../../pages/ReportViewPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage/index";
import SettingsPage from "../../pages/SettingsPage";
import SurveyCampaign360FormPage from "../../pages/SurveyCampaign360FormPage";
import SurveyCampaignFormPage from "../../pages/SurveyCampaignFormPage";
import SurveyCampaignsPage from "../../pages/SurveyCampaignsPage/index";

export default function CompanyUserRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={DashboardPage} />

        <Route
          exact
          path="/surveycampaign/create/:surveyTemplateName"
          component={SurveyCampaignFormPage}
        />

        <Route
          exact
          path="/surveycampaign/edit"
          component={SurveyCampaignFormPage}
        />

        <Route
          exact
          path="/surveycampaign/create/360/:surveyTemplateName"
          component={SurveyCampaign360FormPage}
        />

        <Route exact path="/surveycampaign" component={SurveyCampaignsPage} />

        <Route exact path="/report" component={ReportsPage} />

        <Route exact path="/report/view" component={ReportsViewPage} />

        <Route exact path="/profile" component={EditProfilePage} />

        <Route exact path="/settings" component={SettingsPage} />

        <Route
          exact
          path="/settings/emailtemplate"
          component={EditEmailTemplatePage}
        />

        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
