import { SettingsPageKeys } from "./keys";

export const englishValues = {
  [SettingsPageKeys.settingsPage_settingsTitle]: "Settings",
  [SettingsPageKeys.settingsPage_emailTemplatesString]: "Email templates",
  [SettingsPageKeys.settingsPage_manageTemplatesString]:
    "Manage email templates to be sent out for each survey campaign",
  [SettingsPageKeys.settingsPage_nameTableHeader]: "Name",
  [SettingsPageKeys.settingsPage_templateTableHeader]: "Linked survey template",
  [SettingsPageKeys.settingsPage_createdTableHeader]: "Created",
  [SettingsPageKeys.settingsPage_actionTableHeader]: "Action",
};

export const malayValues = {
  [SettingsPageKeys.settingsPage_settingsTitle]: "Tetapan",
  [SettingsPageKeys.settingsPage_emailTemplatesString]: "Templat e-mel",
  [SettingsPageKeys.settingsPage_manageTemplatesString]:
    "Mengurus templat e-mel yang akan dihantar untuk setiap kempen tinjauan",
  [SettingsPageKeys.settingsPage_nameTableHeader]: "Nama",
  [SettingsPageKeys.settingsPage_templateTableHeader]:
    "Templat tinjauan berpautan",
  [SettingsPageKeys.settingsPage_createdTableHeader]: "Dicipta pada",
  [SettingsPageKeys.settingsPage_actionTableHeader]: "Tindakan",
};

export const chineseSimplifiedValues = {
  [SettingsPageKeys.settingsPage_settingsTitle]: "设置",
  [SettingsPageKeys.settingsPage_emailTemplatesString]: "电子邮件模板",
  [SettingsPageKeys.settingsPage_manageTemplatesString]:
    "管理发送给每个调查活动的电子邮件模板",
  [SettingsPageKeys.settingsPage_nameTableHeader]: "名称",
  [SettingsPageKeys.settingsPage_templateTableHeader]: "关联调查模板",
  [SettingsPageKeys.settingsPage_createdTableHeader]: "创建于",
  [SettingsPageKeys.settingsPage_actionTableHeader]: "操作",
};

export const chineseTraditionalValues = {
  [SettingsPageKeys.settingsPage_settingsTitle]: "設置",
  [SettingsPageKeys.settingsPage_emailTemplatesString]: "電子郵件模板",
  [SettingsPageKeys.settingsPage_manageTemplatesString]:
    "管理發送給每個調查活動的電子郵件模板",
  [SettingsPageKeys.settingsPage_nameTableHeader]: "名稱",
  [SettingsPageKeys.settingsPage_templateTableHeader]: "關聯調查模板",
  [SettingsPageKeys.settingsPage_createdTableHeader]: "創建於",
  [SettingsPageKeys.settingsPage_actionTableHeader]: "操作",
};
