import { Button, Form, Input } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TEST_IDS from "../../constants/cypressTestIds";
import { SurveyCampaign } from "../../constants/types";
import { SurveyCampaign360FormKeys } from "../../services/i8tn/SurveyCampaign360Form/keys";
import { parseDate } from "../../util";

interface Props {
  onFinish: Function;
  onBack: () => void;
  onNext: () => void;
  onChangeSurveyCampaign: (surveyCampaign: SurveyCampaign) => void;
  surveyCampaign?: SurveyCampaign;
}

enum CheckingUniqueStatus {
  default,
  checking,
  isUnique,
  isNotUnique,
}

export default function BasicInfo(props: Props) {
  const [checkingUniqueStatus, setCheckingUniqueStatus] = useState(
    CheckingUniqueStatus.default,
  );
  const { t } = useTranslation();

  return (
    <div>
      <Form
        initialValues={props.surveyCampaign}
        layout="vertical"
        onFieldsChange={() => {
          setCheckingUniqueStatus(CheckingUniqueStatus.default);
        }}
        onFinish={async (v) => {
          props.onChangeSurveyCampaign({
            ...props.surveyCampaign,
            ...v,
          });
          props.onNext();
        }}
      >
        <Form.Item
          data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.campaign_name_label_id}
          label={t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_campaignNameString,
          )}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={`e.g. My Survey ${parseDate(moment.now())}`} />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 30,
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              disabled={
                checkingUniqueStatus === CheckingUniqueStatus.isNotUnique
              }
            >
              <>{t(SurveyCampaign360FormKeys.surveyCampaign360Form_nextBtn)}</>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
