import { FeedbackGroupQuestionReportKeys } from "./keys";

export const englishValues = {
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]:
    "Group Report",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]:
    "Survey Campaign",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]:
    "Survey",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]:
    "Last updated",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]:
    "Download as PDF",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]:
    "Comparison",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]:
    "Email",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]:
    "Actions",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]:
    "View report",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]:
    "Position",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]:
    "Last updated",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]:
    "Select a report from the left",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]:
    "Individual Report",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]:
    "Gender",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]:
    "Email",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]:
    "Response ID",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]:
    "Survey Campaign",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]:
    "Survey",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]:
    "Last updated",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]:
    "Download as PDF",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]:
    "Comparison",
};

export const malayValues = {
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]:
    "Laporan Kumpulan",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]:
    "Kempen Tinjauan",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]:
    "Tinjauan",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]:
    "Dikemas kini pada",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]:
    "Muat turun sebagai PDF",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]:
    "Perbandingan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]:
    "E-mel",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]:
    "Tindakan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]:
    "Tunjukkan laporan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]:
    "Jawatan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]:
    "Dikemas kini pada",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]:
    "Pilih laporan di sebelah kiri",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]:
    "Laporan Individu",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]:
    "Jantina",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]:
    "E-mel",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]:
    "ID Respons",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]:
    "Kempen Tinjauan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]:
    "Tinjauan",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]:
    "Dikemas kini pada",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]:
    "Muat turun sebagai PDF",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]:
    "Perbandingan",
};

export const chineseSimplifiedValues = {
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]:
    "组报告",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]:
    "调查活动",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]:
    "调查",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]:
    "更新于",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]:
    "下载为PDF",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]:
    "比较",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]:
    "电子邮件",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]:
    "操作",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]:
    "查看报告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]:
    "职位",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]:
    "更新于",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]:
    "选择左侧的报告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]:
    "个人报告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]:
    "性别",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]:
    "电子邮件",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]:
    "响应ID",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]:
    "调查活动",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]:
    "调查",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]:
    "更新于",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]:
    "下载为PDF",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]:
    "比较",
};
export const chineseTraditionalValues = {
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]:
    "組報告",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]:
    "調查活動",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]:
    "調查",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]:
    "更新於",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]:
    "下載為PDF",
  [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]:
    "比較",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]:
    "電子郵件",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]:
    "操作",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]:
    "查看報告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]:
    "職位",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]:
    "更新於",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]:
    "選擇左側的報告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]:
    "個人報告",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]:
    "性別",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]:
    "電子郵件",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]:
    "響應ID",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]:
    "調查活動",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]:
    "調查",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]:
    "更新於",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]:
    "下載為PDF",
  [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]:
    "比較",
};
