// https://docs.cypress.io/guides/references/best-practices#Selecting-Elements
const TEST_IDS = {
  DASHBOARD: {
    survey_template_id: "survey_template_id",
  },
  SURVEY_CAMPAIGN_FORM: {
    campaign_name_label_id: "campaign_name_label_id",
    add_an_employee_btn_id: "add_an_employee_btn_id",
    preview_and_send_label_id: "preview_and_send_label_id",
    edit_email_template_id: "edit_email_template_id",
    save_email_template_id: "save_email_template_id",
  },
  SURVEY_CAMPAIGN_VIEW: {
    survey_link_id: "survey_link_id",
    more_id: "more_id",
    status_id: "status_id",
    status_filter_id: "status_filter_id",
    deleted_status_filter_id: "deleted_status_filter_id",
    all_status_filter_id: "all_status_filter_id",
    survey_template_filter_id: "survey_template_filter_id",
    search_bar_id: "search_bar_id",
    search_button_id: "search_button_id",
  },
  SURVEY_RESPONSE_PAGE: {
    start_survey_button_id: "start_survey_button_id",
    question_container_id: "question_container_id",
    question_id: "question_id",
    answer_btn_id: "answer_btn_id",
    answer_text_id: "answer_text_id",
    submit_btn_id: "submit_btn_id",
    confirm_submit_btn_id: "confirm_submit_btn_id",
  },
  REPORT_PAGE: {
    group_download_as_pdf_button_id: "group_download_as_pdf_button_id",
    individual_download_as_pdf_button_id:
      "individual_download_as_pdf_button_id",
  },
  REPORT_PRINT_PAGE: {
    report_type_text_id: "report_type_text_id",
    survey_template_name: "survey_template_name",
  },
  HEADER: {
    profile_container_id: "profile_container_id",
  },
  SETTINGS_PAGE: {
    survey_template_edit_button_id: "survey_template_edit_button_id",
  },
  EDIT_EMAIL_TEMPLATE_PAGE: {
    subject_input_id: "subject_input_id",
    content_input_id: "content_input_id",
  },
};
export default TEST_IDS;
