import React, { useEffect, useState } from "react";
import { getQueryParam } from "../../util";

import { Button, Empty, Input, message, Modal, Skeleton, Table } from "antd";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useHistory } from "react-router-dom";
import {
  GetEmailTemplateByEmailTemplateIdRequest,
  GetEmailTemplateVariablesRequest,
  GetSurveyTemplatesBySurveyTemplateIdRequest,
  UpdateEmailTemplateRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import {
  EmailTemplate,
  EmailVariables,
  SurveyTemplate,
} from "../../constants/types";
import {
  getEmailTemplateByEmailTemplateId,
  getEmailTemplateVariables,
  getSurveyTemplateBySurveyTemplateId,
  updateEmailTemplate,
} from "../../services/api";
import { EditEmailTemplatePageKeys } from "../../services/i8tn/EditEmailTemplatePage/keys";

interface Props {
  isShowFooter: boolean;
  onUpdate?: (editorState: string | undefined) => void;
}

export default function EditEmailTemplateForm(props: Props) {
  const history = useHistory();
  const emailTemplateId = getQueryParam("emailTemplateId");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [variables, setVariables] = useState<EmailVariables | undefined>();
  const [emailTemplate, setEmailTemplate] = useState<
    EmailTemplate | undefined
  >();
  const [editorState, setEditorState] = useState<string | undefined>();
  const [surveyTemplate, setSurveyTemplate] = useState<
    SurveyTemplate | undefined
  >();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    const req1: GetEmailTemplateByEmailTemplateIdRequest = {
      emailTemplateId: emailTemplateId,
    };
    getEmailTemplateByEmailTemplateId(req1)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        setEmailTemplate(res.emailTemplate);
        setEditorState(res.emailTemplate?.content);

        const req2: GetSurveyTemplatesBySurveyTemplateIdRequest = {
          surveyTemplateId: res.emailTemplate?.surveyTemplateId,
        };

        return getSurveyTemplateBySurveyTemplateId(req2);
      })
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        setSurveyTemplate(res.surveyTemplate);
      })
      .catch((err) => message.error(err))
      .finally(() => setIsLoading(false));

    const req3: GetEmailTemplateVariablesRequest = {};
    getEmailTemplateVariables(req3)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setVariables(res.emailTemplateVariables);
      })
      .catch((err) => message.error(err));
  }, [emailTemplateId]);

  const onSave = () => {
    if (!emailTemplate) return null;

    setIsUpdateLoading(true);

    emailTemplate.content = editorState || "";

    const req: UpdateEmailTemplateRequest = {
      emailTemplate: emailTemplate,
    };

    updateEmailTemplate(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        message.success("Updated");
        history.push("/settings");
      })
      .catch((err) => message.error(err))
      .finally(() => setIsUpdateLoading(false));
  };

  const dataSource = variables
    ? Object.keys(variables).map((key) => {
        return {
          key: key,
          ...variables[key],
        };
      })
    : [];

  if (isLoading) {
    return (
      <div className="page-content">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div>
      {emailTemplate ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 30,
            }}
          >
            <div
              style={{
                flex: 4,
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                }}
              >
                <span>
                  <b>
                    {t(EditEmailTemplatePageKeys.editEmailTemplate_nameString)}
                  </b>
                  <p>{emailTemplate.name}</p>
                </span>
                <span>
                  <b>
                    {t(
                      EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString,
                    )}
                  </b>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`/surveycampaign/create/${surveyTemplate?.name}?step=0`}
                    >
                      {surveyTemplate?.name}
                    </a>
                  </p>
                </span>
                <span>
                  <b>
                    {t(
                      EditEmailTemplatePageKeys.editEmailTemplate_subjectString,
                    )}
                  </b>
                  <Input
                    data-testid={
                      TEST_IDS.EDIT_EMAIL_TEMPLATE_PAGE.subject_input_id
                    }
                    value={emailTemplate.subject}
                    onChange={(e) => {
                      setEmailTemplate({
                        ...emailTemplate,
                        subject: e.target.value,
                      });
                    }}
                  />
                </span>

                <span>
                  <b>
                    {t(
                      EditEmailTemplatePageKeys.editEmailTemplate_contentString,
                    )}
                  </b>
                  <ReactQuill
                    data-testid={
                      TEST_IDS.EDIT_EMAIL_TEMPLATE_PAGE.content_input_id
                    }
                    style={{ height: "80%" }}
                    theme="snow"
                    value={editorState}
                    onChange={(v) => {
                      setEditorState(v);
                      props.onUpdate && props.onUpdate(editorState);
                    }}
                  />
                </span>
              </div>
            </div>

            <div
              style={{
                flex: 2,
              }}
            >
              <b>
                {t(EditEmailTemplatePageKeys.editEmailTemplate_variablesString)}
              </b>
              <p>
                <i>
                  {t(
                    EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString,
                  )}
                </i>
              </p>
              <Table
                dataSource={dataSource}
                size="small"
                bordered
                columns={[
                  {
                    title: (
                      <>
                        {t(
                          EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader,
                        )}
                      </>
                    ),
                    dataIndex: "key",
                    render: (value) => <i>{value}</i>,
                  },
                  {
                    title: (
                      <>
                        {t(
                          EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader,
                        )}
                      </>
                    ),
                    dataIndex: "description",
                  },
                ]}
                pagination={false}
              />
            </div>
          </div>
          {props.isShowFooter ? (
            <div
              style={{
                display: "flex",
                gap: 20,
                paddingTop: 10,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link to="/settings">
                <>{t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}</>
              </Link>
              <Button
                loading={isUpdateLoading}
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    content: (
                      <p>
                        {t(
                          EditEmailTemplatePageKeys.editEmailTemplate_savePrompt,
                        )}
                      </p>
                    ),
                    onOk: () => onSave(),
                  });
                }}
              >
                <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
}
