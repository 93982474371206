type Languages = {
  [key: string]: Language;
};

export type Language = {
  nativeName: string;
  flag: string;
  isDisabled: boolean;
  isDefault: boolean;
};

const LANGUAGES: Languages = {
  en: {
    nativeName: "English",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg",
    isDisabled: false,
    isDefault: true,
  },
  my: {
    nativeName: "Melayu",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MY.svg",
    isDisabled: false,
    isDefault: false,
  },
  cnSimplified: {
    nativeName: "简体中文",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg",
    isDisabled: false,
    isDefault: false,
  },
  cnTraditional: {
    nativeName: "繁体中文",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/TW.svg",
    isDisabled: false,
    isDefault: false,
  },
};

export default LANGUAGES;
