import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  message,
  Skeleton,
  Table,
  Typography,
  Tooltip,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../App";
import EditEmailTemplateForm from "../../components/EditEmailTemplate";
import {
  GetEmailTemplateByCompanyIdRequest,
  UpdateEmailTemplateRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import {
  EmailTemplate,
  SurveyCampaign,
  SurveyEmployee,
} from "../../constants/types";
import {
  getEmailTemplatesByCompanyId,
  updateEmailTemplate,
} from "../../services/api";
import { setQueryParam, stringParser } from "../../util";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { EditEmailTemplatePageKeys } from "../../services/i8tn/EditEmailTemplatePage/keys";

interface PreviewInterface {
  surveyEmployees?: SurveyEmployee[];
  surveyCampaign?: SurveyCampaign;
  onBack: Function;
  onSend: Function;
  onSave: Function;
}

type EmailInputParams = {
  currentUserName?: string;
  companyName?: string;
  surveyCampaignName?: string;
  link?: string;
  email?: string;
};
export default function Preview(props: PreviewInterface) {
  const context = useContext(SessionContext);

  const [surveyEmployees, setSurveyEmployees] = useState<
    SurveyEmployee[] | undefined
  >([]);
  const [emailTemplate, setEmailTemplate] = useState<
    EmailTemplate | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isEditEmailTemplate, setIsEditEmailTemplate] = useState(false);
  const [editorState, setEditorState] = useState<string | undefined>();
  const [selectedSurveyEmployee, setSelectedSurveyEmployee] = useState<
    SurveyEmployee | undefined
  >();

  const { t } = useTranslation();

  const getData = useCallback(() => {
    setSurveyEmployees(props.surveyEmployees);

    const se = props.surveyEmployees && props.surveyEmployees[0];
    setSelectedSurveyEmployee(se);

    setIsLoading(true);
    const req: GetEmailTemplateByCompanyIdRequest = {
      companyId: context?.company?._id,
    };
    getEmailTemplatesByCompanyId(req)
      .then((res) => {
        let _emailTemplate = res.emailTemplates?.find((et) => {
          return et.surveyTemplateId === props.surveyCampaign?.surveyTemplateId;
        });

        if (!_emailTemplate) return null;

        const inputParams: EmailInputParams = {
          currentUserName: `${se?.fname} ${se?.lname}`,
          companyName: `${context?.company?.name}`,
          surveyCampaignName: props.surveyCampaign?.name,
          email: context?.company?.helpEmail,
        };
        _emailTemplate.content = stringParser(
          _emailTemplate?.content || "",
          inputParams,
        );
        setEmailTemplate(_emailTemplate);
      })
      .finally(() => setIsLoading(false));
  }, [props.surveyEmployees, props.surveyCampaign, context?.company]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSave = () => {
    if (!emailTemplate) return null;

    setIsUpdateLoading(true);

    emailTemplate.content = editorState || "";

    const req: UpdateEmailTemplateRequest = {
      emailTemplate: emailTemplate,
    };

    updateEmailTemplate(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        message.success("Updated");
        setIsEditEmailTemplate(false);
        getData();
      })
      .catch((err) => message.error(err))
      .finally(() => setIsUpdateLoading(false));
  };

  const columns = [
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader,
          )}
        </>
      ),
      dataIndex: "fname",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader,
          )}
        </>
      ),
      dataIndex: "lname",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader,
          )}
        </>
      ),
      dataIndex: "position",
    },
    {
      title: (
        <>
          {t(SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader)}
        </>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <>
          {t(SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader)}
        </>
      ),
      dataIndex: "team",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader,
          )}
        </>
      ),
      dataIndex: "gender",
    },
  ];

  return (
    <div>
      <Typography.Title
        data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.preview_and_send_label_id}
        level={3}
      >
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString,
          )}
        </>
      </Typography.Title>
      <h4 style={{ color: "grey" }}>
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString,
          )}
        </>{" "}
        ({surveyEmployees?.length})
      </h4>
      <Table
        rowKey="_id"
        size="small"
        columns={columns}
        dataSource={surveyEmployees}
        pagination={false}
      />

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10,
          paddingBottom: 10,
        }}
      >
        <h4>
          {`${t(SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString)} ${selectedSurveyEmployee?.fname} ${selectedSurveyEmployee?.lname} (${selectedSurveyEmployee?.position})`}
        </h4>
        {isEditEmailTemplate ? (
          <div>
            <Button type="link" onClick={() => setIsEditEmailTemplate(false)}>
              <>{t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}</>
            </Button>
            <Button
              loading={isUpdateLoading}
              type="primary"
              onClick={() => onSave()}
              data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.save_email_template_id}
            >
              <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
            </Button>
          </div>
        ) : (
          <Tooltip
            title={t(
              SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown,
            )}
          >
            <EditOutlined
              style={{
                color: COLORS.PRIMARY_COLOR,
                fontSize: 18,
              }}
              data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.edit_email_template_id}
              onClick={() => {
                setQueryParam("emailTemplateId", emailTemplate?._id);
                setIsEditEmailTemplate(true);
              }}
            />
          </Tooltip>
        )}
      </div>
      <Card>
        {isLoading ? (
          <Skeleton />
        ) : isEditEmailTemplate ? (
          <EditEmailTemplateForm
            isShowFooter={false}
            onUpdate={(_editorState) => setEditorState(_editorState)}
          />
        ) : (
          <div
            style={{
              maxHeight: 600,
              overflow: "scroll",
            }}
            dangerouslySetInnerHTML={{ __html: emailTemplate?.content || "" }}
          ></div>
        )}
      </Card>

      <Typography.Title level={3}>
        <>{t(SurveyCampaignFormKeys.surveyCampaignForm_faqString)}</>
      </Typography.Title>
      <Collapse>
        <Collapse.Panel
          header={t(SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString)}
          key="1"
        >
          <p>
            <>{t(SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString)}</>
          </p>
        </Collapse.Panel>
      </Collapse>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Button onClick={(e) => props.onBack()} type="link">
          <>{t(SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn)}</>
        </Button>
        <div style={{ display: "flex", gap: 10 }}>
          <Button onClick={(e) => props.onSave()}>
            <>{t(SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn)}</>
          </Button>
          <Button
            onClick={(e) => props.onSend()}
            type="primary"
            disabled={isEditEmailTemplate}
          >
            <>{t(SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn)}</>
          </Button>
        </div>
      </div>
    </div>
  );
}
