import { LoginPageKeys } from "./keys";

export const englishValues = {
  [LoginPageKeys.loginPage_loginPrompt]: "Login",
  [LoginPageKeys.loginPage_emailPlaceholder]: "Email",
  [LoginPageKeys.loginPage_passwordPlaceholder]: "Password",
  [LoginPageKeys.loginPage_loginBtn]: "Log In",
  [LoginPageKeys.loginPage_forgetPasswordBtn]: "Forget password?",
};

export const malayValues = {
  [LoginPageKeys.loginPage_loginPrompt]: "Log masuk",
  [LoginPageKeys.loginPage_emailPlaceholder]: "E-mel",
  [LoginPageKeys.loginPage_passwordPlaceholder]: "Kata laluan",
  [LoginPageKeys.loginPage_loginBtn]: "Log masuk",
  [LoginPageKeys.loginPage_forgetPasswordBtn]: "Terlupa kata laluan?",
};
export const chineseSimplifiedValues = {
  [LoginPageKeys.loginPage_loginPrompt]: "登录",
  [LoginPageKeys.loginPage_emailPlaceholder]: "电子邮件",
  [LoginPageKeys.loginPage_passwordPlaceholder]: "密码",
  [LoginPageKeys.loginPage_loginBtn]: "登录",
  [LoginPageKeys.loginPage_forgetPasswordBtn]: "忘记密码？",
};

export const chineseTraditionalValues = {
  [LoginPageKeys.loginPage_loginPrompt]: "登錄",
  [LoginPageKeys.loginPage_emailPlaceholder]: "電子郵件",
  [LoginPageKeys.loginPage_passwordPlaceholder]: "密碼",
  [LoginPageKeys.loginPage_loginBtn]: "登錄",
  [LoginPageKeys.loginPage_forgetPasswordBtn]: "忘記密碼？",
};
