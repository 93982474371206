import { ForgotPasswordPageKeys } from "./keys";

export const englishValues = {
  [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]:
    "Forgot password?",
  [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]:
    "Enter your email and we will send you a link to reset your password.",
  [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: "Email",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: "Back to login",
  [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: "Send",
  [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]:
    "We've sent the link to your email.",
  [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]:
    "Click on it to reset your password.",
  [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]:
    "The link expires within 24 hours.",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]: "Back to login",
};

export const malayValues = {
  [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]:
    "Terlupa kata laluan?",
  [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]:
    "Sila masukkan e-mel anda dan kami akan menghantar pautan untuk menetap semula kata laluan anda.",
  [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: "E-mel",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: "Kembali",
  [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: "Hantar",
  [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]:
    "Kami sudah menghantar pautan ke alamat e-mel anda.",
  [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]:
    "Sila tekan pautan tersebut untuk menetap semula kata laluan.",
  [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]:
    "Pautan ini akan luput dalam tempoh 24 jam.",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]:
    "Kembali ke log masuk",
};

export const chineseSimplifiedValues = {
  [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]:
    "忘记密码？",
  [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]:
    "请输入您的电子邮件，我们将发送链接以重置您的密码。",
  [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: "电子邮件",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: "返回",
  [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: "发送",
  [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]:
    "我们已经发送链接到您的电子邮件地址。",
  [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]:
    "请点击链接以重置您的密码。",
  [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]:
    "该链接将在24小时内过期。",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]: "返回登录",
};

export const chineseTraditionalValues = {
  [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]:
    "忘記密碼？",
  [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]:
    "請輸入您的電子郵件，我們將發送鏈接以重置您的密碼。",
  [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: "電子郵件",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: "返回",
  [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: "發送",
  [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]:
    "我們已經發送鏈接到您的電子郵件地址。",
  [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]:
    "請點擊鏈接以重置您的密碼。",
  [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]:
    "該鏈接將在24小時內過期。",
  [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]: "返回登錄",
};
