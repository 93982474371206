import { Card } from "antd";
import React from "react";
import FeedbackGroupQuestionReport from "../../components/QuestionReport/FeedbackGroupQuestionReport";

interface Prop {
  surveyCampaignId?: string;
}

export default function FeedbackGroupView(props: Prop) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <Card
        style={{
          width: "60%",
        }}
      >
        <FeedbackGroupQuestionReport
          surveyCampaignId={props.surveyCampaignId}
        />
      </Card>
    </div>
  );
}
