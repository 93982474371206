import {
  EditOutlined,
  LoadingOutlined,
  MailOutlined,
  MoreOutlined,
  QuestionCircleFilled,
  RollbackOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Dropdown,
  Empty,
  Menu,
  Modal,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../App";
import PositionsTag from "../../components/PositionsTag";
import SurveyCampaignStatusTag from "../../components/SurveyCampaignStatusTag";
import SurveyResponseStatusTag from "../../components/SurveyResponseStatusTag";
import {
  DeleteSurveyCampaignRequest,
  FinishSurveyCampaignRequest,
  GetSurveyCampaignBySurveyCampaignIdRequest,
  GetSurveyResponseBySurveyCampaignIdRequest,
  GetSurveyTemplatesBySurveyTemplateIdRequest,
  ResendSurveyResponseEmailRequest,
  StartSurveyCampaignRequest,
  UndeleteSurveyCampaignRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import {
  FeatureFlagStatus,
  FeatureFlagTemplateKey,
  SurveyCampaign,
  SurveyCampaignStatusLabels,
  SurveyResponse,
  SurveyResponseStatusLabels,
  SurveyTemplate,
  SurveyTemplateType,
} from "../../constants/types";
import {
  deleteSurveyCampaign,
  finishSurveyCampaign,
  getSurveyCampaignBySurveyCampaignId,
  getSurveyResponsesBySurveyCampaignId,
  getSurveyTemplateBySurveyTemplateId,
  resendSurveyResponseEmail,
  startSurveyCampaign,
  undeleteSurveyCampaign,
} from "../../services/api";
import { SurveyCampaignKeys } from "../../services/i8tn/SurveyCampaignsPage/keys";
import { SurveyCampaignViewKeys } from "../../services/i8tn/SurveyCampaignView/keys";
import { getQueryParam, parseTime } from "../../util/index";

interface Prop {
  surveyCampaignId?: string;
  onSurveyCampaignUpdated: (surveyCampaign: SurveyCampaign) => void;
}

type QueryValues = {
  surveySearchString: string;
  surveyStatus: string;
  surveyCampaignId?: string;
};

export default function SurveyCampaignView(props: Prop) {
  const context = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);
  const [surveyCampaign, setSurveyCampaign] = useState<
    SurveyCampaign | undefined
  >();
  const [surveyTemplate, setSurveyTemplate] = useState<
    SurveyTemplate | undefined
  >();
  const [surveyResponses, setSurveyResponses] = useState<
    SurveyResponse[] | null
  >();
  const [selectedSurveyIds, setSelectedSurveyIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const queryValues: QueryValues = {
    surveySearchString: getQueryParam("surveySearchString") || "",
    surveyStatus: getQueryParam("surveyStatus") || "All",
    surveyCampaignId: getQueryParam("surveyCampaignId") || "",
  };

  const getData = useCallback(async () => {
    if (!props.surveyCampaignId) return null;
    if (props.surveyCampaignId === surveyCampaign?._id) return null;

    try {
      setIsLoading(true);

      const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
        surveyCampaignId: props.surveyCampaignId,
      };
      const res1 = await getSurveyCampaignBySurveyCampaignId(req1);
      if (!res1.success) throw res1.message;
      setSurveyCampaign(res1.surveyCampaign);

      const req2: GetSurveyTemplatesBySurveyTemplateIdRequest = {
        surveyTemplateId: res1.surveyCampaign.surveyTemplateId,
      };
      const res2 = await getSurveyTemplateBySurveyTemplateId(req2);
      if (!res2.success) throw res2.message;
      setSurveyTemplate(res2.surveyTemplate);

      const req3: GetSurveyResponseBySurveyCampaignIdRequest = {
        surveyCampaignId: props.surveyCampaignId,
      };
      const res3 = await getSurveyResponsesBySurveyCampaignId(req3);

      if (!res3.success) throw res3.message;

      setSurveyResponses(res3.surveyResponses);

      setIsLoading(false);
    } catch (err) {
      message.error(
        typeof err === "string"
          ? err
          : typeof err === "object"
          ? err?.toString()
          : `Something went wrong`
      );
      setIsLoading(false);
    }
  }, [props.surveyCampaignId, surveyCampaign?._id]);

  useEffect(() => {
    getData();
  }, [getData, props.surveyCampaignId]);

  if (!props.surveyCampaignId || !surveyCampaign) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <Typography.Title level={5}>
          <>{t(SurveyCampaignKeys.surveyCampaigns_selectFromLeftString)}</>
        </Typography.Title>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoadingOutlined style={{ fontSize: 10 }} />
      </div>
    );
  }

  function onResendSurveyResponses(surveyIds: string[]) {
    const surveysToResend =
      surveyIds
        .map((surveyResponseId) =>
          surveyResponses?.find((s) => s._id === surveyResponseId)
        )
        .filter((survey) => {
          return survey?._isAbleToResend;
        }) || [];

    Modal.confirm({
      title:
        surveysToResend.length > 0
          ? "Confirm re-send email?"
          : "Selected surveys cannot be re-sent",
      content:
        surveysToResend.length > 0 ? (
          <>
            <p>{surveysToResend.length} emails would be sent out.</p>
            <ul>
              {surveysToResend.map((s) => {
                return <li key={s?._id}>{s?.surveyEmployee.email}</li>;
              })}
            </ul>
          </>
        ) : null,
      onOk: () => {
        const req: ResendSurveyResponseEmailRequest = {
          surveyResponseIds: surveysToResend.map((s) => s?._id || ""),
        };
        resendSurveyResponseEmail(req)
          .then((res) => {
            if (!res.success) throw new Error(res.message);
            message.success(`Resend scheduled`);

            const newSurveyResponses = surveyResponses?.map((sr) => {
              if (surveyIds.includes(sr._id)) {
                sr.status = SurveyResponseStatusLabels.PENDING;
              }
              return sr;
            });

            setSurveyResponses(newSurveyResponses);
          })
          .catch((err) => message.error(err.message));
      },
    });
  }

  function onComplete(surveyCampaignId: string | undefined) {
    if (surveyCampaignId === undefined) {
      message.error("Invalid survey campaign");
    } else {
      message.loading(
        <>
          {t(
            SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt
          )}
        </>,
        1
      );

      const req: FinishSurveyCampaignRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      finishSurveyCampaign(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
            surveyCampaignId: surveyCampaignId,
          };

          return getSurveyCampaignBySurveyCampaignId(req1);
        })
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setSurveyCampaign(res.surveyCampaign);
          props.onSurveyCampaignUpdated(res.surveyCampaign);

          message.success(
            <>
              {t(
                SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt
              )}
            </>
          );
        })
        .catch((err) => message.error(err.message));
    }
  }

  function onStart(surveyCampaignId: string | undefined) {
    if (surveyCampaignId === undefined) {
      message.error("Invalid survey campaign");
    } else {
      message.loading("Starting survey campaign...", 1);

      const req: StartSurveyCampaignRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      startSurveyCampaign(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
            surveyCampaignId: surveyCampaignId,
          };

          return getSurveyCampaignBySurveyCampaignId(req1);
        })
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setSurveyCampaign(res.surveyCampaign);
          props.onSurveyCampaignUpdated(res.surveyCampaign);

          message.success("Started survey campaign");
        })
        .catch((err) => message.error(err.message));
    }
  }

  function onDelete(surveyCampaignId: string | undefined) {
    if (surveyCampaignId === undefined) {
      message.error("Invalid survey campaign");
    } else {
      message.loading(
        <>
          {t(
            SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt
          )}
        </>,
        1
      );

      const req: DeleteSurveyCampaignRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      deleteSurveyCampaign(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
            surveyCampaignId: surveyCampaignId,
          };

          return getSurveyCampaignBySurveyCampaignId(req1);
        })
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setSurveyCampaign(res.surveyCampaign);
          props.onSurveyCampaignUpdated(res.surveyCampaign);

          message.success(
            <>
              {t(
                SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt
              )}
            </>
          );
        })
        .catch((err) => message.error(err.message));
    }
  }

  function onUndelete(surveyCampaignId: string | undefined) {
    if (surveyCampaignId === undefined) {
      message.error("Invalid survey campaign");
    } else {
      message.loading(
        <>
          {t(
            SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt
          )}
        </>,
        1
      );

      const req: UndeleteSurveyCampaignRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      undeleteSurveyCampaign(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
            surveyCampaignId: surveyCampaignId,
          };

          return getSurveyCampaignBySurveyCampaignId(req1);
        })
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setSurveyCampaign(res.surveyCampaign);
          props.onSurveyCampaignUpdated(res.surveyCampaign);

          message.success(
            <>
              {t(
                SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt
              )}
            </>
          );
        })
        .catch((err) => message.error(err.message));
    }
  }

  const columns = [
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader)}</>
      ),
      width: 250,
      render: (rowData: SurveyResponse) => {
        return <span>{rowData.surveyEmployee.email}</span>;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.email.localeCompare(b.surveyEmployee.email),
    },
    {
      title: "Status",
      render: (rowData: SurveyResponse) => {
        return <SurveyResponseStatusTag status={rowData.status} />;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.status.localeCompare(b.status),
    },
    {
      title: (
        <span>
          {t(SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader)}{" "}
          &nbsp;
          <Tooltip
            title={t(SurveyCampaignViewKeys.surveyCampaignView_progressPrompt)}
          >
            <QuestionCircleFilled />
          </Tooltip>
        </span>
      ),
      render: (rowData: SurveyResponse) => {
        return `${rowData._totalNumberOfAnsweredQuestions} / ${rowData._totalNumberOfQuestions}`;
      },
    },
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader)}</>
      ),
      render: (rowData: SurveyResponse) => {
        return <span>{rowData.surveyEmployee.fname}</span>;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.fname.localeCompare(b.surveyEmployee.fname),
    },
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader)}</>
      ),
      render: (rowData: SurveyResponse) => {
        return <span>{rowData.surveyEmployee.lname}</span>;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.lname.localeCompare(b.surveyEmployee.lname),
    },
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader)}</>
      ),
      render: (rowData: SurveyResponse) => {
        return <PositionsTag position={rowData.surveyEmployee.position} />;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.position.localeCompare(b.surveyEmployee.position),
    },
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader)}</>
      ),
      render: (rowData: SurveyResponse) => {
        return <span>{rowData.surveyEmployee.team}</span>;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.team.localeCompare(b.surveyEmployee.team),
    },
    {
      title: (
        <>{t(SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader)}</>
      ),
      render: (rowData: SurveyResponse) => {
        return <span>{rowData.surveyEmployee.gender}</span>;
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.gender?.localeCompare(b.surveyEmployee.gender),
    },
    {
      title: (
        <>
          {t(SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader)}
        </>
      ),
      dataIndex: "updatedAt",
      render: (updatedAt) => parseTime(updatedAt),
      sorter: (a, b) =>
        moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf(),
    },
    {
      title: "ID",
      dataIndex: "_id",
    },
  ];

  const dataSource =
    surveyResponses?.filter((sr) => {
      /**
       * 12/6/2022 daniel.kwok
       * Disabled search or filter by api. Frontend only, temporarily
       */

      let isIncludeSearchStringMatch = true;
      let isSurveyCampaignStatusMatch = true;

      if (queryValues.surveySearchString) {
        const hashedString = `${sr.surveyEmployee.email} ${sr.surveyEmployee.fname} ${sr.surveyEmployee.lname} ${sr.surveyEmployee.position} ${sr.surveyEmployee.team}`;
        isIncludeSearchStringMatch = hashedString
          .toLocaleLowerCase()
          .includes(queryValues.surveySearchString.toLowerCase());
      }

      if (queryValues.surveyStatus && queryValues.surveyStatus !== "All") {
        isSurveyCampaignStatusMatch = sr.status === queryValues.surveyStatus;
      }

      return isIncludeSearchStringMatch && isSurveyCampaignStatusMatch;
    }) || [];

  function getSurveyStatisticComponent(label: string, value: any) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ color: "grey", marginBottom: 0 }}>{label}</p>
        <p>{value}</p>
      </div>
    );
  }

  function getActionsComponent(selectedSurveyIds: string[]) {
    return (
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          marginBottom: 10,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {
          /**
           * 9/9/2022 daniel.kwok
           * Hide survey response action buttons if survey campaign hasn't started
           */
          surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing ? (
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              <Button
                onClick={(e) => {
                  onResendSurveyResponses(selectedSurveyIds);
                }}
                disabled={selectedSurveyIds.length === 0}
                icon={<MailOutlined />}
              >
                {t(SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn)}
              </Button>
              <Tooltip title="Coming soon">
                <Button
                  onClick={(e) => {
                    onResendSurveyResponses(selectedSurveyIds);
                  }}
                  disabled={true}
                  icon={<StopOutlined />}
                >
                  {t(SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn)}
                </Button>
              </Tooltip>
              <Tooltip title="Coming soon">
                <Button
                  onClick={(e) => {
                    onResendSurveyResponses(selectedSurveyIds);
                  }}
                  disabled={true}
                  icon={<RollbackOutlined />}
                >
                  {t(SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn)}
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div></div>
          )
        }
      </div>
    );
  }

  function getTableComponent() {
    switch (surveyTemplate?.type) {
      case SurveyTemplateType.normal:
        return (
          <>
            <p style={{ color: "grey", marginBottom: 0 }}>
              Responses (refresh to get latest update)
            </p>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={dataSource}
              scroll={{
                x: columns.length * 150,
              }}
              /**
               * 9/9/2022 daniel.kwok
               * Hide checkboxes if campaign hasn't started
               */
              rowSelection={
                surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing
                  ? {
                      type: "checkbox",
                      selectedRowKeys: selectedSurveyIds,
                      onChange: (
                        selectedRowKeys,
                        selectedRows: SurveyResponse[]
                      ) => {
                        setSelectedSurveyIds(selectedRowKeys as string[]);
                      },
                      onSelectAll: () => {
                        setSelectedSurveyIds(
                          surveyResponses?.map((s) => s._id) || []
                        );
                      },
                    }
                  : undefined
              }
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                defaultPageSize: 10,
              }}
            />
            {getFAQComponent()}
          </>
        );

      case SurveyTemplateType.feedback:
        const participants: SurveyResponse[] = dataSource.filter(
          (d) => d.surveyEmployee._id === d.surveyEmployee.participantId
        );

        if (participants.length === 0) {
          return <Empty />;
        }

        return (
          <>
            {participants.map((participant, i) => {
              const peers = dataSource.filter((d) => {
                const isPeer =
                  d.surveyEmployee.participantId ===
                  participant.surveyEmployee._id;
                return isPeer;
              });

              return (
                <div key={participant._id}>
                  <Typography.Title level={5}>
                    360 Survey #{i + 1}:{" "}
                    {`${participant.surveyEmployee.fname} ${participant.surveyEmployee.lname}(${participant.surveyEmployee.email})`}
                  </Typography.Title>
                  <Table
                    rowKey="_id"
                    columns={columns}
                    dataSource={peers}
                    scroll={{
                      x: columns.length * 150,
                    }}
                    rowSelection={
                      surveyCampaign?.status ===
                      SurveyCampaignStatusLabels.ongoing
                        ? {
                            type: "checkbox",
                            selectedRowKeys: selectedSurveyIds,
                            onChange: (
                              selectedRowKeys,
                              selectedRows: SurveyResponse[]
                            ) => {
                              setSelectedSurveyIds(selectedRowKeys as string[]);
                            },
                            onSelectAll: () => {
                              setSelectedSurveyIds(
                                surveyResponses?.map((s) => s._id) || []
                              );
                            },
                          }
                        : undefined
                    }
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                      defaultPageSize: 10,
                    }}
                  />
                </div>
              );
            })}
            {getFAQComponent()}
          </>
        );
    }
  }

  function getTitleComponent(surveyCampaign?: SurveyCampaign) {
    const isDisableViewReport = context?.featureFlags?.find((ff) => {
      return (
        ff.key === FeatureFlagTemplateKey.IS_DISABLE_REPORT_READ &&
        ff.status === FeatureFlagStatus.ENABLED
      );
    })
      ? true
      : false;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <div>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            {surveyCampaign?.name}
          </Typography.Title>
          <p style={{ color: "grey", fontStyle: "italic" }}>
            ID: {surveyCampaign?._id}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
          }}
        >
          {surveyCampaign?.status === SurveyCampaignStatusLabels.draft ? (
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              <Tooltip title="Edit survey campaign">
                <a
                  href={`/surveycampaign/edit?surveyCampaignId=${surveyCampaign?._id}&step=0`}
                >
                  <Button
                    icon={<EditOutlined />}
                    href={`/surveycampaign/edit?surveyCampaignId=${surveyCampaign?._id}&step=0`}
                  />
                </a>
              </Tooltip>
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure?",
                    content: <p>Emails would be sent out to all recipients</p>,
                    onOk: () => onStart(props.surveyCampaignId),
                  });
                }}
              >
                Start campaign
              </Button>
            </div>
          ) : surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        {t(
                          SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt
                        )}
                      </>
                    ),
                    onOk: () => onComplete(props.surveyCampaignId),
                  });
                }}
              >
                {t(
                  SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn
                )}
              </Button>
              {isDisableViewReport ? (
                <Tooltip
                  title={t(
                    SurveyCampaignViewKeys.surveyCampaignView_isDisableReportReadWarnString
                  )}
                >
                  <Button disabled={isDisableViewReport} type="link">
                    {t(SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn)}
                  </Button>
                </Tooltip>
              ) : (
                <a
                  href={`/report/view?surveyCampaignId=${props.surveyCampaignId}&surveyTemplateType=${surveyTemplate?.type}`}
                >
                  <Button type="link">
                    {t(SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn)}
                  </Button>
                </a>
              )}
            </>
          ) : surveyCampaign?.status ===
            SurveyCampaignStatusLabels.completed ? (
            isDisableViewReport ? (
              <Tooltip
                title={t(
                  SurveyCampaignViewKeys.surveyCampaignView_isDisableReportReadWarnString
                )}
              >
                <Button disabled={isDisableViewReport} type="primary">
                  {t(SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn)}
                </Button>
              </Tooltip>
            ) : (
              <a
                href={`/report/view?surveyCampaignId=${props.surveyCampaignId}&surveyTemplateType=${surveyTemplate?.type}`}
              >
                <Button disabled={isDisableViewReport} type="primary">
                  {t(SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn)}
                </Button>
              </a>
            )
          ) : null}

          <Dropdown
            overlay={
              <Menu>
                {surveyCampaign?.status ===
                SurveyCampaignStatusLabels.deleted ? (
                  <Menu.Item
                    key="/restore"
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <>
                            {t(
                              SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt
                            )}
                          </>
                        ),
                        content: t(
                          SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt
                        ),
                        onOk: () => onUndelete(props.surveyCampaignId),
                      });
                    }}
                  >
                    Restore
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="/delete"
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <>
                            {t(
                              SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt
                            )}
                          </>
                        ),
                        content: t(
                          SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt
                        ),
                        onOk: () => onDelete(props.surveyCampaignId),
                      });
                    }}
                  >
                    Delete
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={["click"]}
            placement="bottomLeft"
          >
            <Tooltip
              title={t(SurveyCampaignViewKeys.surveyCampaignView_moreTooltip)}
            >
              <MoreOutlined
                data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.more_id}`}
                style={{
                  color: COLORS.PRIMARY_COLOR,
                }}
              />
            </Tooltip>
          </Dropdown>
        </div>
      </div>
    );
  }

  function getFAQComponent() {
    return (
      <>
        <Typography.Title level={3}>
          {t(SurveyCampaignViewKeys.surveyCampaignView_faqString)}
        </Typography.Title>
        <Collapse>
          <Collapse.Panel
            header={t(
              SurveyCampaignViewKeys.surveyCampaignView_availableStatusString
            )}
            key="1"
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>
                {" "}
                <SurveyResponseStatusTag
                  status={SurveyResponseStatusLabels.PENDING}
                />
                <p>
                  {t(
                    SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString
                  )}
                </p>
              </div>
              <div>
                <SurveyResponseStatusTag
                  status={SurveyResponseStatusLabels.EMAIL_SENT}
                />
                <p>
                  {t(
                    SurveyCampaignViewKeys.surveyCampaignView_sentStatusString
                  )}
                </p>
              </div>

              <div>
                <SurveyResponseStatusTag
                  status={SurveyResponseStatusLabels.EMAIL_FAILED}
                />
                <p>
                  {t(
                    SurveyCampaignViewKeys.surveyCampaignView_failedStatusString
                  )}
                </p>
              </div>
              <div>
                <SurveyResponseStatusTag
                  status={SurveyResponseStatusLabels.CANCELLED}
                />
                <p>
                  {t(
                    SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString
                  )}
                </p>
              </div>
              <div>
                <SurveyResponseStatusTag
                  status={SurveyResponseStatusLabels.COMPLETED}
                />
                <p>
                  {t(
                    SurveyCampaignViewKeys.surveyCampaignView_completedStatusString
                  )}
                </p>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      </>
    );
  }

  return (
    <>
      {getTitleComponent(surveyCampaign)}

      <br />
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: 30,
        }}
      >
        {getSurveyStatisticComponent(
          t(SurveyCampaignKeys.surveyCampaigns_statusString),
          <SurveyCampaignStatusTag status={surveyCampaign?.status} />
        )}
        {getSurveyStatisticComponent(
          t(SurveyCampaignKeys.surveyCampaigns_surveyString),
          surveyTemplate?.name
        )}
        {getSurveyStatisticComponent(
          t(SurveyCampaignViewKeys.surveyCampaignView_createdString),
          parseTime(surveyCampaign?.createdAt)
        )}
      </div>

      <br />
      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        {getActionsComponent(selectedSurveyIds)}
      </div>

      <br />
      <br />
      {getTableComponent()}
    </>
  );
}
