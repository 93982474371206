import { Button, Form, Input, message } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UpdateSurveyCampaignRequest } from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { SurveyCampaign } from "../../constants/types";
import { updateSurveyCampaign } from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { parseDate } from "../../util";

interface Props {
  onFinish: Function;
  onBack: () => void;
  onNext: () => void;
  onChangeSurveyCampaign: (surveyCampaign: SurveyCampaign) => void;
  surveyCampaign?: SurveyCampaign;
  originalSurveyCampaign?: SurveyCampaign;
  isEditMode: boolean;
}

export default function BasicInfo(props: Props) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(props.surveyCampaign);
  }, [props.surveyCampaign, form]);

  async function onFinish() {
    const formValues = form.getFieldsValue(true) as SurveyCampaign;

    if (props.originalSurveyCampaign?.name !== formValues.name) {
      const newSurveyCampaign: SurveyCampaign = Object.assign(
        {},
        props.surveyCampaign,
      );
      newSurveyCampaign.name = formValues.name;

      props.onChangeSurveyCampaign(newSurveyCampaign);
      props.onNext();
    } else {
      props.onChangeSurveyCampaign(props.originalSurveyCampaign);
      props.onNext();
    }
  }

  async function onSave() {
    try {
      const formValues = form.getFieldsValue(true) as SurveyCampaign;

      const newSurveyCampaign: SurveyCampaign = Object.assign(
        {},
        props.surveyCampaign,
      );
      newSurveyCampaign.name = formValues.name;

      props.onChangeSurveyCampaign(newSurveyCampaign);

      const req2: UpdateSurveyCampaignRequest = {
        surveyCampaign: newSurveyCampaign,
      };

      updateSurveyCampaign(req2)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          message.success("Updated");
          window.history.back();
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (err) {
      message.error(err instanceof Error ? err.message : err?.toString());
    }
  }

  return (
    <div>
      <Form
        form={form}
        initialValues={props.surveyCampaign}
        layout="vertical"
        onFinish={async () => {
          onFinish();
        }}
      >
        <Form.Item
          data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.campaign_name_label_id}
          label={t(
            SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString,
          )}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={`e.g. My Survey ${parseDate(moment.now())}`} />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <Button onClick={() => props.onBack()} type="link">
              <>{t(SurveyCampaignFormKeys.surveyCampaignForm_backBtn)}</>
            </Button>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 30,
                gap: 10,
              }}
            >
              {props.isEditMode ? (
                <Button onClick={() => onSave()}>
                  <>{t(SurveyCampaignFormKeys.surveyCampaignForm_saveBtn)}</>
                </Button>
              ) : null}
              <Button htmlType="submit" type="primary">
                <>{t(SurveyCampaignFormKeys.surveyCampaignForm_nextBtn)}</>
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
