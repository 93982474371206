import { NormalIndividualQuestionReportKeys } from "./keys";

export const englishValues = {
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]:
    "Individual Report",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]:
    "Gender",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]:
    "Email",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]:
    "Survey ID",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]:
    "Survey",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]:
    "Last updated",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]:
    "Download as PDF",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]:
    "Participant",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]:
    "Comparison",
};

export const malayValues = {
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]:
    "Laporan Individu",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]:
    "Jantina",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]:
    "E-mel",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]:
    "ID Tinjauan",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]:
    "Tinjauan",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]:
    "Dikemas kini pada",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]:
    "Muat turun sebagai PDF",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]:
    "Peserta",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]:
    "Perbandingan",
};

export const chineseSimplifiedValues = {
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]:
    "个人报告",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]:
    "性别",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]:
    "电子邮件",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]:
    "调查ID",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]:
    "调查",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]:
    "更新于",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]:
    "下载为PDF",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]:
    "参与者",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]:
    "比较",
};

export const chineseTraditionalValues = {
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]:
    "個人報告",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]:
    "性別",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]:
    "電子郵件",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]:
    "調查ID",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]:
    "調查",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]:
    "更新於",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]:
    "下載為PDF",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]:
    "參與者",
  [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]:
    "比較",
};
