import { Button, Card, Form, Input, message, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import {
  IsResetPasswordCodeValidRequest,
  ResetPasswordRequest,
} from "../../constants/apiRequestResponse";
import { IsResetPasswordCodeValidFailureCode } from "../../constants/types";
import {
  getIsResetPasswordTokenValid,
  resetPassword,
} from "../../services/api";
import { getQueryParam } from "../../util";

export default function ResetPasswordPage() {
  const token = getQueryParam("token");

  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [tokenStatus, setTokenStatus] =
    useState<IsResetPasswordCodeValidFailureCode>();

  useEffect(() => {
    if (!token) {
      setTokenStatus(IsResetPasswordCodeValidFailureCode.invalid);
    } else {
      setIsValidatingToken(true);

      const req: IsResetPasswordCodeValidRequest = {
        token: token,
      };
      getIsResetPasswordTokenValid(req)
        .then((res) => {
          if (!res.isValid) throw new Error(res.failureCode);
        })
        .catch((err) => {
          setTokenStatus(err);
        })
        .finally(() => setIsValidatingToken(false));
    }
  }, [token]);

  return (
    <div
      className="page-content"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: 50,
        position: "absolute",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <Card
        style={{
          width: "40%",
          minWidth: 300,
        }}
      >
        <div
          style={{
            flex: "40%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img alt="" style={{ width: "100%" }} src={logo} />
        </div>

        <div
          style={{
            flex: "40%",
          }}
        >
          {isValidatingToken ? (
            <Skeleton active />
          ) : tokenStatus === IsResetPasswordCodeValidFailureCode.expired ? (
            <>
              <p>Link expired.</p>

              <Link id="back-link" to="/forgot-password">
                Request another link?
              </Link>
            </>
          ) : tokenStatus === IsResetPasswordCodeValidFailureCode.invalid ? (
            <>
              <p>Link invalid.</p>

              <Link id="back-link" to="/forgot-password">
                Request another link?
              </Link>
            </>
          ) : (
            <>
              <h1 style={{ color: "#467e8e" }}>Reset password</h1>
              <br />
              <Form
                layout={"vertical"}
                onFinish={async (v) => {
                  console.log(v);
                  try {
                    if (v.newPassword !== v.confirmNewPassword)
                      throw new Error(`Passwords do not match.`);

                    const req: ResetPasswordRequest = {
                      token: token,
                      newPassword: v.newPassword,
                    };
                    const res = await resetPassword(req);
                    if (!res.success) throw new Error(res.message);

                    Modal.success({
                      title: "Password reset done!",
                      content: <a href="/login">Click here to login</a>,
                      okButtonProps: {
                        style: {
                          display: "none",
                        },
                      },
                    });
                  } catch (err) {
                    message.error(
                      typeof err === "string"
                        ? err
                        : typeof err === "object"
                          ? err?.toString()
                          : `Something went wrong`,
                    );
                  }
                }}
                initialValues={{
                  newPassword: "",
                  confirmNewPassword: "",
                }}
              >
                <Form.Item name="newPassword">
                  <Input.Password placeholder={"New password"} />
                </Form.Item>
                <Form.Item name="confirmNewPassword">
                  <Input.Password placeholder={"Confirm new password"} />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Reset
                    </Button>
                    <Link to="/login">Back to login</Link>
                  </div>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
