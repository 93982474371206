import { CompanyUserLayoutKeys } from "./keys";

export const englishValues = {
  [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]:
    "Surveys Campaigns",
  [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]:
    "Good morning",
  [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]:
    "Good afternoon",
  [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]:
    "Good evening",
  [CompanyUserLayoutKeys.companyUserLayout_reportString]: "Report",
  [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: "Profile",
  [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: "Settings",
  [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: "Logout",
};

export const malayValues = {
  [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]:
    "Kempen Tinjauan",
  [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]:
    "Selamat pagi",
  [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]:
    "Selamat petang",
  [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]:
    "Selamat petang",
  [CompanyUserLayoutKeys.companyUserLayout_reportString]: "Laporan",
  [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: "Profil",
  [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: "Tetapan",
  [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: "Log keluar",
};

export const chineseSimplifiedValues = {
  [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]: "调查活动",
  [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]: "早上好",
  [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]: "下午好",
  [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]: "晚上好",
  [CompanyUserLayoutKeys.companyUserLayout_reportString]: "报告",
  [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: "个人资料",
  [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: "设置",
  [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: "退出",
};

export const chineseTraditionalValues = {
  [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]: "調查活動",
  [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]: "早上好",
  [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]: "下午好",
  [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]: "晚上好",
  [CompanyUserLayoutKeys.companyUserLayout_reportString]: "報告",
  [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: "個人資料",
  [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: "設置",
  [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: "退出",
};
