import { SurveyCampaignFormKeys } from "./keys";

export const englishValues = {
  [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]: "Run a survey",
  [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: "Basic info",
  [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]:
    "Campaign name",
  [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: "Save",
  [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: "Next",
  [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: "Back",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]:
    "Upload employees",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]:
    "Upload employees",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]:
    "Download a ",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]:
    "sample CSV template",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]:
    "to see an example of the format required.",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]:
    "Supported formats: xlxs, csv",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]:
    "This will override existing data. Confirm?",
  [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]:
    "errors found.",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]:
    "All good to go!",
  [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]:
    "Add an employee",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]:
    "No data found",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]:
    "First name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]:
    "Last name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]:
    "Position",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]:
    "Email",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]: "Team",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]:
    "Gender",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]:
    "Errors",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]:
    "Action",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]:
    "Add a new employee",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]: "Added",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]:
    "First Name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]:
    "Last Name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]:
    "Email",
  [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]:
    "Email must end with one of",
  [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]:
    ". E.g. johndoe@",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]:
    "Gender",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]:
    "Position",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]:
    "Team",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]:
    "Delete",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: "Save",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]:
    "Confirm delete?",
  [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: "Deleted",
  [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: "Updated",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]:
    "First Name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]:
    "Last Name",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]:
    "Email",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]:
    "Gender",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]:
    "Position",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]:
    "Team",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]:
    "Delete",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]: "Save",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]:
    "Confirm delete?",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]:
    "Preview and send",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]:
    "Preview and send",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]:
    "Uploaded employees ",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]:
    "First name",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]:
    "Last name",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]:
    "Position",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]: "Email",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: "Team",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]:
    "Gender",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]:
    "More actions",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]:
    "Example email for",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]: "Refresh",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]:
    " Edit template",
  [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: "FAQ",
  [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]:
    "What is [$link]?",
  [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]:
    'This is the unique link for each survey. Will only be generated once "Send" is clicked.',
  [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: "Back",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]: "Save as draft",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: "Send",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]:
    "Are you sure?",
  [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]:
    "Sending survey",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]:
    "Survey campaign started!",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]:
    "Survey Campaign",
};

export const malayValues = {
  [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]:
    "Jalankan tinjauan",
  [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: "Info asas",
  [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]: "Nama kempen",
  [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: "Simpan",
  [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: "Seterusnya",
  [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: "Kembali",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]:
    "Memuat naik pekerja",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]:
    "Memuat naik pekerja",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]:
    "Muat turun ",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]:
    "templat sampel CSV ",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]:
    " untuk lihat contoh format yang ditetapkan.",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]:
    "Format yang disokong: xlxs, csv",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]:
    "Tindakan ini akan menggantikan data yang sedia ada. Sahkan?",
  [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]:
    "ralat ditemui.",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]:
    "Data sudah disediakan!",
  [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]:
    "Tambah pekerja",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]:
    "Tiada data dijumpai",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]:
    "Nama pertama",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]:
    "Nama keluarga",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]:
    "Jawatan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]:
    "E-mel",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]:
    "Pasukan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]:
    "Jantina",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]:
    "Ralat",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]:
    "Tindakan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]:
    "Tambah pekerja baharu",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]:
    "Ditambahkan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]:
    "Nama pertama",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]:
    "Nama keluarga",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]:
    "E-mel",
  [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]:
    "E-mel mesti berakhir dengan salah satu daripada",
  [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]:
    ". Contohnya johndoe@",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]:
    "Jantina",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]:
    "Jawatan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]:
    "Pasukan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]:
    "Padam",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: "Simpan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]:
    "Sahkan padam?",
  [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: "Dipadamkan",
  [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: "Dikemaskinikan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]:
    "Nama pertama",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]:
    "Nama keluarga",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]:
    "E-mel",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]:
    "Jantina",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]:
    "Jawatan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]:
    "Pasukan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]:
    "Padam",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]:
    "Simpan",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]:
    "Sahkan padam?",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]:
    "Pratonton dan hantar",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]:
    "Pratonton dan hantar",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]:
    "Pekerja yang dimuat naik ",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]:
    "Nama pertama",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]:
    "Nama keluarga",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]:
    "Jawatan",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]: "E-mel",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: "Pasukan",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]:
    "Jantina",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]:
    "Tindakan lanjut",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]:
    "Contoh e-mel untuk",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]:
    "Muat semula",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]:
    " Edit templat",
  [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: "Soalan lazim",
  [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]:
    "Apakah itu [$link]?",
  [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]:
    'Ini ialah pautan yang unik untuk setiap tinjauan. Pautan akan dijanakan selepas tekan "Hantar".',
  [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: "Kembali",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]:
    "Simpan sebagai draf",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: "Hantar",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]:
    "Adakah anda pasti?",
  [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]:
    "Tinjauan sedang dihantar",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]:
    "Kempen tinjauan telah dimulakan!",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]:
    "Kempen Tinjauan",
};

export const chineseSimplifiedValues = {
  [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]: "发起调查",
  [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: "基本信息",
  [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]: "活动名称",
  [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: "下一步",
  [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: "返回",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]: "上传员工",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]:
    "上传员工",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]:
    "下载",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]:
    "示例CSV模板",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]:
    "查看所需的格式示例。",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]:
    "支持的格式：xlxs、csv",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]:
    "这将覆盖现有数据。确认吗？",
  [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]: "发现错误。",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]:
    "一切准备就绪！",
  [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]: "添加员工",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]:
    "未找到数据",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]:
    "职位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]:
    "电子邮件",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]: "团队",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]:
    "性别",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]:
    "错误",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]:
    "操作",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]:
    "添加新员工",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]: "已添加",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]:
    "电子邮件",
  [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]:
    "电子邮件必须以以下之一结尾",
  [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]:
    "。例如，johndoe@",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]:
    "性别",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]:
    "职位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]:
    "团队",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]: "删除",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]:
    "确认删除？",
  [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: "已删除",
  [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: "已更新",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]:
    "电子邮件",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]:
    "性别",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]:
    "职位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]:
    "团队",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]:
    "删除",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]:
    "确认删除？",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]:
    "预览并发送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]:
    "预览并发送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]:
    "已上传的员工 ",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]:
    "职位",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]:
    "电子邮件",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: "团队",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]: "性别",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]:
    "更多操作",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]:
    "示例电子邮件",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]: "刷新",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]:
    "编辑模板",
  [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: "常见问题解答",
  [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]:
    "什么是 [$link]?",
  [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]:
    "这是每项调查的唯一链接。只有在点击“发送”后才会生成。",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: "返回",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]: "保存为草稿",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: "发送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]:
    "确认发送？",
  [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]: "发送调查",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]:
    "调查活动已开始！",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]:
    "调查活动",
};
export const chineseTraditionalValues = {
  [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]: "發起調查",
  [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: "基本信息",
  [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]: "活動名稱",
  [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: "下一步",
  [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: "返回",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]: "上傳員工",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]:
    "上傳員工",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]:
    "下載",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]:
    "示例CSV模板",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]:
    "查看所需的格式示例。",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]:
    "支持的格式：xlxs、csv",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]:
    "這將覆蓋現有數據。確認嗎？",
  [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]: "發現錯誤。",
  [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]:
    "一切準備就緒！",
  [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]: "添加員工",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]:
    "未找到數據",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]:
    "職位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]:
    "電子郵件",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]: "團隊",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]:
    "性別",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]:
    "錯誤",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]:
    "操作",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]:
    "添加新員工",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]: "已添加",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]:
    "電子郵件",
  [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]:
    "電子郵件必須以以下之一結尾",
  [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]:
    "。例如，johndoe@",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]:
    "性別",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]:
    "職位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]:
    "團隊",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]: "刪除",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]:
    "確認刪除？",
  [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: "已刪除",
  [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: "已更新",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]:
    "電子郵件",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]:
    "性別",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]:
    "職位",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]:
    "團隊",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]:
    "刪除",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]: "保存",
  [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]:
    "確認刪除？",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]:
    "預覽並發送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]:
    "預覽並發送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]:
    "已上傳的員工 ",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]:
    "名字",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]:
    "姓氏",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]:
    "職位",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]:
    "電子郵件",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: "團隊",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]: "性別",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]:
    "更多操作",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]:
    "示例電子郵件",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]: "刷新",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]:
    "編輯模板",
  [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: "常見問題解答",
  [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]:
    "什麼是 [$link]?",
  [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]:
    "這是每項調查的唯一連結。只有在點擊“發送”後才會生成。",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: "返回",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]: "保存為草稿",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: "發送",
  [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]:
    "確認發送？",
  [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]: "發送調查",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]:
    "調查活動已開始！",
  [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]:
    "調查活動",
};
