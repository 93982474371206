import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminDashboardPage from "../../pages/AdminPages/Dashboard";
import EditProfilePage from "../../pages/EditProfilePage";
import NotFoundPage from "../../pages/NotFoundPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";

export default function AdminRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={AdminDashboardPage} />

        <Route exact path="/profile" component={EditProfilePage} />
        <Route
          exact
          path="/forgot-password/:token/:dev?"
          component={ResetPasswordPage}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
