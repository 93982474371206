import { SurveyResponsePageKeys } from "./keys";

export const englishValues = {
  [SurveyResponsePageKeys.surveyResponsePage_startBtn]: "Start",
  [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]: "Save as draft",
  [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]:
    "Save progress and resume survey another time.",
  [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]:
    "Survey will still be accessible from the link in your email.",
  [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: "Submit",
  [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]:
    "Survey is incomplete",
  [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]:
    "Submit survey. Answers cannot be changed later.",
  [SurveyResponsePageKeys.surveyResponsePage_confirmString]:
    "Confirm? You will not be able to edit this once submitted.",
  [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]:
    "Survey is saved as draft.",
  [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]:
    "Feel free to come back and finish it anytime.",
  [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: "Continue",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]:
    "Survey is completed!",
  [SurveyResponsePageKeys.surveyResponsePage_thankYouString]:
    "Thank you for submitting your response.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]:
    "This survey response had been cancelled.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]:
    "You will not be able to submit.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]:
    "This survey campaign has yet start.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]:
    "You will not be able to submit your response.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]:
    "This campaign had been cancelled.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]:
    "You will not be able to submit your response.",
  [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]:
    "Unknown status",
  [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]:
    "Please reach out to your HR department.",
  [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]:
    "This campaign have yet to start.",
  [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]:
    "No submission will be accepted.",
  [SurveyResponsePageKeys.surveyResponsePage_expiredString]:
    "This campaign is expired.",
  [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]:
    "No more submission will be accepted.",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]:
    "Something's wrong with this survey.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]:
    "Response submitted.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]:
    "Thank you for your submission.",
  [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]:
    "You cannot re-submit a submitted survey.",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]:
    "Something's wrong with this campaign.",
};

export const malayValues = {
  [SurveyResponsePageKeys.surveyResponsePage_startBtn]: "Mula",
  [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]:
    "Simpan sebagai draf",
  [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]:
    "Simpan progres dan sambung tinjauan pada masa yang lain.",
  [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]:
    "Tinjauan masih dapat diakses dari pautan dalam e-mel anda.",
  [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: "Hantar",
  [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]:
    "Tinjauan tidak dilengkapi",
  [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]:
    "Hantarkan tinjauan. Jawapan tidak boleh ditukar selepas ini.",
  [SurveyResponsePageKeys.surveyResponsePage_confirmString]:
    "Sahkan? Anda tidak dapat edit ini selepas dihantarkan.",
  [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]:
    "Tinjauan telah disimpan sebagai draf.",
  [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]:
    "Anda boleh kembali dan melengkapkan tinjauan pada masa yang lain.",
  [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: "Teruskan",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]:
    "Tinjauan telah dilengkapkan!",
  [SurveyResponsePageKeys.surveyResponsePage_thankYouString]:
    "Terima kasih atas respons anda.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]:
    "Tinjauan ini telah dibatalkan.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]:
    "Anda tidak akan dapat menyerahkan jawapan anda.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]:
    "Kempen ini belum bermula.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]:
    "Anda tidak akan dapat menyerahkan jawapan anda.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]:
    "Kempen ini telah dipadamkan.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]:
    "Anda tidak akan dapat menyerahkan jawapan anda.",
  [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]:
    "Status tidak diketahui",
  [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]:
    "Sila hubungi jabatan Sumber Manusia anda.",
  [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]:
    "Kempen ini belum bermula.",
  [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]:
    "Respons tidak akan diterimakan.",
  [SurveyResponsePageKeys.surveyResponsePage_expiredString]:
    "Kempen ini sudah tamat tempoh.",
  [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]:
    "Respons yang baharu tidak akan diterimakan.",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]:
    "Ada yang tak kena dengan tinjauan ini.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]:
    "Tinjauan telah diserahkan.",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]:
    "Terima kasih atas penyerahan anda",
  [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]:
    "Anda tidak dapat hantar semula tinjauan yang sudah dihantarkan.",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]:
    "Ada yang tak kena dengan kempen ini.",
};

export const chineseSimplifiedValues = {
  [SurveyResponsePageKeys.surveyResponsePage_startBtn]: "开始",
  [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]: "保存草稿",
  [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]:
    "保存进度，稍后继续调查。",
  [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]:
    "调查仍可通过您电子邮件中的链接访问。",
  [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: "提交",
  [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]:
    "调查未完成",
  [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]:
    "提交调查。此后将无法更改答案。",
  [SurveyResponsePageKeys.surveyResponsePage_confirmString]:
    "确认？提交后将无法编辑。",
  [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]:
    "调查已保存为草稿。",
  [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]:
    "您可以随时回来完成调查。",
  [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: "继续",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]:
    "调查已完成！",
  [SurveyResponsePageKeys.surveyResponsePage_thankYouString]: "感谢您的回复。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]:
    "调查已取消。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]:
    "您将无法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]:
    "此活动尚未开始。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]:
    "您将无法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]:
    "此活动已删除。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]:
    "您将无法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]: "未知状态",
  [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]:
    "请联系您的人力资源部门。",
  [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]:
    "此活动尚未开始。",
  [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]:
    "不接受提交。",
  [SurveyResponsePageKeys.surveyResponsePage_expiredString]: "此活动已过期。",
  [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]:
    "不再接受新的提交。",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]:
    "此调查出现问题。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]:
    "调查已提交。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]:
    "感谢您的提交。",
  [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]:
    "无法重新提交已提交的调查。",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]:
    "此活动出现问题。",
};

export const chineseTraditionalValues = {
  [SurveyResponsePageKeys.surveyResponsePage_startBtn]: "開始",
  [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]: "儲存為草稿",
  [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]:
    "儲存進度，稍後繼續調查。",
  [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]:
    "調查仍可通過您電子郵件中的連結訪問。",
  [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: "提交",
  [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]:
    "調查未完成",
  [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]:
    "提交調查。此後將無法更改答案。",
  [SurveyResponsePageKeys.surveyResponsePage_confirmString]:
    "確認？提交後將無法編輯。",
  [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]:
    "調查已儲存為草稿。",
  [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]:
    "您可以隨時回來完成調查。",
  [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: "繼續",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]:
    "調查已完成！",
  [SurveyResponsePageKeys.surveyResponsePage_thankYouString]: "感謝您的回覆。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]:
    "調查已取消。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]:
    "您將無法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]:
    "此活動尚未開始。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]:
    "您將無法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]:
    "此活動已刪除。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]:
    "您將無法提交您的答案。",
  [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]: "未知狀態",
  [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]:
    "請聯繫您的人力資源部門。",
  [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]:
    "此活動尚未開始。",
  [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]:
    "不接受提交。",
  [SurveyResponsePageKeys.surveyResponsePage_expiredString]: "此活動已過期。",
  [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]:
    "不再接受新的提交。",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]:
    "此調查出現問題。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]:
    "調查已提交。",
  [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]:
    "感謝您的提交。",
  [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]:
    "無法重新提交已提交的調查。",
  [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]:
    "此活動出現問題。",
};
