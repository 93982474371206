import { Button, Card, Empty, Skeleton, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FeedbackTeamQuestionReport from "../../components/QuestionReport/FeedbackTeamQuestionReport";
import { GetSurveyCampaign360TeamReportRequest } from "../../constants/apiRequestResponse";
import {
  BasicSurveyCampaign,
  BasicSurveyTemplate,
  Response360StatisticsByTeam,
} from "../../constants/types";
import { getSurveyCampaign360TeamReport } from "../../services/api";
import { FeedbackGroupQuestionReportKeys } from "../../services/i8tn/FeedbackGroupQuestionReport/keys";
import { getQueryParam, setQueryParam } from "../../util";

interface Prop {
  surveyCampaignId?: string;
}

export default function FeedbackTeamView(props: Prop) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [surveyCampaign, setSurveyCampaign] = useState<
    BasicSurveyCampaign | undefined
  >();
  const [surveyTemplate, setSurveyTemplate] = useState<
    BasicSurveyTemplate | undefined
  >();
  const [responseStatisticsByTeam, setResponseStatisticsByTeam] = useState<
    Response360StatisticsByTeam | undefined
  >();
  const [team, setTeam] = useState<string>(getQueryParam("team") || "");

  const { t } = useTranslation();

  const surveyCampaignId =
    props.surveyCampaignId || getQueryParam("surveyCampaignId") || undefined;

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const req: GetSurveyCampaign360TeamReportRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      getSurveyCampaign360TeamReport(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);
          setResponseStatisticsByTeam(res.responseStatisticsByTeam);
          setSurveyCampaign(res.surveyCampaign);
          setSurveyTemplate(res.surveyTemplate);
        })
        .catch((err) => {
          message.error(err);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      message.error(
        typeof err === "string"
          ? err
          : typeof err === "object"
            ? err?.toString()
            : `Something went wrong`,
      );
      setIsError(true);
    }
    setIsLoading(false);
  }, [surveyCampaignId]);

  useEffect(() => {
    getData();
  }, [surveyCampaignId, getData]);

  if (isError) {
    return (
      <div>
        <h1>Oops. Invalid survey campaign</h1>
      </div>
    );
  }

  if (isLoading) {
    return <Skeleton active />;
  }

  const columns = [
    {
      title: <>Team</>,
      width: 200,
      dataIndex: "team",
      sorter: (
        a: { key: number; team: string },
        b: { key: number; team: string },
      ) => a.team.localeCompare(b.team),
    },
    {
      title: (
        <>
          {t(
            FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader,
          )}
        </>
      ),
      render: (rowData: { key: number; team: string }) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            <Button
              type="link"
              onClick={() => {
                setQueryParam("team", rowData.team);
                setTeam(rowData.team);
              }}
            >
              <>
                {t(
                  FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink,
                )}
              </>
            </Button>
          </div>
        );
      },
    },
  ];

  const teams: { key: number; team: string }[] = responseStatisticsByTeam
    ? Object.keys(responseStatisticsByTeam).map((team, id) => ({
        key: id,
        team: team,
      }))
    : [];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <Card
        style={{
          width: "30%",
          minWidth: 400,
          overflow: "scroll",
        }}
      >
        <Table columns={columns} dataSource={teams} pagination={false} />
      </Card>
      <Card
        style={{
          width: "70%",
        }}
      >
        {team ? (
          <FeedbackTeamQuestionReport
            surveyCampaignId={props.surveyCampaignId}
            team={team}
            surveyCampaign={surveyCampaign}
            surveyTemplate={surveyTemplate}
            responseStatisticsByTeam={responseStatisticsByTeam}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`Select a team from the left`}
          />
        )}
      </Card>
    </div>
  );
}
