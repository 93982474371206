import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../App";
import logo2 from "../../assets/logo2.jpg";
import config from "../../config";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import LANGUAGES, { Language } from "../../services/i8tn/supportedLanguages";
import CompanyUserRoutes from "../../routes/CompanyUserRoutes/index";
import { logout } from "../../services/api";
import i18n from "../../services/i8tn";
import { useTranslation } from "react-i18next";
import { CompanyUserLayoutKeys } from "../../services/i8tn/CompanyUserLayout/keys";

const { Header, Content, Footer } = Layout;

function CompanyUserLayout() {
  const { t } = useTranslation();
  const context = useContext(SessionContext);
  const activeKey = window.location.pathname;
  const [language, setLanguage] = useState<Language | undefined>();

  useEffect(() => {
    const _language = Object.values(LANGUAGES).find(
      (lang) => lang.nativeName === i18n.resolvedLanguage
    );
    setLanguage(_language);
  }, []);

  /**
   * Returns a time sensitive greeting based on time of day
   */
  const getGreeting = () => {
    const currentTime = new Date().getHours();

    if (currentTime < 12) {
      return (
        <>{t(CompanyUserLayoutKeys.companyUserLayout_morningGreetingString)}</>
      );
    } else if (currentTime >= 12 && currentTime < 18) {
      return (
        <>
          {t(CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString)}
        </>
      );
    } else if (currentTime >= 18) {
      return (
        <>{t(CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString)}</>
      );
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Header
          style={{
            backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
            display: "flex",
            padding: "0 20px",
          }}
        >
          <a href="/" style={{ width: "10%" }}>
            <img alt="" src={logo2} height={"90%"} />
          </a>
          <Menu
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
              borderColor: COLORS.BACKGROUND_COLOR_DARK,
              color: "white",
            }}
            selectedKeys={[activeKey]}
            mode="horizontal"
            defaultSelectedKeys={["/"]}
          >
            <Menu.Item key="/surveycampaign">
              <a style={{ color: "white" }} href="/surveycampaign">
                {t(
                  CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString
                )}
              </a>
            </Menu.Item>
            <Menu.Item key="/report">
              <a style={{ color: "white" }} href="/report">
                {t(CompanyUserLayoutKeys.companyUserLayout_reportString)}
              </a>
            </Menu.Item>
            <Menu.Item key="/language">
              <Dropdown
                overlay={
                  <Menu>
                    {Object.values(LANGUAGES).map((lang) => {
                      return (
                        <Menu.Item
                          onClick={() => {
                            setLanguage(lang);
                            i18n.changeLanguage(lang.nativeName);
                          }}
                          key={lang.nativeName}
                          disabled={lang.isDisabled}
                        >
                          <span>
                            <img
                              alt=""
                              src={lang.flag}
                              width={20}
                              style={{ marginRight: 5 }}
                            />
                            {lang.nativeName}
                          </span>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
              >
                <img alt="" src={language?.flag} width={20} />
              </Dropdown>
            </Menu.Item>

            <Menu.Item
              data-testid={TEST_IDS.HEADER.profile_container_id}
              key="/profile"
            >
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="/profile">
                      <a href="/profile">
                        {t(
                          CompanyUserLayoutKeys.companyUserLayout_profileDropDownString
                        )}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="/settings">
                      <a href="/settings">
                        {t(
                          CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString
                        )}
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      key="/login"
                      onClick={() => {
                        logout();
                        window.location.href = "/login";
                      }}
                    >
                      {t(
                        CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString
                      )}
                    </Menu.Item>
                  </Menu>
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  {getGreeting()}, {context?.companyUser?.name}
                  <UserOutlined style={{ fontSize: 20, marginLeft: 5 }} />
                </div>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Header>
        <Content>
          <CompanyUserRoutes />
        </Content>
        <Footer
          style={{
            textAlign: "left",
            backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
            color: "white",
          }}
        >
          The Development Center Survey Platform v.{config.version}
        </Footer>
      </Layout>
    </Layout>
  );
}

export default CompanyUserLayout;
