import { EditProfilePageKeys } from "./keys";

export const englishValues = {
  [EditProfilePageKeys.editProfilePage_editProfileTitle]: "Edit profile",
  [EditProfilePageKeys.editProfilePage_userTab]: "User",
  [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: "User profile",
  [EditProfilePageKeys.editProfilePage_userProfileNameString]: "Name",
  [EditProfilePageKeys.editProfilePage_userProfileEmailString]: "Email",
  [EditProfilePageKeys.editProfilePage_userProfileGenderString]: "Gender",
  [EditProfilePageKeys.editProfilePage_userProfileMobileString]: "Mobile",
  [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: "Save",
  [EditProfilePageKeys.editProfilePage_companyTab]: "Company",
  [EditProfilePageKeys.editProfilePage_companyTabTitle]: "Company",
  [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: "Change logo",
  [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]:
    "Update company logo",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainString]:
    "Email Domain",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]:
    'The portion after "@" of your company email. E.g. if your company email is johndoe@acme.com, your email domain would be acme.com',
  [EditProfilePageKeys.editProfilePage_companyNameString]: "Name",
  [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]:
    "Insert company name",
  [EditProfilePageKeys.editProfilePage_companyIndustryString]: "Industry",
  [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]:
    "Insert industry",
  [EditProfilePageKeys.editProfilePage_companyLocationString]: "Location",
  [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]:
    "Insert company location",
  [EditProfilePageKeys.editProfilePage_companySizeString]: "Company Size",
  [EditProfilePageKeys.editProfilePage_companySaveBtn]: "Save",
  [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]:
    "Something went wrong. Please refresh and try again?",
  [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]:
    "Successfully updated company.",
  [EditProfilePageKeys.editProfilePage_resetPasswordTab]: "Reset password",
  [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]: "Reset password",
  [EditProfilePageKeys.editProfilePage_resetPasswordOldString]: "Old password",
  [EditProfilePageKeys.editProfilePage_resetPasswordNewString]: "New password",
  [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]:
    "Confirm new password",
  [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: "Reset",
};

export const malayValues = {
  [EditProfilePageKeys.editProfilePage_editProfileTitle]: "Edit profil",
  [EditProfilePageKeys.editProfilePage_userTab]: "Pengguna",
  [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: "Profil pengguna",
  [EditProfilePageKeys.editProfilePage_userProfileNameString]: "Nama",
  [EditProfilePageKeys.editProfilePage_userProfileEmailString]: "E-mel",
  [EditProfilePageKeys.editProfilePage_userProfileGenderString]: "Jantina",
  [EditProfilePageKeys.editProfilePage_userProfileMobileString]:
    "Telefon bimbit",
  [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: "Simpan",
  [EditProfilePageKeys.editProfilePage_companyTab]: "Syarikat",
  [EditProfilePageKeys.editProfilePage_companyTabTitle]: "Syarikat",
  [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: "Tukar logo",
  [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]:
    "Kemaskini logo syarikat",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainString]:
    "Domain E-mel",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]:
    'Bahagian selepas "@" pada e-mel syarikat anda. Contohnya, jika e-mel syarikat anda ialah johndoe@acme.com, domain e-mel anda ialah acme.com',
  [EditProfilePageKeys.editProfilePage_companyNameString]: "Nama",
  [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]:
    "Isikan nama syarikat",
  [EditProfilePageKeys.editProfilePage_companyIndustryString]: "Industri",
  [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]:
    "Isikan industri",
  [EditProfilePageKeys.editProfilePage_companyLocationString]: "Lokasi",
  [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]:
    "Isikan lokasi syarikat",
  [EditProfilePageKeys.editProfilePage_companySizeString]: "Saiz Syarikat",
  [EditProfilePageKeys.editProfilePage_companySaveBtn]: "Simpan",
  [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]:
    "Sesuatu telah berlaku. Sila muat semula dan cuba lagi?",
  [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]:
    "Berjaya mengemas kini syarikat",
  [EditProfilePageKeys.editProfilePage_resetPasswordTab]:
    "Set semula kata laluan",
  [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]:
    "Set semula kata laluan",
  [EditProfilePageKeys.editProfilePage_resetPasswordOldString]:
    "Kata laluan semasa",
  [EditProfilePageKeys.editProfilePage_resetPasswordNewString]:
    "Kata laluan baharu",
  [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]:
    "Sahkan kata laluan baharu",
  [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: "Set semula",
};

export const chineseSimplifiedValues = {
  [EditProfilePageKeys.editProfilePage_editProfileTitle]: "编辑个人资料",
  [EditProfilePageKeys.editProfilePage_userTab]: "用户",
  [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: "用户资料",
  [EditProfilePageKeys.editProfilePage_userProfileNameString]: "姓名",
  [EditProfilePageKeys.editProfilePage_userProfileEmailString]: "电子邮件",
  [EditProfilePageKeys.editProfilePage_userProfileGenderString]: "性别",
  [EditProfilePageKeys.editProfilePage_userProfileMobileString]: "手机",
  [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: "保存",
  [EditProfilePageKeys.editProfilePage_companyTab]: "公司",
  [EditProfilePageKeys.editProfilePage_companyTabTitle]: "公司",
  [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: "更换标志",
  [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]: "更新公司标志",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainString]: "电子邮件域",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]:
    '您公司电子邮件中的 "@" 后的部分。例如，如果您的公司电子邮件是 johndoe@acme.com，您的电子邮件域是 acme.com',
  [EditProfilePageKeys.editProfilePage_companyNameString]: "名称",
  [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]: "填写公司名称",
  [EditProfilePageKeys.editProfilePage_companyIndustryString]: "行业",
  [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]: "填写行业",
  [EditProfilePageKeys.editProfilePage_companyLocationString]: "位置",
  [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]:
    "填写公司位置",
  [EditProfilePageKeys.editProfilePage_companySizeString]: "公司规模",
  [EditProfilePageKeys.editProfilePage_companySaveBtn]: "保存",
  [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]:
    "发生错误。请重新加载并重试。",
  [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]:
    "成功更新公司",
  [EditProfilePageKeys.editProfilePage_resetPasswordTab]: "重置密码",
  [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]: "重置密码",
  [EditProfilePageKeys.editProfilePage_resetPasswordOldString]: "当前密码",
  [EditProfilePageKeys.editProfilePage_resetPasswordNewString]: "新密码",
  [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]:
    "确认新密码",
  [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: "重置",
};

export const chineseTraditionalValues = {
  [EditProfilePageKeys.editProfilePage_editProfileTitle]: "編輯個人資料",
  [EditProfilePageKeys.editProfilePage_userTab]: "用戶",
  [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: "用戶資料",
  [EditProfilePageKeys.editProfilePage_userProfileNameString]: "姓名",
  [EditProfilePageKeys.editProfilePage_userProfileEmailString]: "電子郵件",
  [EditProfilePageKeys.editProfilePage_userProfileGenderString]: "性別",
  [EditProfilePageKeys.editProfilePage_userProfileMobileString]: "手機",
  [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: "保存",
  [EditProfilePageKeys.editProfilePage_companyTab]: "公司",
  [EditProfilePageKeys.editProfilePage_companyTabTitle]: "公司",
  [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: "更換標誌",
  [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]: "更新公司標誌",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainString]: "電子郵件域",
  [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]:
    '您公司電子郵件中的 "@" 後的部分。例如，如果您的公司電子郵件是 johndoe@acme.com，您的電子郵件域是 acme.com',
  [EditProfilePageKeys.editProfilePage_companyNameString]: "名稱",
  [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]: "填寫公司名稱",
  [EditProfilePageKeys.editProfilePage_companyIndustryString]: "行業",
  [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]: "填寫行業",
  [EditProfilePageKeys.editProfilePage_companyLocationString]: "位置",
  [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]:
    "填寫公司位置",
  [EditProfilePageKeys.editProfilePage_companySizeString]: "公司規模",
  [EditProfilePageKeys.editProfilePage_companySaveBtn]: "保存",
  [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]:
    "發生錯誤。請重新加載並重試。",
  [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]:
    "成功更新公司",
  [EditProfilePageKeys.editProfilePage_resetPasswordTab]: "重置密碼",
  [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]: "重置密碼",
  [EditProfilePageKeys.editProfilePage_resetPasswordOldString]: "當前密碼",
  [EditProfilePageKeys.editProfilePage_resetPasswordNewString]: "新密碼",
  [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]:
    "確認新密碼",
  [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: "重置",
};
