import { Card, message, PageHeader, Table } from "antd";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SessionContext } from "../../App";
import {
  GetEmailTemplateByCompanyIdRequest,
  GetSurveyTemplatesRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { EmailTemplate, SurveyTemplate } from "../../constants/types";
import {
  getEmailTemplatesByCompanyId,
  getSurveyTemplates,
} from "../../services/api";
import { parseTime } from "../../util";
import { useTranslation } from "react-i18next";
import { SettingsPageKeys } from "../../services/i8tn/SettingsPage/keys";

export default function SettingsPage() {
  const context = useContext(SessionContext);

  const [emailTemplates, setEmailTemplates] = useState<
    EmailTemplate[] | undefined
  >();
  const [surveyTemplates, setSurveyTemplates] = useState<
    SurveyTemplate[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    const req: GetEmailTemplateByCompanyIdRequest = {
      companyId: context?.company?._id,
    };
    getEmailTemplatesByCompanyId(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setEmailTemplates(res.emailTemplates);
      })
      .catch((err) => message.error(err?.toString()))
      .finally(() => setIsLoading(false));

    const req2: GetSurveyTemplatesRequest = {};
    getSurveyTemplates(req2)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setSurveyTemplates(res.surveyTemplates);
      })
      .catch((err) => message.error(err?.toString()));
  }, [context?.company?._id]);

  return (
    <div className="page-content">
      <PageHeader
        className="site-page-header"
        title={t(SettingsPageKeys.settingsPage_settingsTitle)}
      />
      <Card>
        <h3>
          <b>{t(SettingsPageKeys.settingsPage_emailTemplatesString)}</b>
        </h3>
        <p>{t(SettingsPageKeys.settingsPage_manageTemplatesString)}</p>

        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <div style={{ flex: 2 }}>
            <Table
              loading={isLoading}
              dataSource={emailTemplates?.map((e) => {
                const st = surveyTemplates?.find(
                  (st) => st._id === e.surveyTemplateId,
                );
                return {
                  ...e,
                  surveyTemplateName: st?.name,
                };
              })}
              rowKey={"_id"}
              columns={[
                {
                  title: (
                    <>{t(SettingsPageKeys.settingsPage_nameTableHeader)}</>
                  ),
                  dataIndex: "name",
                },
                {
                  title: (
                    <>{t(SettingsPageKeys.settingsPage_templateTableHeader)}</>
                  ),
                  dataIndex: "surveyTemplateName",
                },
                {
                  title: (
                    <>{t(SettingsPageKeys.settingsPage_createdTableHeader)}</>
                  ),
                  dataIndex: "createdAt",
                  render: (createdAt) => parseTime(createdAt),
                },
                {
                  title: (
                    <>{t(SettingsPageKeys.settingsPage_actionTableHeader)}</>
                  ),
                  render: (rowData) => (
                    <Link
                      data-testid={`${TEST_IDS.SETTINGS_PAGE.survey_template_edit_button_id}_${rowData.surveyTemplateName}`}
                      to={`/settings/emailtemplate/?emailTemplateId=${rowData._id}`}
                    >
                      Edit
                    </Link>
                  ),
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
