import { Card, Tabs } from "antd";
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import AgreementTab from "../AgreementTab";
import AgreementCampaignDetails from "../AgreementTab/AgreementCampaignDetails";
import CreateAgreementCampaign from "../AgreementTab/CreateAgreementCampaign";
import CompanyTab from "../CompanyTab";
import CompanyUserTab from "../CompanyUserTab";
import SurveyTemplateTab from "../SurveyTemplateTab";

export default function AdminDashboardPage() {
  const location = useLocation();
  const defaultActiveKey = location.pathname.split("/")[1].replaceAll("/", "");
  return (
    <div className="page-content">
      <Card>
        <Tabs defaultActiveKey={defaultActiveKey}>
          <Tabs.TabPane
            tab={<a href="/companyUser">Users</a>}
            key="companyUser"
          ></Tabs.TabPane>

          <Tabs.TabPane
            tab={<a href="/company">Companies</a>}
            key="company"
          ></Tabs.TabPane>

          <Tabs.TabPane
            tab={<a href="/surveyTemplate">Survey Templates</a>}
            key="surveyTemplate"
          ></Tabs.TabPane>
          <Tabs.TabPane
            tab={<a href="/agreement">Agreements</a>}
            key="agreement"
          ></Tabs.TabPane>
        </Tabs>
        <Switch>
          <Route exact path={["/", "/company"]}>
            <CompanyTab />
          </Route>
          <Route exact path="/companyUser">
            <CompanyUserTab />
          </Route>
          <Route exact path="/surveyTemplate">
            <SurveyTemplateTab />
          </Route>
          <Route exact path="/agreement">
            <AgreementTab />
          </Route>
          <Route exact path="/agreement/details">
            <AgreementCampaignDetails />
          </Route>
          <Route exact path="/agreement/create">
            <CreateAgreementCampaign />
          </Route>
        </Switch>
      </Card>
    </div>
  );
}
