import { Skeleton, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  DisableSurveyTemplateRequest,
  EnableSurveyTemplateRequest,
  GetSurveyTemplatesRequest,
} from "../../../constants/apiRequestResponse";
import { SurveyTemplate } from "../../../constants/types";
import {
  disableSurveyTemplate,
  enableSurveyTemplate,
  getSurveyTemplates,
} from "../../../services/api";
import { parseTime } from "../../../util";

export default function SurveyTemplateTab() {
  const [surveyTemplates, setSurveyTemplates] = useState<
    SurveyTemplate[] | undefined
  >();
  const [isActivateLoading, setIsActivateLoading] = useState(false);
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsGetDataLoading(true);

    const req: GetSurveyTemplatesRequest = {
      isGetDeleted: true,
    };

    getSurveyTemplates(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        setSurveyTemplates(res.surveyTemplates);
      })
      .catch((err) => message.error(err.toString()))
      .finally(() => setIsGetDataLoading(false));
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          flex: 2,
        }}
      >
        {isGetDataLoading ? (
          <Skeleton active />
        ) : (
          <Table
            rowKey={"_id"}
            dataSource={surveyTemplates}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
              },
              {
                title: "Order",
                dataIndex: "order",
              },
              {
                title: "Status",
                dataIndex: "active",
                sorter: (a, b) => {
                  if (a.active && !b.active) {
                    return 1;
                  }
                  return -1;
                },
                render: (active, surveyTemplate) => (
                  <Switch
                    unCheckedChildren="Inactive"
                    checkedChildren="Active"
                    checked={active}
                    loading={isActivateLoading}
                    style={{
                      width: 80,
                    }}
                    onChange={(activate) => {
                      setIsActivateLoading(true);
                      if (activate) {
                        const req: EnableSurveyTemplateRequest = {
                          surveyTemplateId: surveyTemplate._id,
                        };
                        enableSurveyTemplate(req)
                          .then((res) => {
                            if (!res.success) throw new Error(res.message);

                            const newSurveyTemplates = surveyTemplates?.map(
                              (st) => {
                                if (st._id === surveyTemplate._id) {
                                  st.active = activate;
                                }
                                return st;
                              }
                            );

                            setSurveyTemplates(newSurveyTemplates);
                          })
                          .catch((err) => message.error(err))
                          .finally(() => setIsActivateLoading(false));
                      } else {
                        const req: DisableSurveyTemplateRequest = {
                          surveyTemplateId: surveyTemplate._id,
                        };
                        disableSurveyTemplate(req)
                          .then((res) => {
                            if (!res.success) throw new Error(res.message);

                            const newSurveyTemplates = surveyTemplates?.map(
                              (st) => {
                                if (st._id === surveyTemplate._id) {
                                  st.active = activate;
                                }
                                return st;
                              }
                            );

                            setSurveyTemplates(newSurveyTemplates);
                          })
                          .catch((err) => message.error(err))
                          .finally(() => setIsActivateLoading(false));
                      }
                    }}
                  />
                ),
              },
              {
                title: "Number of questions",
                dataIndex: "questions",
                render: (questions) => questions.length,
              },
              {
                title: "Last updated",
                dataIndex: "updatedAt",
                render: (updatedAt) => parseTime(updatedAt),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}
