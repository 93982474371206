export enum CompanyUserLayoutKeys {
  companyUserLayout_surveysCampaignsString = "companyUserLayout_surveysCampaignsString",
  companyUserLayout_reportString = "companyUserLayout_reportString",
  companyUserLayout_morningGreetingString = "companyUserLayout_morningGreetingString",
  companyUserLayout_afternoonGreetingString = "companyUserLayout_afternoonGreetingString",
  companyUserLayout_eveningGreetingString = "companyUserLayout_eveningGreetingString",
  companyUserLayout_profileDropDownString = "companyUserLayout_profileDropDownString",
  companyUserLayout_settingsDropDownString = "companyUserLayout_settingsDropDownString",
  companyUserLayout_logoutDropDownString = "companyUserLayout_logoutDropDownString",
}
