import { SurveyCampaignKeys } from "./keys";

export const englishValues = {
  [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]:
    "Survey Campaigns",
  [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: "Search",
  [SurveyCampaignKeys.surveyCampaigns_statusString]: "Status",
  [SurveyCampaignKeys.surveyCampaigns_surveyString]: "Survey",
  [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "All",
  [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "All",
  [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: "Name",
  [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: "Survey",
  [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: "Created at",
  [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]:
    "Select a survey campaign from the left",
};

export const malayValues = {
  [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]: "Kempen Tinjauan",
  [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: "Cari",
  [SurveyCampaignKeys.surveyCampaigns_statusString]: "Status",
  [SurveyCampaignKeys.surveyCampaigns_surveyString]: "Tinjauan",
  [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "Semua",
  [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "Semua",
  [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: "Nama",
  [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: "Tinjauan",
  [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: "Dicipta pada",
  [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]:
    "Sila pilih kempen tinjauan di sebelah kiri",
};

export const chineseSimplifiedValues = {
  [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]: "调查活动",
  [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: "搜索",
  [SurveyCampaignKeys.surveyCampaigns_statusString]: "状态",
  [SurveyCampaignKeys.surveyCampaigns_surveyString]: "调查",
  [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "全部",
  [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "全部",
  [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: "名称",
  [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: "调查",
  [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: "创建于",
  [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]:
    "请从左侧选择调查活动",
};

export const chineseTraditionalValues = {
  [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]: "調查活動",
  [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: "搜索",
  [SurveyCampaignKeys.surveyCampaigns_statusString]: "狀態",
  [SurveyCampaignKeys.surveyCampaigns_surveyString]: "調查",
  [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "全部",
  [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "全部",
  [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: "名稱",
  [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: "調查",
  [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: "創建於",
  [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]:
    "請從左側選擇調查活動",
};
