import {
  Breadcrumb,
  Card,
  message,
  Modal,
  PageHeader,
  Skeleton,
  Steps,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { SessionContext } from "../../App";
import {
  CreateSurveyCampaignRequest,
  CreateSurveyResponseFromSurveyCampaignIdRequest,
  GetSurveyCampaignBySurveyCampaignIdRequest,
  GetSurveyResponseBySurveyCampaignIdRequest,
  GetSurveyTemplatesRequest,
  StartSurveyCampaignRequest,
  UpdateSurveyCampaignRequest,
} from "../../constants/apiRequestResponse";
import {
  SurveyCampaign,
  SurveyCampaignStatusLabels,
  SurveyEmployee,
  SurveyTemplate,
} from "../../constants/types";
import {
  createSurveyCampaign,
  createSurveyResponsesFromSurveyCampaignId,
  getSurveyCampaignBySurveyCampaignId,
  getSurveyResponsesBySurveyCampaignId,
  getSurveyTemplates,
  startSurveyCampaign,
  updateSurveyCampaign,
} from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam, parseTime, setQueryParam } from "../../util/index";
import BasicInfo from "./BasicInfo";
import Preview from "./Preview";
import styles from "./SurveyCampaignFormPage.module.css";
import UploadEmployees from "./UploadEmployees";

type Param = {
  surveyTemplateName: string;
};

export enum CreateSurveyCampaignStep {
  BasicInfo,
  UploadEmployees,
  Preview,
}

export default function SurveyCampaignFormPage() {
  const context = useContext(SessionContext);
  const history = useHistory();
  const params = useParams<Param>();
  const { t } = useTranslation();

  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";

  const [isLoading, setIsLoading] = useState(false);
  const [surveyTemplate, setSurveyTemplate] = useState<
    SurveyTemplate | undefined
  >();
  const [currentStep, setCurrentStep] = useState(
    Number(getQueryParam("step")) || CreateSurveyCampaignStep.BasicInfo,
  );
  const [surveyEmployees, setSurveyEmployees] = useState<SurveyEmployee[]>([]);
  const [surveyCampaign, setSurveyCampaign] = useState<SurveyCampaign>({
    _id: surveyCampaignId,
    surveyTemplateId: "",
    companyId: context?.company?._id,
    name: "",
    status: SurveyCampaignStatusLabels.draft,
    questions: [],
  });
  const [originalSurveyCampaign, setOriginalSurveyCampaign] =
    useState<SurveyCampaign>({
      _id: surveyCampaignId,
      surveyTemplateId: "",
      companyId: context?.company?._id,
      name: "",
      status: SurveyCampaignStatusLabels.draft,
      questions: [],
    });

  const isEditMode = window.location.pathname.includes("edit");

  const getData = useCallback(() => {
    setIsLoading(true);

    if (isEditMode) {
      const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
        surveyCampaignId: surveyCampaignId,
      };

      getSurveyCampaignBySurveyCampaignId(req1)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          setSurveyCampaign(res.surveyCampaign);
          setOriginalSurveyCampaign(res.surveyCampaign);
        })
        .catch((err) => {
          message.error(err.toString());
        })
        .finally(() => setIsLoading(false));

      const req2: GetSurveyResponseBySurveyCampaignIdRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      getSurveyResponsesBySurveyCampaignId(req2)
        .then((res) => {
          if (!res.success) throw new Error(res.message);

          const _surveyEmployees = res.surveyResponses?.map((sr) => {
            return sr.surveyEmployee;
          });

          setSurveyEmployees(_surveyEmployees);
        })
        .catch((err) => {
          message.error(err.toString());
        })
        .finally(() => setIsLoading(false));
    }

    const req: GetSurveyTemplatesRequest = {
      name: params.surveyTemplateName,
    };

    getSurveyTemplates(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        const st = res.surveyTemplates[0];
        setSurveyTemplate(st);

        setSurveyCampaign((sc) => {
          return {
            ...sc,
            surveyTemplateId: st._id,
          };
        });
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => setIsLoading(false));
  }, [isEditMode, params.surveyTemplateName, surveyCampaignId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSend = async () => {
    message.loading(
      <>{t(SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt)}</>,
    );
    setIsLoading(true);

    try {
      if (isEditMode) {
        const req1: UpdateSurveyCampaignRequest = {
          surveyCampaign: surveyCampaign,
        };
        const res1 = await updateSurveyCampaign(req1);
        if (!res1.success) throw new Error(res1.message);

        const req3: StartSurveyCampaignRequest = {
          surveyCampaignId: surveyCampaign._id,
        };
        await startSurveyCampaign(req3);

        message.success(
          <>
            {t(
              SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt,
            )}
          </>,
        );

        setTimeout(() => {
          history.push(
            `/surveycampaign?surveyCampaignId=${surveyCampaign._id}`,
          );
        }, 1 * 1000);
      } else {
        /**
         * 9/6/2022 daniel.kwok
         * 1. create survey campaign
         */
        const req1: CreateSurveyCampaignRequest = {
          surveyCampaign: surveyCampaign,
        };
        const res1 = await createSurveyCampaign(req1);
        if (!res1.success) throw new Error(res1.message);

        /**
         * 9/6/2022 daniel.kwok
         * 2. create survey responses
         */
        const req2: CreateSurveyResponseFromSurveyCampaignIdRequest = {
          surveyCampaignId: res1.surveyCampaign._id,
          surveyEmployees: surveyEmployees,
        };
        const res2 = await createSurveyResponsesFromSurveyCampaignId(req2);
        if (!res2.success) throw new Error(res2.message);

        /**
         * 9/6/2022 daniel.kwok
         * 3. start survey campaign
         */
        const req3: StartSurveyCampaignRequest = {
          surveyCampaignId: res1.surveyCampaign._id,
        };
        await startSurveyCampaign(req3);

        message.success(
          <>
            {t(
              SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt,
            )}
          </>,
        );

        setTimeout(() => {
          history.push(
            `/surveycampaign?surveyCampaignId=${res1.surveyCampaign._id}`,
          );
        }, 1 * 1000);
      }
    } catch (err) {
      console.log(err);
      message.error(
        typeof err === "string"
          ? err
          : typeof err === "object"
            ? err?.toString()
            : `Something went wrong`,
      );
    }

    setIsLoading(false);
  };

  const onSaveAsDraft = async () => {
    message.loading("Saving survey", 1);

    if (isEditMode) {
      try {
        const req1: UpdateSurveyCampaignRequest = {
          surveyCampaign: surveyCampaign,
        };
        const res1 = await updateSurveyCampaign(req1);
        if (!res1.success) throw new Error(res1.message);

        /**
         * 11/6/2022 daniel.kwok
         * 2. create survey responses
         */
        const req2: CreateSurveyResponseFromSurveyCampaignIdRequest = {
          surveyCampaignId: surveyCampaign._id,
          surveyEmployees: surveyEmployees,
        };
        const res2 = await createSurveyResponsesFromSurveyCampaignId(req2);
        if (!res2.success) throw new Error(res2.message);

        message.success(`Survey campaign saved!`);

        setTimeout(() => {
          history.push(
            `/surveycampaign?surveyCampaignId=${surveyCampaign._id}`,
          );
        }, 1 * 1000);
      } catch (err) {
        message.error(
          typeof err === "string"
            ? err
            : typeof err === "object"
              ? err?.toString()
              : `Something went wrong`,
        );
      }
    } else {
      try {
        /**
         * 11/6/2022 daniel.kwok
         * 1. create survey campaign
         */
        const req1: CreateSurveyCampaignRequest = {
          surveyCampaign: surveyCampaign,
        };
        const res1 = await createSurveyCampaign(req1);

        /**
         * 11/6/2022 daniel.kwok
         * 2. create survey responses
         */
        const req2: CreateSurveyResponseFromSurveyCampaignIdRequest = {
          surveyCampaignId: res1.surveyCampaign._id,
          surveyEmployees: surveyEmployees,
        };
        const res2 = await createSurveyResponsesFromSurveyCampaignId(req2);
        if (!res2.success) throw new Error(res2.message);

        message.success(`Survey campaign saved!`);

        setTimeout(() => {
          history.push(
            `/surveycampaign?surveyCampaignId=${res1.surveyCampaign._id}`,
          );
        }, 1 * 1000);
      } catch (err) {
        message.error(
          typeof err === "string"
            ? err
            : typeof err === "object"
              ? err?.toString()
              : `Something went wrong`,
        );
      }
    }
  };

  if (isLoading) {
    return (
      <div className="page-content">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="page-content">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/surveycampaign`}>
            <>
              {t(
                SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb,
              )}
            </>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/surveycampaign/create/${surveyTemplate?._id}`}>
            {`${surveyTemplate?.name}`}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
        }}
      >
        <PageHeader
          title={
            isEditMode ? (
              `Edit survey campaign`
            ) : (
              <>
                {t(SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString)}
              </>
            )
          }
          onBack={() => history.push("/")}
        />
        <Card>
          {isEditMode ? (
            <>
              <h1>{surveyCampaign?.name}</h1>
              <p>ID: {surveyTemplate?._id}</p>
              <br />
              <p style={{ color: "grey", fontStyle: "italic" }}>
                Last updated {parseTime(surveyCampaign.updatedAt)}
              </p>
            </>
          ) : (
            <>
              <h1>{surveyTemplate?.name}</h1>
              <p>{surveyTemplate?.description}</p>
            </>
          )}
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            minHeight: "50vh",
            justifyContent: "space-between",
          }}
        >
          <div
            className={styles.card}
            style={{
              width: "20%",
            }}
          >
            <Steps
              size="small"
              direction="vertical"
              current={Number(currentStep)}
            >
              <Steps.Step
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString,
                )}
              />
              <Steps.Step
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString,
                )}
              />
              <Steps.Step
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString,
                )}
              />
            </Steps>
          </div>

          <div
            className={styles.card}
            style={{
              width: "80%",
            }}
          >
            {currentStep === CreateSurveyCampaignStep.BasicInfo ? (
              <BasicInfo
                isEditMode={isEditMode}
                onBack={() => {
                  window.history.back();
                }}
                onNext={() => {
                  setCurrentStep(CreateSurveyCampaignStep.UploadEmployees);
                  setQueryParam(
                    "step",
                    CreateSurveyCampaignStep.UploadEmployees,
                  );
                }}
                onChangeSurveyCampaign={(_surveyCampaign) => {
                  setSurveyCampaign((sc) => _surveyCampaign);
                }}
                surveyCampaign={surveyCampaign}
                originalSurveyCampaign={originalSurveyCampaign}
                onFinish={(se) => {
                  setSurveyEmployees(se);
                }}
              />
            ) : currentStep === CreateSurveyCampaignStep.UploadEmployees ? (
              <UploadEmployees
                isEditMode={isEditMode}
                onBack={() => {
                  setCurrentStep(CreateSurveyCampaignStep.BasicInfo);
                  setQueryParam("step", CreateSurveyCampaignStep.BasicInfo);
                }}
                onNext={() => {
                  setCurrentStep(CreateSurveyCampaignStep.Preview);
                  setQueryParam("step", CreateSurveyCampaignStep.Preview);
                }}
                onChangeSurveyEmployees={(_surveyEmployees) => {
                  setSurveyEmployees(_surveyEmployees);
                }}
                surveyEmployees={surveyEmployees}
                onFinish={(se) => {
                  setSurveyEmployees(se);
                }}
                surveyCampaign={surveyCampaign}
              />
            ) : currentStep === CreateSurveyCampaignStep.Preview ? (
              <Preview
                onBack={() => {
                  setCurrentStep(CreateSurveyCampaignStep.UploadEmployees);
                  setQueryParam(
                    "step",
                    CreateSurveyCampaignStep.UploadEmployees,
                  );
                }}
                onSend={() => {
                  Modal.confirm({
                    title: (
                      <>
                        {t(
                          SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt,
                        )}
                      </>
                    ),
                    onOk: onSend,
                  });
                }}
                onSave={() => onSaveAsDraft()}
                surveyEmployees={surveyEmployees}
                surveyCampaign={surveyCampaign}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
