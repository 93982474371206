import { Button, message, Skeleton, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SessionContext } from "../../App";
import background from "../../assets/background.jpg";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import { SurveyTemplate, SurveyTemplateType } from "../../constants/types";
import { getSurveyTemplates } from "../../services/api";
import styles from "./Dashboard.module.css";
import RecentSurveyCampaigns from "./RecentSurveyCampaigns";
import { useTranslation } from "react-i18next";
import { DashboardPageKeys } from "../../services/i8tn/DashboardPage/keys";
import config from "../../config";

export default function DashboardPage() {
  const { t } = useTranslation();
  const context = useContext(SessionContext);
  const [isSurveyTemplatesLoading, setIsSurveyTemplatesLoading] =
    useState(false);
  const [surveyTemplates, setSurveyTemplates] = useState<
    SurveyTemplate[] | undefined
  >();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsSurveyTemplatesLoading(true);
    getSurveyTemplates()
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setSurveyTemplates(res.surveyTemplates);
      })
      .catch((err) => {
        message.error(err);
      })
      .finally(() => setIsSurveyTemplatesLoading(false));
  };

  const renderSurveyTemplate = (
    type: SurveyTemplateType,
    _id: string,
    name: string,
    description: string,
    i: number,
    isComingSoon: boolean = false,
  ) => {
    let url = "#";
    switch (type) {
      case SurveyTemplateType.normal:
        url = `/surveycampaign/create/${name}?step=0`;
        break;
      case SurveyTemplateType.feedback:
        url = `/surveycampaign/create/360/${name}?step=0`;
        break;
    }

    return (
      <div key={_id} className={styles.card}>
        <div style={{ height: "70%", width: "100%" }}>
          <Typography.Title style={{ color: COLORS.PRIMARY_COLOR }}>
            {name}
          </Typography.Title>
          <div>
            <h3 style={{ color: "white" }}>{description}</h3>
          </div>
        </div>
        <div
          style={{
            height: "30%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {isComingSoon ? (
            <Button
              data-testid={`${TEST_IDS.DASHBOARD.survey_template_id}_${i}`}
              type="primary"
              style={{
                fontSize: 15,
              }}
              disabled={true}
            >
              {t(DashboardPageKeys.dashboardPage_comingSoonBtn)}
            </Button>
          ) : (
            <Link to={url}>
              <Button
                data-testid={`${TEST_IDS.DASHBOARD.survey_template_id}_${name}`}
                type="primary"
                style={{
                  fontSize: 15,
                }}
              >
                {t(DashboardPageKeys.dashboardPage_createBtn)}
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="page-content"
      style={{
        backgroundImage: `url(${background})`,
        backgroundBlendMode: "lighten",
      }}
    >
      <div
        className={styles.frosted}
        style={{
          width: "70%",
          margin: "auto",
          gap: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
          }}
        >
          <div style={{ flex: 5 }}>
            <img height={100} src={context?.company?.logo} alt="" />
            <h1 style={{ color: "white", fontSize: 30, fontWeight: 300 }}>
              <>{t(DashboardPageKeys.dashboardPage_whatSurveyString)}</>
            </h1>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                // gap: '10%',
              }}
            >
              {isSurveyTemplatesLoading ? (
                /**
                 * 22/8/2022 daniel.kwok
                 * Statically add 3 skeletons for surveytemplates when loading
                 * Optimally should load as many skeletons as there are surveytemplates
                 */
                <>
                  <Skeleton.Button
                    className={styles.card}
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    active
                  />
                  <Skeleton.Button
                    className={styles.card}
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    active
                  />
                  <Skeleton.Button
                    className={styles.card}
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    active
                  />
                </>
              ) : (
                <>
                  {surveyTemplates
                    ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                    .map((surveyTemplate, i) => {
                      return renderSurveyTemplate(
                        surveyTemplate.type,
                        surveyTemplate._id,
                        surveyTemplate.name,
                        surveyTemplate.description,
                        i,
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
        <RecentSurveyCampaigns />
      </div>

      <br />

      <div
        style={{
          backgroundColor: COLORS.BACKGROUND_COLOR,
          borderRadius: 20,
          display: "flex",
          flexDirection: "column",
          padding: 20,
          gap: 50,
          width: "70%",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "row",
            gap: 20,
          }}
        >
          <div style={{ flex: 3 }}>
            <h3
              style={{
                color: COLORS.BACKGROUND_COLOR_DARK,
                fontWeight: "bold",
              }}
            >
              {t(DashboardPageKeys.dashboardPage_howString)}
            </h3>
            <p style={{ color: COLORS.BACKGROUND_COLOR_DARK }}>
              Our user-friendly interface ensures you can create a tailored
              survey experience efficiently. You will find options to create new
              surveys, view existing ones, and analyse responses.
              <br />
              <br />
              Begin by selecting a template that best fits your survey needs.
              For seamless management of your survey content, our platform
              allows you to easily enter participant details, customise emails,
              and track invitations sent, responses received, and any follow-up
              actions required.
              <br />
              <br />
              Empower your decision-making process and unlock actionable
              insights by generating detailed reports from the survey data
              collected and stored within our platform.
            </p>
          </div>
        </div>
      </div>

      <br />
      <div
        style={{
          backgroundColor: COLORS.BACKGROUND_COLOR,
          borderRadius: 20,
          display: "flex",
          flexDirection: "column",
          padding: 20,
          gap: 50,
          width: "70%",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "49%" }}>
              <h4
                style={{
                  color: COLORS.BACKGROUND_COLOR_DARK,
                  fontWeight: "bold",
                }}
              >
                {`#1 ${t(DashboardPageKeys.dashboardPage_howToCreateString)}`}
              </h4>
              <video width="100%" controls>
                {config.env === "production" ? (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-stg.oss-ap-southeast-1.aliyuncs.com/videoGuide/1.How%20do%20I%20create%20a%20survey_%20-%20HD%201080p.mov"
                  />
                ) : (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-prd.oss-ap-southeast-1.aliyuncs.com/videoGuide/1.How%20do%20I%20create%20a%20survey_%20-%20HD%201080p.mov"
                  />
                )}
              </video>
            </div>
            <div style={{ width: "49%" }}>
              <h4
                style={{
                  color: COLORS.BACKGROUND_COLOR_DARK,
                  fontWeight: "bold",
                }}
              >
                {`#2 ${t(DashboardPageKeys.dashboardPage_howToAddString)}`}
              </h4>
              <video width="100%" controls>
                {config.env === "production" ? (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-stg.oss-ap-southeast-1.aliyuncs.com/videoGuide/2.How%20do%20I%20add%20new%20participants%20to%20a%20campaign_%20-%20HD%201080p.mov"
                  />
                ) : (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-prd.oss-ap-southeast-1.aliyuncs.com/videoGuide/2.How%20do%20I%20add%20new%20participants%20to%20a%20campaign_%20-%20HD%201080p.mov"
                  />
                )}
              </video>
            </div>
            <div style={{ width: "49%" }}>
              <h4
                style={{
                  color: COLORS.BACKGROUND_COLOR_DARK,
                  fontWeight: "bold",
                }}
              >
                {`#3 ${t(DashboardPageKeys.dashboardPage_howToRespondString)}`}

                <video width="100%" controls>
                  {config.env === "production" ? (
                    <source
                      type="video/mp4"
                      src="https://tdc-survey-platform-stg.oss-ap-southeast-1.aliyuncs.com/videoGuide/3.How%20do%20I%20respond%20to%20a%20survey_%20-%20HD%201080p.mov"
                    />
                  ) : (
                    <source
                      type="video/mp4"
                      src="https://tdc-survey-platform-prd.oss-ap-southeast-1.aliyuncs.com/videoGuide/3.How%20do%20I%20respond%20to%20a%20survey_%20-%20HD%201080p.mov"
                    />
                  )}
                </video>
              </h4>
            </div>
            <div style={{ width: "49%" }}>
              <h4
                style={{
                  color: COLORS.BACKGROUND_COLOR_DARK,
                  fontWeight: "bold",
                }}
              >
                {`#4 ${t(DashboardPageKeys.dashboardPage_howToViewString)}`}
              </h4>
              <video width="100%" controls>
                {config.env === "production" ? (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-stg.oss-ap-southeast-1.aliyuncs.com/videoGuide/4.How%20do%20I%20view%20reports_%20-%20HD%201080p.mov"
                  />
                ) : (
                  <source
                    type="video/mp4"
                    src="https://tdc-survey-platform-prd.oss-ap-southeast-1.aliyuncs.com/videoGuide/4.How%20do%20I%20view%20reports_%20-%20HD%201080p.mov"
                  />
                )}
              </video>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}
