import { NormalGroupQuestionReportKeys } from "./keys";

export const englishValues = {
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]:
    "Group Report",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]:
    "Survey Campaign",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]:
    "Survey",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]:
    "Last updated",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]:
    "Download as PDF",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: "Mode",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]:
    "Average",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]:
    "Comparison",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]:
    "Not applicable",
};

export const malayValues = {
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]:
    "Laporan Kumpulan",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]:
    "Kempen Tinjauan",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]:
    "Tinjauan",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]:
    "Dikemas kini pada",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]:
    "Muat turun sebagai PDF",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: "Mod",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]:
    "Purata",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]:
    "Perbandingan",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]:
    "Tidak berkaitan",
};
export const chineseSimplifiedValues = {
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]:
    "组报告",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]:
    "调查活动",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]:
    "调查",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]:
    "更新于",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]:
    "下载为PDF",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: "模式",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]:
    "平均",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]:
    "比较",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]:
    "不适用",
};

export const chineseTraditionalValues = {
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]:
    "組報告",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]:
    "調查活動",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]:
    "調查",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]:
    "更新於",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]:
    "下載為PDF",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: "模式",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]:
    "平均",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]:
    "比較",
  [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]:
    "不適用",
};
