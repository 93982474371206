import axios from "axios";
import config from "../../config/";
import {
  AgreeToAgreementResponseRequest,
  AgreeToAgreementResponseResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  CreateAgreementCampaignRequest,
  CreateAgreementCampaignResponse,
  CreateCompanyRequest,
  CreateCompanyResponse,
  CreateSurveyCampaignRequest,
  CreateSurveyCampaignResponse,
  CreateSurveyResponseFromSurveyCampaignIdRequest,
  CreateSurveyResponseFromSurveyCampaignIdResponse,
  DeleteSurveyCampaignRequest,
  DeleteSurveyCampaignResponse,
  DisableSurveyTemplateRequest,
  DisableSurveyTemplateResponse,
  EnableSurveyTemplateRequest,
  EnableSurveyTemplateResponse,
  FinishSurveyCampaignRequest,
  FinishSurveyCampaignResponse,
  GetAgreementCampaignByIDRequest,
  GetAgreementCampaignByIDResponse,
  GetAgreementCampaignsResponse,
  GetAgreementResponsesByAgreementCampaignIDRequest,
  GetAgreementResponsesByAgreementCampaignIDResponse,
  GetAllCompanyUserRequest,
  GetAllCompanyUserResponse,
  GetCompaniesRequest,
  GetCompaniesResponse,
  GetCompanyByCompanyIdRequest,
  GetCompanyByCompanyIdResponse,
  GetCompanyUsersByCompanyIdRequest,
  GetCompanyUsersByCompanyIdResponse,
  GetConstantsRequest,
  GetConstantsResponse,
  GetEmailTemplateByCompanyIdRequest,
  GetEmailTemplateByCompanyIdResponse,
  GetEmailTemplateByEmailTemplateIdRequest,
  GetEmailTemplateByEmailTemplateIdResponse,
  GetEmailTemplateVariablesRequest,
  GetEmailTemplateVariablesResponse,
  GetFeatureFlagsByCompanyIdRequest,
  GetFeatureFlagsByCompanyIdResponse,
  GetProfileByCompanyIdRequest,
  GetProfileByCompanyIdResponse,
  GetProfileResponse,
  GetSurveyCampaign360GroupReportRequest,
  GetSurveyCampaign360GroupReportResponse,
  GetSurveyCampaign360IndividualReportRequest,
  GetSurveyCampaign360IndividualReportResponse,
  GetSurveyCampaign360TeamReportRequest,
  GetSurveyCampaign360TeamReportResponse,
  GetSurveyCampaignByCompanyIdRequest,
  GetSurveyCampaignByCompanyIdResponse,
  GetSurveyCampaignBySurveyCampaignIdRequest,
  GetSurveyCampaignBySurveyCampaignIdResponse,
  GetSurveyCampaignGroupReportRequest,
  GetSurveyCampaignGroupReportResponse,
  GetSurveyCampaignGroupReportV2Request,
  GetSurveyCampaignGroupReportV2Response,
  GetSurveyCampaignIndividualReportRequest,
  GetSurveyCampaignIndividualReportResponse,
  GetSurveyResponseBySurveyCampaignIdRequest,
  GetSurveyResponseBySurveyCampaignIdResponse,
  GetSurveyResponseBySurveyResponseIdRequest,
  GetSurveyResponseBySurveyResponseIdResponse,
  GetSurveyResponseDetailRequest,
  GetSurveyResponseDetailResponse,
  GetSurveyTemplatesBySurveyTemplateIdRequest,
  GetSurveyTemplatesBySurveyTemplateIdResponse,
  GetSurveyTemplatesRequest,
  GetSurveyTemplatesResponse,
  GetUploadTncFilePresignedUrlRequest,
  GetUploadTncFilePresignedUrlResponse,
  IsResetPasswordCodeValidRequest,
  IsResetPasswordCodeValidResponse,
  LoginRequest,
  PrintRequest,
  UpdateFeatureFlagStatusByCompanyIdRequest,
  RefreshAccessTokenResponse,
  ResendSurveyResponseEmailRequest,
  ResendSurveyResponseEmailResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SaveSurveyResponseAsDraftRequest,
  SaveSurveyResponseAsDraftResponse,
  SendResetPasswordEmailRequest,
  SendResetPasswordEmailResponse,
  SignUpRequest,
  SignUpResponse,
  StartSurveyCampaignRequest,
  StartSurveyCampaignResponse,
  UndeleteSurveyCampaignRequest,
  UndeleteSurveyCampaignResponse,
  UpdateCompanyRequest,
  UpdateCompanyResponse,
  UpdateCompanyUserRequest,
  UpdateCompanyUserResponse,
  UpdateEmailTemplateRequest,
  UpdateEmailTemplateResponse,
  UpdateSurveyCampaignRequest,
  UpdateSurveyCampaignResponse,
  UpdateSurveyTemplateRequest,
  UpdateSurveyTemplateResponse,
  UploadCompanyLogoRequest,
  UploadCompanyLogoResponse,
  UploadTncFileRequest,
  UploadTncFileResponse,
  UpdateFeatureFlagStatusByCompanyIdResponse,
  GetReportRequest,
  GetSurveyCampaignBasicInfoRequest,
  GetSurveyCampaignBasicInfoResponse,
} from "../../constants/apiRequestResponse";
import { SurveyResponseStatusLabels } from "../../constants/types";
import {
  LOCAL_STORAGE_KEYS,
  getAccessToken,
  getRefreshToken,
  getRefreshingTokenLock,
  removeAccessToken,
  removeRefreshToken,
  removeRefreshingTokenLock,
  setAccessToken,
  setRefreshToken,
  setRefreshingTokenLock,
} from "../local";

function getHeaders() {
  const AUTH_TOKEN = getAccessToken();
  return {
    "Content-Type": "application/json",
    Accept: "application/json, text/html",
    Authorization: `${AUTH_TOKEN}`,
  };
}

function getHeadersWithoutAuth() {
  return {
    "Content-Type": "application/json",
    Accept: "application/json, text/html",
  };
}

const instance = axios.create({
  baseURL: config.api,
});
instance.interceptors.request.use(
  async (request) => {
    let token = getAccessToken();
    if (!token) return request;

    const jwt = JSON.parse(atob(token.split(".")[1]));

    const { exp } = jwt;

    const now = Math.floor(Date.now() / 1000);
    const BUFFER_IN_SECONDS = 5;
    const expWithBuffer = exp - BUFFER_IN_SECONDS;

    if (now >= expWithBuffer) {
      console.log(
        "Token is expired. Attempting to refresh access token via refresh token..."
      );

      const refreshingTokenLock = getRefreshingTokenLock();

      /**
       * 8/3/2023 daniel.kwok
       * Preventing race condition when there are multiple, simultaneous request to refresh access token
       */
      if (!refreshingTokenLock) {
        await setRefreshingTokenLock();

        await refreshAccessToken()
          .then((res) => {
            if (!res.success) throw res.message;
            setAccessToken(res.accessToken);
            setRefreshToken(res.refreshToken);
          })
          .catch((err) => {
            removeAccessToken();
            removeRefreshToken();
          });

        await removeRefreshingTokenLock();
      }
    }

    token = getAccessToken();
    request.headers.Authorization = `Bearer ${token}`;

    return request;
  },
  function (error) {
    console.log(`Request errored`);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    /**
     * if there are query params, window.location.pathname would include a trailing
     * slash at the back of path.
     * Hence need to take into account path with & without trailing slash ¯\_(ツ)_/¯
     */
    const pathsOmittedFromRedirects = [
      "/login",
      "/reset-password",
      "/login/",
      "/reset-password/",
    ];

    if (error?.response?.status === 401) {
      if (!pathsOmittedFromRedirects.includes(window.location.pathname)) {
        window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
      }
    }

    console.log(error.response);
    return Promise.reject(
      error?.response?.data?.message ||
        error?.response?.data ||
        `Something went wrong. Try again?`
    );
  }
);

function refreshAccessToken(): Promise<RefreshAccessTokenResponse> {
  const RT = getRefreshToken();

  return axios
    .post(`${config.api}/companyUser/access-token`, null, {
      headers: {
        [LOCAL_STORAGE_KEYS.REFRESH_TOKEN]: `Bearer ${RT}`,
      },
    })
    .then((res) => res.data);
}

export const login = (loginRequest: LoginRequest) => {
  return axios.post(`${config.api}/companyUser/login`, loginRequest);
};

export const signup = (
  loginRequest: SignUpRequest
): Promise<SignUpResponse> => {
  return fetch(`${config.api}/companyUser/signup`, {
    method: "POST",
    headers: getHeadersWithoutAuth(),
    body: JSON.stringify(loginRequest),
  }).then((res) => res.json());
};

export const logout = () => {
  removeAccessToken();
  return true;
};

export const getProfile = (): Promise<GetProfileResponse> => {
  return instance.get(`/companyUser/getProfile`).then((res) => res.data);
};

export const getProfileByCompanyId = (
  req: GetProfileByCompanyIdRequest
): Promise<GetProfileByCompanyIdResponse> => {
  return fetch(
    `${config.api}/companyUser/getProfileByCompanyId/${req.companyId || ""}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => {
    /**
     * 13/6/2022 daniel.kwok
     * Quick hack to detect 401 Unauthorized error
     */
    if (res.statusText === "Unauthorized") {
      const response: GetProfileByCompanyIdResponse = {
        success: false,
      };
      return response;
    } else {
      return res.json();
    }
  });
};

export const getAllCompanyUsers = (
  req?: GetAllCompanyUserRequest
): Promise<GetAllCompanyUserResponse> => {
  return instance(`/companyUser/getAll`).then((res) => res.data);
};

export const getCompanyUsersByCompanyId = (
  req?: GetCompanyUsersByCompanyIdRequest
): Promise<GetCompanyUsersByCompanyIdResponse> => {
  return instance
    .get(`/companyUser/getByCompanyId/${req?.companyId}`)
    .then((res) => res.data);
};

export const changeCompanyUserPassword = (
  req?: ChangePasswordRequest
): Promise<ChangePasswordResponse> => {
  return instance
    .post("/companyUser/changePassword", req)
    .then((res) => res.data);
};

export const sendForgotPasswordEmail = (
  req?: SendResetPasswordEmailRequest
): Promise<SendResetPasswordEmailResponse> => {
  return fetch(`${config.api}/companyUser/sendForgotPasswordEmail`, {
    method: "POST",
    headers: getHeadersWithoutAuth(),
    body: JSON.stringify(req),
  }).then((res) => res.json());
};

export const getIsResetPasswordTokenValid = (
  req?: IsResetPasswordCodeValidRequest
): Promise<IsResetPasswordCodeValidResponse> => {
  return fetch(`${config.api}/companyUser/isResetPasswordCodeValid`, {
    method: "POST",
    headers: getHeadersWithoutAuth(),
    body: JSON.stringify(req),
  }).then((res) => res.json());
};

export const resetPassword = (
  req?: ResetPasswordRequest
): Promise<ResetPasswordResponse> => {
  return instance
    .post("/companyUser/resetPassword", req)
    .then((res) => res.data);
};

export const updateCompanyUser = (
  req: UpdateCompanyUserRequest
): Promise<UpdateCompanyUserResponse> => {
  return instance.post("/companyUser/update", req).then((res) => res.data);
};

export const getCompanyByCompanyId = (
  req?: GetCompanyByCompanyIdRequest
): Promise<GetCompanyByCompanyIdResponse> => {
  return instance
    .get(`${config.api}/company/getByCompanyId/${req?.companyId || ""}`)
    .then((res) => res.data);
};

export const createCompany = (
  req: CreateCompanyRequest
): Promise<CreateCompanyResponse> => {
  return instance.post("/company/create", req).then((res) => res.data);
};

export const updateCompany = (
  req: UpdateCompanyRequest
): Promise<UpdateCompanyResponse> => {
  return instance.post("/company/update", req).then((res) => res.data);
};

export const uploadCompanyLogo = (
  req: UploadCompanyLogoRequest
): Promise<UploadCompanyLogoResponse> => {
  return instance.post("/company/uploadLogo", req).then((res) => res.data);
};

export const getAllCompanies = (
  req?: GetCompaniesRequest
): Promise<GetCompaniesResponse> => {
  return instance(`/company/getAll`).then((res) => res.data);
};

export const getSurveyTemplates = (
  req?: GetSurveyTemplatesRequest
): Promise<GetSurveyTemplatesResponse> => {
  const url = new URL(`${config.api}/surveyTemplate/get`);

  if (req?.name) {
    url.searchParams.set("name", req.name.toString());
  }
  if (req?.surveyTemplateId) {
    url.searchParams.set("surveyTemplateId", req.surveyTemplateId.toString());
  }
  if (req?.active) {
    url.searchParams.set("active", req.active.toString());
  }
  if (req?.isGetDeleted) {
    url.searchParams.set("isGetDeleted", req?.isGetDeleted?.toString());
  }

  return instance.get(url.toString()).then((res) => res.data);
};

export const getSurveyTemplateBySurveyTemplateId = (
  req?: GetSurveyTemplatesBySurveyTemplateIdRequest
): Promise<GetSurveyTemplatesBySurveyTemplateIdResponse> => {
  return instance(
    `/surveyTemplate/getBySurveyTemplateId/${req?.surveyTemplateId || ""}`
  ).then((res) => res.data);
};

export const updateSurveyTemplate = (
  req: UpdateSurveyTemplateRequest
): Promise<UpdateSurveyTemplateResponse> => {
  return instance.post(`/surveyTemplate/update`, req).then((res) => res.data);
};

export const disableSurveyTemplate = (
  req: DisableSurveyTemplateRequest
): Promise<DisableSurveyTemplateResponse> => {
  return instance.post(`/surveyTemplate/disable`, req).then((res) => res.data);
};

export const enableSurveyTemplate = (
  req: EnableSurveyTemplateRequest
): Promise<EnableSurveyTemplateResponse> => {
  return instance.post(`/surveyTemplate/enable`, req).then((res) => res.data);
};

export const createSurveyCampaign = (
  req: CreateSurveyCampaignRequest
): Promise<CreateSurveyCampaignResponse> => {
  return instance.post(`/surveyCampaign/create`, req).then((res) => res.data);
};

export const startSurveyCampaign = (
  req: StartSurveyCampaignRequest
): Promise<StartSurveyCampaignResponse> => {
  return instance.post(`/surveyCampaign/start`, req).then((res) => res.data);
};

export const updateSurveyCampaign = (
  req: UpdateSurveyCampaignRequest
): Promise<UpdateSurveyCampaignResponse> => {
  return instance.post(`/surveyCampaign/update`, req).then((res) => res.data);
};

export const finishSurveyCampaign = (
  req: FinishSurveyCampaignRequest
): Promise<FinishSurveyCampaignResponse> => {
  return instance.post(`/surveyCampaign/finish`, req).then((res) => res.data);
};

export const getSurveyCampaignsByCompanyId = (
  req: GetSurveyCampaignByCompanyIdRequest
): Promise<GetSurveyCampaignByCompanyIdResponse> => {
  const url = new URL(
    `${config.api}/surveyCampaign/getByCompanyId/${req?.companyId || ""}`
  );

  if (req.limit) {
    url.searchParams.set("limit", req?.limit?.toString());
  }
  if (req.status) {
    url.searchParams.set("status", req?.status?.toString());
  }
  if (req.skip) {
    url.searchParams.set("skip", req?.skip?.toString());
  }
  if (req.isGetDeleted) {
    url.searchParams.set("isGetDeleted", req?.isGetDeleted?.toString());
  }
  if (req.search) {
    url.searchParams.set("search", req?.search?.toString());
  }
  return instance.get(url.toString()).then((res) => res.data);
};

export const getSurveyCampaignBySurveyCampaignId = (
  req: GetSurveyCampaignBySurveyCampaignIdRequest
): Promise<GetSurveyCampaignBySurveyCampaignIdResponse> => {
  return instance
    .get(`/surveyCampaign/getBySurveyCampaignId/${req?.surveyCampaignId || ""}`)
    .then((res) => res.data);
};

export const deleteSurveyCampaign = (
  req: DeleteSurveyCampaignRequest
): Promise<DeleteSurveyCampaignResponse> => {
  return instance
    .delete(`/surveyCampaign/delete/${req.surveyCampaignId}`)
    .then((res) => res.data);
};

export const undeleteSurveyCampaign = (
  req: UndeleteSurveyCampaignRequest
): Promise<UndeleteSurveyCampaignResponse> => {
  return instance.post(`/surveyCampaign/undelete`, req).then((res) => res.data);
};

/**
 * 13/6/2022 daniel.kwok
 * Creates surveyResponses if not exist.
 * Replaced if do.
 * Think of it as a bulk "create if not exist, update if do"
 * @param req CreateSurveyResponseFromSurveyCampaignIdRequest
 * @returns CreateSurveyResponseFromSurveyCampaignIdResponse
 */
export const createSurveyResponsesFromSurveyCampaignId = (
  req: CreateSurveyResponseFromSurveyCampaignIdRequest
): Promise<CreateSurveyResponseFromSurveyCampaignIdResponse> => {
  return instance
    .post(`/surveyResponse/createBySurveyCampaignId`, req)
    .then((res) => res.data);
};

export const getSurveyResponsesBySurveyCampaignId = (
  req: GetSurveyResponseBySurveyCampaignIdRequest
): Promise<GetSurveyResponseBySurveyCampaignIdResponse> => {
  return instance(
    `/surveyResponse/getBySurveyCampaignId/${req?.surveyCampaignId || ""}`
  )
    .then((res) => res.data)
    .then((res: GetSurveyResponseBySurveyCampaignIdResponse) => {
      const surveyResponses = res.surveyResponses
        ?.map((sr) => {
          /**
           * 9/6/2022 daniel.kwok
           * Might wanna revisit this logic in the future
           */
          sr._isAbleToCancel = ![SurveyResponseStatusLabels.CANCELLED].includes(
            sr.status
          );
          sr._isAbleToReopen = [SurveyResponseStatusLabels.CANCELLED].includes(
            sr.status
          );
          sr._isAbleToResend = ![
            SurveyResponseStatusLabels.CANCELLED,
            SurveyResponseStatusLabels.COMPLETED,
          ].includes(sr.status);
          sr._isAbleToViewReport = ![
            SurveyResponseStatusLabels.CANCELLED,
          ].includes(sr.status);

          sr._totalNumberOfQuestions = sr.responses.length;
          sr._totalNumberOfAnsweredQuestions = sr.responses.filter(
            (r) => r.answer !== null && r.answer !== undefined
          ).length;
          sr._answerProgress =
            sr._totalNumberOfAnsweredQuestions / sr._totalNumberOfQuestions;

          return sr;
        })
        .sort((a, b) => {
          const SORTING_ORDER = {
            [SurveyResponseStatusLabels.EMAIL_FAILED]: 0,
            [SurveyResponseStatusLabels.CANCELLED]: 1,
            [SurveyResponseStatusLabels.PENDING]: 2,
            [SurveyResponseStatusLabels.EMAIL_SENT]: 3,
            [SurveyResponseStatusLabels.COMPLETED]: 4,
            [SurveyResponseStatusLabels.DRAFT]: 5,
          };

          return SORTING_ORDER[a.status] < SORTING_ORDER[b.status] ? 1 : -1;
        });

      res.surveyResponses = surveyResponses;

      return res;
    });
};

export const getSurveyResponseBySurveyResponseId = (
  req: GetSurveyResponseBySurveyResponseIdRequest
): Promise<GetSurveyResponseBySurveyResponseIdResponse> => {
  return instance(
    `/surveyResponse/getBySurveyResponseId/${req?.surveyResponseId || ""}`
  ).then((res) => res.data);
};

export const saveSurveyResponseAsDraft = (
  req: SaveSurveyResponseAsDraftRequest
): Promise<SaveSurveyResponseAsDraftResponse> => {
  return instance
    .post(`/surveyResponse/saveDraft`, req)
    .then((res) => res.data);
};

export const finishSurveyResponse = (
  req: SaveSurveyResponseAsDraftRequest
): Promise<SaveSurveyResponseAsDraftResponse> => {
  return instance.post(`/surveyResponse/finish`, req).then((res) => res.data);
};

export const resendSurveyResponseEmail = (
  req: ResendSurveyResponseEmailRequest
): Promise<ResendSurveyResponseEmailResponse> => {
  return instance.post(`/surveyResponse/resend`, req).then((res) => res.data);
};

export const getSurveyCampaignIndividualReport = (
  req: GetSurveyCampaignIndividualReportRequest
): Promise<GetSurveyCampaignIndividualReportResponse> => {
  return instance(
    `/surveyCampaign/getIndividualReport/${req?.surveyResponseId || ""}`
  ).then((res) => res.data);
};

export const getSurveyCampaignGroupReport = (
  req: GetSurveyCampaignGroupReportRequest
): Promise<GetSurveyCampaignGroupReportResponse> => {
  return instance(
    `/surveyCampaign/getGroupReport/${req?.surveyCampaignId || ""}`
  ).then((res) => res.data);
};
export const getSurveyCampaignBasicInfo = (
  req: GetSurveyCampaignBasicInfoRequest
): Promise<GetSurveyCampaignBasicInfoResponse> => {
  return instance(
    `/surveyCampaign/getBasicInfo/${req?.surveyCampaignId || ""}`
  ).then((res) => res.data);
};

export const getSurveyCampaignGroupReportV2 = (
  req: GetSurveyCampaignGroupReportV2Request
): Promise<GetSurveyCampaignGroupReportV2Response> => {
  return instance(
    `/surveyCampaign/getGroupReport/v2/${req?.surveyCampaignId || ""}`
  ).then((res) => res.data);
};

export const getSurveyCampaign360GroupReport = (
  req: GetSurveyCampaign360GroupReportRequest
): Promise<GetSurveyCampaign360GroupReportResponse> => {
  return instance(
    `/surveyCampaign/get360GroupReport/${req?.surveyCampaignId || ""}`
  ).then((res) => res.data);
};

export const getSurveyCampaign360IndividualReport = (
  req: GetSurveyCampaign360IndividualReportRequest
): Promise<GetSurveyCampaign360IndividualReportResponse> => {
  return instance(
    `/surveyCampaign/get360IndividualReport/${req?.surveyResponseId || ""}`
  ).then((res) => res.data);
};

export const getSurveyCampaign360TeamReport = (
  req: GetSurveyCampaign360TeamReportRequest
): Promise<GetSurveyCampaign360TeamReportResponse> => {
  return instance(
    `/surveyCampaign/get360TeamReport/${req?.surveyCampaignId || ""}`
  ).then((res) => res.data);
};

export const printAsPDF = (req: PrintRequest) => {
  return instance(`/print?url=${req?.url || ""}`, {
    responseType: "blob",
  }).then((res) => showFile(res.data));

  /**
   * https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
   */
  function showFile(blob: Blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = "report.pdf";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
};

export const getEmailTemplatesByCompanyId = (
  req: GetEmailTemplateByCompanyIdRequest
): Promise<GetEmailTemplateByCompanyIdResponse> => {
  return instance(`/emailTemplate/get/${req?.companyId || ""}`).then(
    (res) => res.data
  );
};

export const getEmailTemplateByEmailTemplateId = (
  req: GetEmailTemplateByEmailTemplateIdRequest
): Promise<GetEmailTemplateByEmailTemplateIdResponse> => {
  return instance(
    `/emailTemplate/getByEmailTemplateId/${req?.emailTemplateId || ""}`
  ).then((res) => res.data);
};

export const getEmailTemplateVariables = (
  req: GetEmailTemplateVariablesRequest
): Promise<GetEmailTemplateVariablesResponse> => {
  return instance(`/emailTemplate/variables/get`).then((res) => res.data);
};

export const updateEmailTemplate = (
  req: UpdateEmailTemplateRequest
): Promise<UpdateEmailTemplateResponse> => {
  return instance.post(`/emailTemplate/update`, req).then((res) => res.data);
};

export const getConstants = (
  req: GetConstantsRequest
): Promise<GetConstantsResponse> => {
  return instance
    .get(
      `/constants?keys=${req.keys?.join(",") || ""}&parsed=${
        req.parsed
      }&surveyResponseId=${req.surveyResponseId || ""}`
    )
    .then((res) => res.data);
};
export const getSurveyResponseDetail = (
  req: GetSurveyResponseDetailRequest
): Promise<GetSurveyResponseDetailResponse> => {
  return instance
    .get(`/surveyResponse/detail/${req.surveyResponseId || ""}`)
    .then((res) => res.data);
};

export const getAgreementCampaigns =
  (): Promise<GetAgreementCampaignsResponse> => {
    return instance.get(`/agreementCampaign`).then((res) => res.data);
  };

export const createAgreementCampaign = (
  req: CreateAgreementCampaignRequest
): Promise<CreateAgreementCampaignResponse> => {
  return instance.post(`/agreementCampaign`, req).then((res) => res.data);
};

export const uploadTncFile = (
  req: UploadTncFileRequest
): Promise<UploadTncFileResponse> => {
  return instance
    .post(`/agreementCampaign/uploadTncFile`, req)
    .then((res) => res.data);
};

export const getUploadTncFilePresignedUrl = (
  req: GetUploadTncFilePresignedUrlRequest
): Promise<GetUploadTncFilePresignedUrlResponse> => {
  return instance
    .get(`/agreementCampaign/uploadTncFilePresignedUrl/${req.filename}`)
    .then((res) => res.data);
};

export const getAgreementCampaignByID = (
  req: GetAgreementCampaignByIDRequest
): Promise<GetAgreementCampaignByIDResponse> => {
  return instance.get(`/agreementCampaign/${req.id}`).then((res) => res.data);
};

export const getAgreementResponsesByAgreementCampaignID = (
  req: GetAgreementResponsesByAgreementCampaignIDRequest
): Promise<GetAgreementResponsesByAgreementCampaignIDResponse> => {
  return instance
    .get(`/agreementResponse/${req.agreementCampaignId}`)
    .then((res) => res.data);
};

export const agreeToAgreementResponse = (
  req: AgreeToAgreementResponseRequest
): Promise<AgreeToAgreementResponseResponse> => {
  return instance.post(`/agreementResponse/agree`, req).then((res) => res.data);
};

export const getFeatureFlagsByCompanyId = (
  req: GetFeatureFlagsByCompanyIdRequest
): Promise<GetFeatureFlagsByCompanyIdResponse> => {
  return instance.get(`/featureFlag/${req.companyId}`).then((res) => res.data);
};

export const updateFeatureFlagStatusByCompanyId = (
  req: UpdateFeatureFlagStatusByCompanyIdRequest
): Promise<UpdateFeatureFlagStatusByCompanyIdResponse> => {
  return instance.put(`/featureFlag`, req).then((res) => res.data);
};

export const getReport = (req: GetReportRequest) => {
  const url = new URL(
    `${config.api}/surveyCampaign/report/v3/${req.surveyCampaignId}`
  );

  if (req?.format) {
    url.searchParams.set("format", req.format);
  }

  if (req?.layout) {
    url.searchParams.set("layout", req.layout);
  }

  if (req?.team) {
    url.searchParams.set("team", req.team);
  }

  if (req?.surveyResponseId) {
    url.searchParams.set("surveyResponseId", req.surveyResponseId);
  }

  return instance
    .get(url.toString(), {
      responseType: req.format === "pdf" ? "blob" : undefined,
    })
    .then((res) => {
      if (req.format === "html") {
        return res;
      }
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([res.data], { type: "application/pdf" });

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "report.pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
};
