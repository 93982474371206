import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  message,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../App";
import EditEmailTemplateForm from "../../components/EditEmailTemplate";
import PositionsTag from "../../components/PositionsTag";
import {
  GetEmailTemplateByCompanyIdRequest,
  UpdateEmailTemplateRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import {
  EmailTemplate,
  Positions,
  SurveyCampaign,
  SurveyEmployee,
} from "../../constants/types";
import {
  getEmailTemplatesByCompanyId,
  updateEmailTemplate,
} from "../../services/api";
import { setQueryParam, stringParser } from "../../util";
import { SurveyCampaign360FormKeys } from "../../services/i8tn/SurveyCampaign360Form/keys";
import { EditEmailTemplatePageKeys } from "../../services/i8tn/EditEmailTemplatePage/keys";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";

interface Props {
  onBack: Function;
  onSave: Function;
  onSend: Function;
  surveyEmployees: SurveyEmployee[];
  surveyCampaign: SurveyCampaign;
}

type EmailInputParams = {
  currentUserName?: string;
  participantName?: string;
  companyName?: string;
  surveyCampaignName?: string;
  link?: string;
  email?: string;
};

export default function Preview(props: Props) {
  const context = useContext(SessionContext);

  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplateByPosition, setEmailTemplateByPosition] = useState<{
    [key: string]: EmailTemplate | undefined;
  }>({});
  const [isEditEmailTemplateByPosition, setIsEditEmailTemplateByPosition] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [editorStateByPosition, setEditorStateByPosition] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [isUpdateLoadingByPosition, setIsUpdateLoadingByPosition] = useState<{
    [key: string]: boolean;
  }>({});
  const [lineManager, setLineManager] = useState<SurveyEmployee | undefined>();
  const [team, setTeam] = useState<SurveyEmployee | undefined>();
  const [report, setReport] = useState<SurveyEmployee | undefined>();
  const [participant, setParticipant] = useState<SurveyEmployee | undefined>();

  const { t } = useTranslation();

  const getData = useCallback(() => {
    setIsLoading(true);

    const req: GetEmailTemplateByCompanyIdRequest = {
      companyId: context?.company?._id,
    };
    getEmailTemplatesByCompanyId(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        if (!res.emailTemplates) throw new Error("No email templates found.");

        /**Find respective templates based on surveyTemplateId and position */
        let emailTemplates = res.emailTemplates?.filter((et) => {
          return et.surveyTemplateId === props.surveyCampaign?.surveyTemplateId;
        });
        const lineManagerEmailTemplate = emailTemplates.find(
          (et) => et.position === Positions.LineManager,
        );
        const teamEmailTemplate = emailTemplates.find(
          (et) => et.position === Positions.Team,
        );
        const reportEmailTemplate = emailTemplates.find(
          (et) => et.position === Positions.Reports,
        );

        /**Then find a random participant  */
        const participant = props.surveyEmployees.find(
          (se) => se.participantId === se._id,
        );

        /**Then find the corresponding stakeholders around them */
        const _lineManager = props.surveyEmployees.find(
          (se) => se.position === Positions.LineManager,
        );
        const _team = props.surveyEmployees.find(
          (se) => se.position === Positions.Team,
        );
        const _report = props.surveyEmployees.find(
          (se) => se.position === Positions.Reports,
        );

        setLineManager(_lineManager);
        setTeam(_team);
        setReport(_report);
        setParticipant(participant);

        const lineManagerEmailInputParams: EmailInputParams = {
          participantName: `${participant?.fname} ${participant?.lname}`,
          currentUserName: `${_lineManager?.fname} ${_lineManager?.lname}`,
          companyName: `${context?.company?.name}`,
          email: `${_lineManager?.email}`,
        };
        const parsedLineManagerContent = stringParser(
          lineManagerEmailTemplate?.content || "",
          lineManagerEmailInputParams,
        );
        if (lineManagerEmailTemplate) {
          lineManagerEmailTemplate.content = parsedLineManagerContent;
        }

        const teamEmailInputParams: EmailInputParams = {
          participantName: `${participant?.fname} ${participant?.lname}`,
          currentUserName: `${_team?.fname} ${_team?.lname}`,
          companyName: `${context?.company?.name}`,
          email: `${_team?.email}`,
        };
        const parsedTeamContent = stringParser(
          teamEmailTemplate?.content || "",
          teamEmailInputParams,
        );
        if (teamEmailTemplate) {
          teamEmailTemplate.content = parsedTeamContent;
        }

        const reportEmailInputParams: EmailInputParams = {
          participantName: `${participant?.fname} ${participant?.lname}`,
          currentUserName: `${_report?.fname} ${_report?.lname}`,
          companyName: `${context?.company?.name}`,
          email: `${_report?.email}`,
        };
        const parsedReportContent = stringParser(
          reportEmailTemplate?.content || "",
          reportEmailInputParams,
        );
        if (reportEmailTemplate) {
          reportEmailTemplate.content = parsedReportContent;
        }

        setEmailTemplateByPosition({
          [Positions.LineManager]: lineManagerEmailTemplate,
          [Positions.Team]: teamEmailTemplate,
          [Positions.Reports]: reportEmailTemplate,
        });
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => setIsLoading(false));
  }, [
    context?.company,
    props.surveyEmployees,
    props.surveyCampaign.surveyTemplateId,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSave = (emailTemplate?: EmailTemplate, editorState?: string) => {
    if (!emailTemplate) return null;

    setIsUpdateLoadingByPosition((prev) => ({
      ...prev,
      [emailTemplate.position as string]: true,
    }));

    emailTemplate.content = editorState || "";

    const req: UpdateEmailTemplateRequest = {
      emailTemplate: emailTemplate,
    };

    updateEmailTemplate(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        message.success("Updated");
        setIsEditEmailTemplateByPosition((prev) => ({
          ...prev,
          [emailTemplate.position as string]: false,
        }));

        getData();
      })
      .catch((err) => message.error(err))
      .finally(() => {
        setIsUpdateLoadingByPosition((prev) => ({
          ...prev,
          [emailTemplate.position as string]: false,
        }));
      });
  };

  if (props.surveyEmployees.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          gap: 20,
        }}
      >
        <p> No employees uploaded. </p>
        <Button type="link" onClick={() => props.onBack()}>
          Go back
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <Typography.Title level={3}>
        <>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_previewEmailsTitleString,
          )}
        </>
      </Typography.Title>

      {emailTemplateByPosition[Positions.LineManager] &&
      lineManager &&
      participant ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: 10,
            }}
          >
            <p>
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewSampleEmailString,
                )}
              </>{" "}
              <b>
                {lineManager?.fname} {lineManager?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewFeedbackString,
                )}
              </>{" "}
              <b>
                {participant?.fname} {participant?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewAsString,
                )}
              </>{" "}
              <PositionsTag position={lineManager.position} />
            </p>

            {isEditEmailTemplateByPosition[Positions.LineManager] ? (
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.LineManager as string]: false,
                    }));
                  }}
                >
                  <>
                    {t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}
                  </>
                </Button>
                <Button
                  loading={isUpdateLoadingByPosition[Positions.LineManager]}
                  type="primary"
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.save_email_template_id
                  }
                  onClick={() =>
                    onSave(
                      emailTemplateByPosition[Positions.LineManager],
                      editorStateByPosition[Positions.LineManager],
                    )
                  }
                >
                  <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
                </Button>
              </div>
            ) : (
              <Tooltip
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown,
                )}
              >
                <EditOutlined
                  style={{
                    color: COLORS.PRIMARY_COLOR,
                    fontSize: 18,
                  }}
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.edit_email_template_id
                  }
                  onClick={() => {
                    setQueryParam(
                      "emailTemplateId",
                      emailTemplateByPosition[Positions.LineManager]?._id,
                    );
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.LineManager as string]: true,
                    }));
                  }}
                />
              </Tooltip>
            )}
          </div>
          <Card>
            {isLoading ? (
              <Skeleton />
            ) : isEditEmailTemplateByPosition[Positions.LineManager] ? (
              <EditEmailTemplateForm
                isShowFooter={false}
                onUpdate={(_editorState) =>
                  setEditorStateByPosition((prev) => ({
                    ...prev,
                    [Positions.LineManager as string]: _editorState,
                  }))
                }
              />
            ) : (
              <div
                style={{
                  maxHeight: 600,
                  overflow: "scroll",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    emailTemplateByPosition[Positions.LineManager]?.content ||
                    "",
                }}
              ></div>
            )}
          </Card>
        </div>
      ) : null}

      {emailTemplateByPosition[Positions.Team] && team && participant ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: 10,
            }}
          >
            <p>
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewSampleEmailString,
                )}
              </>{" "}
              <b>
                {team?.fname} {team?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewFeedbackString,
                )}
              </>{" "}
              <b>
                {participant?.fname} {participant?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewAsString,
                )}
              </>{" "}
              <PositionsTag position={team.position} />
            </p>

            {isEditEmailTemplateByPosition[Positions.Team] ? (
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.Team as string]: false,
                    }));
                  }}
                >
                  <>
                    {t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}
                  </>
                </Button>
                <Button
                  loading={isUpdateLoadingByPosition[Positions.Team]}
                  type="primary"
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.save_email_template_id
                  }
                  onClick={() =>
                    onSave(
                      emailTemplateByPosition[Positions.Team],
                      editorStateByPosition[Positions.Team],
                    )
                  }
                >
                  <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
                </Button>
              </div>
            ) : (
              <Tooltip
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown,
                )}
              >
                <EditOutlined
                  style={{
                    color: COLORS.PRIMARY_COLOR,
                    fontSize: 18,
                  }}
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.edit_email_template_id
                  }
                  onClick={() => {
                    setQueryParam(
                      "emailTemplateId",
                      emailTemplateByPosition[Positions.Team]?._id,
                    );
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.Team as string]: true,
                    }));
                  }}
                />
              </Tooltip>
            )}
          </div>
          <Card>
            {isLoading ? (
              <Skeleton />
            ) : isEditEmailTemplateByPosition[Positions.Team] ? (
              <EditEmailTemplateForm
                isShowFooter={false}
                onUpdate={(_editorState) =>
                  setEditorStateByPosition((prev) => ({
                    ...prev,
                    [Positions.Team as string]: _editorState,
                  }))
                }
              />
            ) : (
              <div
                style={{
                  maxHeight: 600,
                  overflow: "scroll",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    emailTemplateByPosition[Positions.Team]?.content || "",
                }}
              ></div>
            )}
          </Card>
        </div>
      ) : null}

      {emailTemplateByPosition[Positions.Reports] && report && participant ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: 10,
            }}
          >
            <p>
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewSampleEmailString,
                )}
              </>{" "}
              <b>
                {report?.fname} {report?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewFeedbackString,
                )}
              </>{" "}
              <b>
                {participant?.fname} {participant?.lname}
              </b>{" "}
              <>
                {t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_previewAsString,
                )}
              </>{" "}
              <PositionsTag position={report.position} />
            </p>

            {isEditEmailTemplateByPosition[Positions.Reports] ? (
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.Reports as string]: false,
                    }));
                  }}
                >
                  <>
                    {t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}
                  </>
                </Button>
                <Button
                  loading={isUpdateLoadingByPosition[Positions.Reports]}
                  type="primary"
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.save_email_template_id
                  }
                  onClick={() =>
                    onSave(
                      emailTemplateByPosition[Positions.Reports],
                      editorStateByPosition[Positions.Reports],
                    )
                  }
                >
                  <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
                </Button>
              </div>
            ) : (
              <Tooltip
                title={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown,
                )}
              >
                <EditOutlined
                  style={{
                    color: COLORS.PRIMARY_COLOR,
                    fontSize: 18,
                  }}
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.edit_email_template_id
                  }
                  onClick={() => {
                    setQueryParam(
                      "emailTemplateId",
                      emailTemplateByPosition[Positions.Reports]?._id,
                    );
                    setIsEditEmailTemplateByPosition((prev) => ({
                      ...prev,
                      [Positions.Reports as string]: true,
                    }));
                  }}
                />
              </Tooltip>
            )}
          </div>
          <Card>
            {isLoading ? (
              <Skeleton />
            ) : isEditEmailTemplateByPosition[Positions.Reports] ? (
              <EditEmailTemplateForm
                isShowFooter={false}
                onUpdate={(_editorState) =>
                  setEditorStateByPosition((prev) => ({
                    ...prev,
                    [Positions.Reports as string]: _editorState,
                  }))
                }
              />
            ) : (
              <div
                style={{
                  maxHeight: 600,
                  overflow: "scroll",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    emailTemplateByPosition[Positions.Reports]?.content || "",
                }}
              ></div>
            )}
          </Card>
        </div>
      ) : null}

      <Typography.Title level={3}>
        <>{t(SurveyCampaign360FormKeys.surveyCampaign360Form_faqString)}</>
      </Typography.Title>
      <Collapse>
        <Collapse.Panel
          header={t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_whatIsLinkString,
          )}
          key="1"
        >
          <p>
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_uniqueLinkString,
              )}
            </>
          </p>
        </Collapse.Panel>
      </Collapse>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Button onClick={(e) => props.onBack()}>
          <>
            {t(SurveyCampaign360FormKeys.surveyCampaign360Form_previewBackBtn)}
          </>
        </Button>
        <div style={{ display: "flex", gap: 10 }}>
          <Button onClick={(e) => props.onSave()}>
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_previewDraftBtn,
              )}
            </>
          </Button>
          <Button onClick={(e) => props.onSend()} type="primary">
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_previewSendBtn,
              )}
            </>
          </Button>
        </div>
      </div>
    </div>
  );
}
