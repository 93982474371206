import { Breadcrumb, Button, message, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GetAgreementCampaignByIDRequest,
  GetAgreementResponsesByAgreementCampaignIDRequest,
} from "../../../constants/apiRequestResponse";
import {
  AgreementCampaign,
  AgreementResponse,
  AgreementResponseStatus,
} from "../../../constants/types";
import {
  getAgreementCampaignByID,
  getAgreementResponsesByAgreementCampaignID,
} from "../../../services/api";
import { getAllQueryParams, parseTime } from "../../../util";

const AgreementCampaignDetails: React.FC = () => {
  const history = useHistory();
  const [isAgreementResponsesLoading, setIsAgreementResponsesLoading] =
    useState(false);
  const [agreementCampaign, setAgreementCampaign] = useState<
    AgreementCampaign | undefined
  >();
  const [agreementResponses, setAgreementResponses] = useState<
    AgreementResponse[]
  >([]);

  const columns = [
    {
      title: "User",
      dataIndex: "companyUserName",
    },
    {
      title: "Company",
      dataIndex: "companyName",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        if (status === AgreementResponseStatus.ACCEPTED) {
          return <Tag color="green">{status}</Tag>;
        }
        return <Tag>{status}</Tag>;
      },
    },
    {
      title: "Accepted At",
      dataIndex: "acceptedAt",
      render: (time) => parseTime(time),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsAgreementResponsesLoading(true);

    const queryParams = getAllQueryParams();

    const req1: GetAgreementCampaignByIDRequest = {
      id: queryParams.agreementCampaignId,
    };

    getAgreementCampaignByID(req1)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setAgreementCampaign(res.agreementCampaign);
      })
      .catch((err) => {
        message.error(err.toString());
      });

    const req2: GetAgreementResponsesByAgreementCampaignIDRequest = {
      agreementCampaignId: queryParams.agreementCampaignId,
    };

    getAgreementResponsesByAgreementCampaignID(req2)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setAgreementResponses(res.agreementResponses || []);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => {
        setIsAgreementResponsesLoading(false);
      });
  };

  const getProgressPercentage = (
    agreementResponses: AgreementResponse[]
  ): string => {
    let accepted = 0;
    agreementResponses.forEach((agreementResponse) => {
      if (agreementResponse.status === AgreementResponseStatus.ACCEPTED) {
        accepted++;
      }
    });
    return `${Math.floor((accepted / agreementResponses.length) * 100) || 0} %`;
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>Agreements</Breadcrumb.Item>
        <Breadcrumb.Item>Agreement Details</Breadcrumb.Item>
        <Breadcrumb.Item>{agreementCampaign?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <br /> <br />
      <Typography.Title level={3}>
        Response rate ({getProgressPercentage(agreementResponses)})
      </Typography.Title>
      <Table
        tableLayout={"auto"}
        loading={isAgreementResponsesLoading}
        rowKey={"_id"}
        columns={columns}
        scroll={{
          x: columns.length * 150,
        }}
        dataSource={agreementResponses}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <Button onClick={() => history.goBack()}>Back</Button>
      </div>
    </div>
  );
};

export default AgreementCampaignDetails;
