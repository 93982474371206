import React, { useEffect, useState } from "react";

type Props = {
  url?: string;
  onBase64Changed: Function;
};
export default function UploadImage(props: Props) {
  const [url, setUrl] = useState<string | undefined>("");
  const [base64, setBase64] = useState("");

  useEffect(() => {
    setUrl(props.url);
  }, [props]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        justifyContent: "flex-start",
      }}
    >
      <input
        type="file"
        name="img"
        accept="image/*"
        onChange={async (e) => {
          const file = e.target.files && e.target.files[0];
          var reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);

            reader.onload = function () {
              const _base64 = reader.result;
              if (_base64) {
                props.onBase64Changed(_base64.toString());
                setBase64(_base64.toString());
              }
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
          }
        }}
      />

      <img src={base64 || url} style={{ height: "auto", width: 100 }} alt="" />
    </div>
  );
}
