import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import shortId from "shortid";
import { read } from "xlsx";
import PositionsTag from "../../components/PositionsTag";
import config from "../../config";
import { Gender, Positions, SurveyEmployee } from "../../constants/types";
import { SurveyCampaign360FormKeys } from "../../services/i8tn/SurveyCampaign360Form/keys";

interface Props {
  onBack: Function;
  onNext: Function;
  onSend: Function;
  onSave: Function;
  onChangeSurveyEmployees: Function;
  surveyEmployees: SurveyEmployee[];
}
enum DrawerMode {
  closed = 0,
  view = 1,
  edit = 2,
  addStakeholder = 3,
  addParticipant = 4,
}

export default function UploadEmployees(props: Props) {
  const [selectedSurveyEmployeeId, setSelectedSurveyEmployeeId] =
    useState<string>("");
  const [drawerMode, setDrawerMode] = useState(DrawerMode.closed);
  const [addStakeholderPositon, setAddStakeholderPosition] = useState<
    Positions | ""
  >("");

  const [form] = Form.useForm();
  const isDrawerVisible = drawerMode !== DrawerMode.closed;
  const { t } = useTranslation();

  const surveyEmployeesByParticipantId: {
    [key: string]: SurveyEmployee[];
  } = {};
  props.surveyEmployees.map((d) => {
    if (!surveyEmployeesByParticipantId[d.participantId || ""]) {
      surveyEmployeesByParticipantId[d.participantId || ""] = [];
    }
    surveyEmployeesByParticipantId[d.participantId || ""].push(d);
    return null;
  });

  function getFormattedName(se?: SurveyEmployee) {
    if (!se) return `Invalid survey employee`;
    return `${se?.fname} ${se?.lname}, ${se?.position}`;
  }

  function PreviewSurveyEmployeeBox(props: { surveyEmployee: SurveyEmployee }) {
    return (
      <Tooltip
        title={t(
          SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClickToEditTooltip
        )}
      >
        <Card
          style={{
            height: 120,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedSurveyEmployeeId(props.surveyEmployee._id || "");
            setDrawerMode(DrawerMode.edit);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minWidth: 80,
              textAlign: "center",
            }}
          >
            <PositionsTag
              position={props.surveyEmployee.position}
              team={props.surveyEmployee.team}
            />
            <p
              style={{
                fontWeight:
                  props.surveyEmployee.position === Positions.Participant
                    ? "bold"
                    : undefined,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.surveyEmployee.fname} {props.surveyEmployee.lname}
            </p>
          </div>
        </Card>
      </Tooltip>
    );
  }

  function PlaceholderSurveyEmployeeBox(props: {
    participant: SurveyEmployee;
    position: Positions;
  }) {
    return (
      <Button
        style={{
          height: 120,
          width: 130,
          padding: 5,
          border: `2px dashed #dbdbdb`,
          color: "grey",
        }}
        onClick={() => {
          setSelectedSurveyEmployeeId(props.participant._id || "");
          setDrawerMode(DrawerMode.addStakeholder);
          setAddStakeholderPosition(props.position);
        }}
      >
        Add {props.position}
      </Button>
    );
  }

  function ParticipantGroupsView() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <p style={{ color: "grey", paddingTop: 50 }}>
          {Object.keys(surveyEmployeesByParticipantId).length}{" "}
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesParticipantsString
          )}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            width: "100%",
            // height: 500,
            flexWrap: "wrap",
            // overflow: 'scroll'
          }}
        >
          <Button
            style={styles.participantGroupPlaceholder}
            onClick={() => {
              setDrawerMode(DrawerMode.addParticipant);
            }}
          >
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddNewParticipantString
              )}
            </>
          </Button>
          {Object.values(surveyEmployeesByParticipantId).length > 0
            ? Object.values(surveyEmployeesByParticipantId).map((datas) => {
                const participant = datas.find(
                  (d) => d.position === Positions.Participant
                );
                if (!participant) return <p>Bad data. No participant found</p>;

                const lineManagers: SurveyEmployee[] = [];
                const teams: SurveyEmployee[] = [];
                const reports: SurveyEmployee[] = [];

                datas.forEach((d) => {
                  if (d.position === Positions.LineManager) {
                    lineManagers.push(d);
                  } else if (d.position === Positions.Team) {
                    teams.push(d);
                  } else if (d.position === Positions.Reports) {
                    reports.push(d);
                  }
                });

                teams.splice(2, 0, participant);

                return (
                  <div
                    key={participant.participantId}
                    style={{
                      border: "solid 3px lightgrey",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 5,
                      }}
                    >
                      <Tooltip
                        title={t(
                          SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearGroupTooltip
                        )}
                      >
                        <CloseOutlined
                          onClick={() => {
                            Modal.confirm({
                              title: (
                                <>
                                  {t(
                                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearGroupPrompt
                                  )}
                                </>
                              ),
                              onOk: () => {
                                onDeleteSurveyEmployee(participant);
                                message.success(`cleared group`);
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        padding: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Line manager */}
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                        }}
                      >
                        {lineManagers.map((lineManager) => (
                          <PreviewSurveyEmployeeBox
                            key={lineManager._id}
                            surveyEmployee={lineManager}
                          />
                        ))}
                        <PlaceholderSurveyEmployeeBox
                          participant={participant}
                          position={Positions.LineManager}
                        />
                      </div>

                      {/* Team and participant*/}
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                        }}
                      >
                        {teams.map((tnp) => (
                          <PreviewSurveyEmployeeBox
                            key={tnp._id}
                            surveyEmployee={tnp}
                          />
                        ))}
                        <PlaceholderSurveyEmployeeBox
                          participant={participant}
                          position={Positions.Team}
                        />
                      </div>

                      {/* Reports */}
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                        }}
                      >
                        {reports.map((report) => (
                          <PreviewSurveyEmployeeBox
                            key={report._id}
                            surveyEmployee={report}
                          />
                        ))}
                        <PlaceholderSurveyEmployeeBox
                          participant={participant}
                          position={Positions.Reports}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {props.surveyEmployees.length > 0 ? (
          <Button
            onClick={() => {
              Modal.confirm({
                title: (
                  <>
                    {t(
                      SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearAllPrompt
                    )}
                  </>
                ),
                onOk: () => {
                  props.onChangeSurveyEmployees([]);
                  message.success(`Cleared all`);
                },
              });
            }}
            danger
          >
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearAllBtn
              )}
            </>
          </Button>
        ) : null}
      </div>
    );
  }

  function onEditSurveyEmployee(d: SurveyEmployee) {
    const index = props.surveyEmployees.findIndex((data) => data._id === d._id);

    let _datas = [...props.surveyEmployees];
    _datas[index] = d;
    props.onChangeSurveyEmployees(_datas);
  }

  function onDeleteSurveyEmployee(d?: SurveyEmployee) {
    if (!d) return null;
    let _datas;
    if (d.position === Positions.Participant) {
      _datas = props.surveyEmployees.filter(
        (data) => data.participantId !== d.participantId
      );
    } else {
      _datas = props.surveyEmployees.filter((data) => data._id !== d._id);
    }
    props.onChangeSurveyEmployees(_datas);
  }

  function onAddSurveyEmployee(d: SurveyEmployee) {
    const _datas = [...props.surveyEmployees, d];
    props.onChangeSurveyEmployees(_datas);
  }

  function onAddParticipant(d: SurveyEmployee) {
    /**
     * 23/8/2022 daniel.kwok
     * Important, append at front, not back.
     */
    const _datas = [d, ...props.surveyEmployees];
    props.onChangeSurveyEmployees(_datas);
  }

  function getDrawer(
    isDrawerVisible: boolean,
    drawerMode: DrawerMode,
    selectedSurveyEmployeeId: string
  ) {
    let selectedParticipant: SurveyEmployee | undefined;

    let formFields;
    switch (drawerMode) {
      case DrawerMode.addParticipant:
        const participantId = shortId();
        const newParticipant: SurveyEmployee = {
          fname: "",
          lname: "",
          email: "",
          team: "",
          position: Positions.Participant,
          gender: "" as Gender,
          _id: participantId,
          participantId: participantId,
        };
        formFields = newParticipant
          ? Object.keys(newParticipant).map((key) => {
              return {
                name: key as string,
                value: newParticipant[key],
              };
            })
          : [];
        form.setFields(formFields);
        return (
          <Drawer
            visible={isDrawerVisible}
            onClose={() => {
              setDrawerMode(0);
            }}
            title={`Adding a participant`}
            destroyOnClose={true}
          >
            <Form
              form={form}
              layout="vertical"
              labelCol={{
                style: {
                  opacity: 0.8,
                },
              }}
              onFinish={(v: SurveyEmployee) => {
                onAddParticipant(v);
                /**
                 * TODO daniel.kwok 5/5/2022
                 * Prevent duplicated entries
                 */
                message.success(`Added participant`);

                setDrawerMode(DrawerMode.closed);

                form?.resetFields();
                form.setFields(formFields);
              }}
            >
              <Form.Item hidden name="position" />
              <Form.Item name="participantId" hidden />
              <Form.Item label="ID" name="_id">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="fname"
                rules={[{ required: true }]}
              >
                <Input placeholder="John" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lname"
                rules={[{ required: true }]}
              >
                <Input placeholder="Doe" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true }]}
              >
                <Input placeholder="johndoe@email.com" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true }]}
              >
                <Select showSearch placeholder={"Male"}>
                  {["Male", "Female"].map((gender) => {
                    return (
                      <Select.Option key={gender} value={gender}>
                        {gender}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Team" name="team">
                <Input placeholder="Logistics" />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 5,
                }}
              >
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Form>
          </Drawer>
        );

      case DrawerMode.addStakeholder:
        /**
         * 25/5/2022 daniel.kwok
         * In this case, selectedSurveyEmployeeId will be a participantId, set by PlaceholderBox
         */
        selectedParticipant = props.surveyEmployees.find(
          (data) => data._id === selectedSurveyEmployeeId
        );

        if (!selectedParticipant) return <p>Unable to find participant</p>;

        const newSurveyEmployee: SurveyEmployee = {
          fname: "",
          lname: "",
          email: "",
          position: addStakeholderPositon,
          gender: "" as Gender,
          team: "",
          _id: shortId(),
          participantId: selectedParticipant._id,
        };

        formFields = newSurveyEmployee
          ? Object.keys(newSurveyEmployee).map((key) => {
              return {
                name: key as string,
                value: newSurveyEmployee[key],
              };
            })
          : [];

        form.setFields(formFields);

        return (
          <Drawer
            visible={isDrawerVisible}
            onClose={() => {
              setDrawerMode(DrawerMode.closed);
            }}
            title={`${t(
              SurveyCampaign360FormKeys.surveyCampaign360Form_addingAString
            )} ${addStakeholderPositon} ${t(
              SurveyCampaign360FormKeys.surveyCampaign360Form_toString
            )} ${getFormattedName(selectedParticipant)}`}
            destroyOnClose={true}
          >
            <Form
              form={form}
              layout="vertical"
              labelCol={{
                style: {
                  opacity: 0.8,
                },
              }}
              onFinish={(v: SurveyEmployee) => {
                onAddSurveyEmployee(v);
                /**
                 * TODO daniel.kwok 5/5/2022
                 * Prevent duplicated entries
                 */
                message.success(`Added ${getFormattedName(v)}`);

                setDrawerMode(DrawerMode.closed);
                setSelectedSurveyEmployeeId("");

                form?.resetFields();
                form.setFields(formFields);
              }}
            >
              <Form.Item label="ID" name="_id">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormPositionString
                )}
                name="position"
                rules={[{ required: true }]}
              >
                <Select disabled>
                  {Object.values(Positions)
                    .filter((pos) => pos !== Positions.Participant)
                    .map((pos) => {
                      return (
                        <Select.Option key={pos} value={pos}>
                          {pos}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="participantId" hidden />
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormFirstNameString
                )}
                name="fname"
                rules={[{ required: true }]}
              >
                <Input placeholder="John" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormLastNameString
                )}
                name="lname"
                rules={[{ required: true }]}
              >
                <Input placeholder="Doe" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormEmailString
                )}
                name="email"
                rules={[{ required: true }]}
              >
                <Input placeholder="johndoe@email.com" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormGenderString
                )}
                name="gender"
                rules={[{ required: true }]}
              >
                <Select showSearch placeholder={"Male"}>
                  {["Male", "Female"].map((gender) => {
                    return (
                      <Select.Option key={gender} value={gender}>
                        {gender}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormTeamString
                )}
                name="team"
              >
                <Input placeholder="Logistics" />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 5,
                }}
              >
                <Button htmlType="submit" type="primary">
                  {t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormSaveBtn
                  )}
                </Button>
              </div>
            </Form>
          </Drawer>
        );

      case DrawerMode.edit:
        /**
         * 25/5/2022 daniel.kwok
         * In this case, selectedSurveyEmployeeId will be a surveyEmployeeId, set by Box
         */
        selectedParticipant = props.surveyEmployees.find(
          (data) => data._id === selectedSurveyEmployeeId
        );

        if (!selectedParticipant) return <p>No data found</p>;
        formFields = Object.keys(selectedParticipant).map((key) => {
          return {
            name: key as string,
            value: selectedParticipant && selectedParticipant[key],
          };
        });

        form.setFields(formFields);

        return (
          <Drawer
            title={`Edit ${getFormattedName(selectedParticipant)}`}
            visible={isDrawerVisible}
            onClose={() => {
              setDrawerMode(0);
            }}
            destroyOnClose={true}
          >
            {selectedParticipant ? (
              <Form
                form={form}
                layout="vertical"
                labelCol={{
                  style: {
                    opacity: 0.8,
                  },
                }}
                onFinish={(v: SurveyEmployee) => {
                  onEditSurveyEmployee(v);
                  /**
                   * TODO daniel.kwok 5/5/2022
                   * Prevent duplicated entries
                   */
                  message.success(`Updated ${getFormattedName(v)}`);

                  setDrawerMode(DrawerMode.closed);

                  form?.resetFields();
                  form.setFields(formFields);
                }}
              >
                <Form.Item label="ID" name="_id">
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormPositionString
                  )}
                  name="position"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {Object.values(Positions)
                      .filter((pos) => pos !== Positions.Participant)
                      .map((pos) => {
                        return (
                          <Select.Option key={pos} value={pos}>
                            {pos}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="participantId" hidden />
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormFirstNameString
                  )}
                  name="fname"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="John" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormLastNameString
                  )}
                  name="lname"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Doe" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormEmailString
                  )}
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="johndoe@email.com" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormGenderString
                  )}
                  name="gender"
                >
                  <Select showSearch placeholder={"Male"}>
                    {["Male", "Female"].map((gender) => {
                      return (
                        <Select.Option key={gender} value={gender}>
                          {gender}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormTeamString
                  )}
                  name="team"
                >
                  <Input placeholder="Logistics" />
                </Form.Item>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 5,
                  }}
                >
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <>
                            {t(
                              SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormConfirmDeletePrompt
                            )}
                          </>
                        ),
                        onOk: () => {
                          onDeleteSurveyEmployee(selectedParticipant);
                          message.success(
                            `Deleted ${getFormattedName(selectedParticipant)}`
                          );
                        },
                      });
                    }}
                    type="link"
                  >
                    <>
                      {t(
                        SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormDeleteBtn
                      )}
                    </>
                  </Button>
                  <Button htmlType="submit" type="primary">
                    <>
                      {t(
                        SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormSaveBtn
                      )}
                    </>
                  </Button>
                </div>
              </Form>
            ) : null}
          </Drawer>
        );

      default:
        break;
    }
  }

  return (
    <div>
      <h1>
        {t(
          SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesTitleString
        )}
      </h1>
      <p>
        {" "}
        {t(
          SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesDownloadString
        )}{" "}
        <a download href={config.assets.employees_360_template_2_xlsx_url}>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSampleExcelLink
          )}
        </a>
        {t(
          SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesExampleString
        )}
      </p>
      <input
        type="file"
        onChange={(e) => {
          try {
            const file = e.target.files && e.target.files[0];

            const COL_RANGES = {
              [Positions.Participant]: {
                start: "A",
                end: "E",
              },
              [Positions.LineManager]: {
                start: "F",
                end: "T",
              },
              [Positions.Team]: {
                start: "U",
                end: "CB",
              },
              [Positions.Reports]: {
                start: "CC",
                end: "EJ",
              },
            };

            if (file) {
              const reader = new FileReader();
              const rABS = !!reader?.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e?.target?.result;
                const wb = read(bstr, { type: rABS ? "binary" : "array" });

                const datas: SurveyEmployee[] = [];

                const sheet = Object.values(wb.Sheets)[0];

                const STARTING_ROW = 3;
                const ENDING_ROW = Math.max(
                  ...Object.keys(sheet)
                    .map((key) => Number(key.replace(/^\D+/g, "")))
                    .filter((n) => n)
                );

                for (let row = STARTING_ROW; row <= ENDING_ROW; row++) {
                  const participantId = shortId();
                  const values = Object.keys(sheet)
                    .filter(
                      (key) => key.replace(/^\D+/g, "") === row.toString()
                    )
                    .map((key) => {
                      return {
                        key,
                        value: sheet[key].v,
                      };
                    });

                  for (let i = 0; i < values.length; i += 5) {
                    /**
                     * 25/5/2022 daniel.kwok
                     * Extract cell col characters, e.g. "AB" from "AB12"
                     */
                    const v = values[i];
                    const char = v.key.replace(/[\d]/g, "");

                    const data: SurveyEmployee = {
                      fname: values[i + 0]?.value,
                      lname: values[i + 1]?.value,
                      email: values[i + 2]?.value,
                      gender: values[i + 3]?.value,
                      position: "",
                      team: values[i + 4]?.value,
                      _id: "",
                      participantId: participantId,
                    };

                    /**
                     * 25/5/2022 daniel.kwok
                     * Figure out position of this data (participant, linemanager etc) based on column position
                     */
                    if (
                      isInbetween(
                        char,
                        COL_RANGES[Positions.Participant].start,
                        COL_RANGES[Positions.Participant].end
                      )
                    ) {
                      data.position = Positions.Participant;
                      data._id = participantId;
                    } else if (
                      isInbetween(
                        char,
                        COL_RANGES[Positions.LineManager].start,
                        COL_RANGES[Positions.LineManager].end
                      )
                    ) {
                      data.position = Positions.LineManager;
                      data._id = shortId();
                    } else if (
                      isInbetween(
                        char,
                        COL_RANGES[Positions.Team].start,
                        COL_RANGES[Positions.Team].end
                      )
                    ) {
                      data.position = Positions.Team;
                      data._id = shortId();
                    } else if (
                      isInbetween(
                        char,
                        COL_RANGES[Positions.Reports].start,
                        COL_RANGES[Positions.Reports].end
                      )
                    ) {
                      data.position = Positions.Reports;
                      data._id = shortId();
                    }

                    datas.push(data);
                  }
                }

                /**
                 * 25/5/2022 daniel.kwok
                 *
                 * Determines if a column in in between 2 other columns
                 * E.g.
                 * C will be between A and D
                 * AB will be between Z and AC
                 *
                 * General philosophy:
                 * Treat columns like a base-26 number. Simply re-convert it back to base-10, and do comparison.
                 */
                function isInbetween(
                  char: string,
                  startChar: string,
                  endChar: string
                ): boolean {
                  const ALPHABETS = [
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "K",
                    "L",
                    "M",
                    "N",
                    "O",
                    "P",
                    "Q",
                    "R",
                    "S",
                    "T",
                    "U",
                    "V",
                    "W",
                    "X",
                    "Y",
                    "Z",
                  ];

                  let charIndex = 0;
                  char.split("").map((c, i) => {
                    const index = ALPHABETS.indexOf(c) + 1;
                    charIndex = charIndex * i * ALPHABETS.length;
                    charIndex += index;
                    return null;
                  });

                  let startCharIndex = 0;
                  startChar.split("").map((c, i) => {
                    const index = ALPHABETS.indexOf(c) + 1;
                    startCharIndex = startCharIndex * i * ALPHABETS.length;
                    startCharIndex += index;
                    return null;
                  });

                  let endCharIndex = 0;
                  endChar.split("").map((c, i) => {
                    const index = ALPHABETS.indexOf(c) + 1;
                    endCharIndex = endCharIndex * i * ALPHABETS.length;
                    endCharIndex += index;
                    return null;
                  });

                  const isInbetween =
                    charIndex >= startCharIndex && charIndex <= endCharIndex;
                  return isInbetween;
                }

                props.onChangeSurveyEmployees(datas);
              };

              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }
          } catch (err) {
            message.error(
              typeof err === "string"
                ? err
                : typeof err === "object"
                ? err?.toString()
                : `Unable to upload template`
            );
          }
        }}
      />
      <p style={{ color: "grey" }}>
        <>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSupportedFormatsString
          )}
        </>
      </p>

      {getDrawer(isDrawerVisible, drawerMode, selectedSurveyEmployeeId)}

      <ParticipantGroupsView />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            onClick={(e) => props.onNext()}
            type="primary"
            disabled={props.surveyEmployees.length === 0}
          >
            <>
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_employeesNextBtn
              )}
            </>
          </Button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  participantGroupPlaceholder: {
    height: "100%",
    minHeight: 430,
    width: 300,
    border: "dashed 3px lightgrey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  participantGroup: {
    border: "solid 3px lightgrey",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};
