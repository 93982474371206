import React from "react";
import { ReportLayouts } from "../../constants/misc";
import { getQueryParam } from "../../util";
import FeedbackGroupPrintView from "./FeedbackGroupPrintView";
import FeedbackIndividualPrintView from "./FeedbackIndividualPrintView";
import NormalGroupPrintView from "./NormalGroupPrintView";
import NormalIndividualPrintView from "./NormalIndividualPrintView";
import FeedbackTeamPrintView from "./FeedbackTeamPrintView";

export default function ReportPrintViewPage() {
  const mode = (getQueryParam("layout") as ReportLayouts) || undefined;

  switch (mode) {
    case ReportLayouts.normalGroupReport:
      return <NormalGroupPrintView />;

    case ReportLayouts.normalIndividualReport:
      return <NormalIndividualPrintView />;

    case ReportLayouts.feedbackGroupReport:
      return <FeedbackGroupPrintView />;

    case ReportLayouts.feedbackIndividualReport:
      return <FeedbackIndividualPrintView />;

    case ReportLayouts.feedbackTeamReport:
      return <FeedbackTeamPrintView />;

    default:
      return <h1>Bad link</h1>;
  }
}
