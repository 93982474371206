import { CheckCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import shortId from "shortid";
import { read } from "xlsx";
import { SessionContext } from "../../App";
import config from "../../config";
import { CreateSurveyResponseFromSurveyCampaignIdRequest } from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import {
  Gender,
  Positions,
  SurveyCampaign,
  SurveyEmployee,
} from "../../constants/types";
import { createSurveyResponsesFromSurveyCampaignId } from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
interface Props {
  onFinish: Function;
  onBack: () => void;
  onNext: () => void;
  onChangeSurveyEmployees: (surveyEmployees: SurveyEmployee[]) => void;
  surveyEmployees: SurveyEmployee[];
  isEditMode: boolean;
  surveyCampaign?: SurveyCampaign;
}

enum DrawerMode {
  closed = 0,
  edit = 2,
  add = 3,
}

/**
 * 30/5/2022 daniel.kwok
 * Custom type, closely resembling SurveyEmployee, but with a couple extra properties
 */
type Row = {
  _id?: string;
  fname: string;
  lname: string;
  email: string;
  gender: Gender;
  position: Positions | string;
  team: string;
  errors: string[];
};

export default function UploadEmployees(props: Props) {
  const context = useContext(SessionContext);

  const [rows, setRows] = useState<Row[]>([]);
  const [selectedSurveyEmployeeId, setSelectedSurveyEmployeeId] = useState<
    string | undefined
  >("");
  const [drawerMode, setDrawerMode] = useState(DrawerMode.closed);
  const [form] = Form.useForm();
  const isDrawerVisible = drawerMode !== DrawerMode.closed;
  const { t } = useTranslation();
  /**
   * 23/4/2022 daniel.kwok
   * Not the best, but manual way of validating excel input
   * Updates the "errors" property of a row.
   * Done here in a seperate function cz it's reused in 2 places.
   */

  /** TODO daniel.kwok
   * convert to i18tn compatible?
   * -sherlyn
   */

  const validateRows = useCallback(
    (rows: Row[]): Row[] => {
      let emails = {};
      const newRows = rows.map((row) => {
        let errors: string[] = [];

        /**Validate fname */
        if (!row.fname) errors.push("Missing First Name");

        /**validate lname */
        if (!row.lname) errors.push("Missing Last Name");

        /**Validate gender input */
        if (![Gender.Male, Gender.Female].includes(row.gender)) {
          errors.push(
            `Gender must be one of "${Gender.Male}" or "${Gender.Female}"`,
          );
        }

        /**Validate email input */
        if (!context?.company?.emailDomains.includes(row.email.split("@")[1])) {
          errors.push(
            `Email must be one of (${context?.company?.emailDomains?.map((ed) => `@${ed}`)})`,
          );
        } else if (emails[row.email]) {
          errors.push(`Duplicated email`);
        }
        emails[row.email] = row.email;

        row.errors = errors;

        return row;
      });

      return newRows;
    },
    [context?.company],
  );

  useEffect(() => {
    const rows = props.surveyEmployees.map((se) => {
      const row: Row = {
        _id: se._id,
        fname: se.fname,
        lname: se.lname,
        email: se.email,
        gender: se.gender,
        position: se.position,
        team: se.team,
        errors: [],
      };

      return row;
    });

    const validatedRows = validateRows(rows);

    setRows(validatedRows);
  }, [props.surveyEmployees, validateRows]);

  const columns = [
    {
      title: "",
      dataIndex: "errors",
      render: (errors: string[]) => {
        return errors.length > 0 ? (
          <CloseCircleFilled style={{ color: "red" }} />
        ) : (
          <CheckCircleOutlined style={{ color: "green" }} />
        );
      },
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader,
          )}
        </>
      ),
      dataIndex: "fname",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader,
          )}
        </>
      ),
      dataIndex: "lname",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader,
          )}
        </>
      ),
      dataIndex: "position",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader,
          )}
        </>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader,
          )}
        </>
      ),
      dataIndex: "team",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader,
          )}
        </>
      ),
      dataIndex: "gender",
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader,
          )}
        </>
      ),
      dataIndex: "errors",
      render: (errors: string[]) => {
        return (
          <span style={{ color: "red", marginRight: 5 }}>
            <ul>
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </span>
        );
      },
    },
    {
      title: (
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader,
          )}
        </>
      ),
      render: (rowData: Row) => {
        return (
          <Button
            onClick={() => {
              setSelectedSurveyEmployeeId(rowData._id);
              setDrawerMode(DrawerMode.edit);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  function onEditRow(row: Row) {
    const index = rows.findIndex((r) => r._id === row._id);
    const _rows = JSON.parse(JSON.stringify(rows));
    _rows[index] = row;

    const validatedRows = validateRows(_rows);

    setRows(validatedRows);
  }

  function onDeleteRow(row?: Row) {
    const _rows = rows.filter((r) => r._id !== row?._id);
    const validatedRows = validateRows(_rows);

    setRows(validatedRows);
  }

  function onAddRow(row: Row) {
    const _rows = [row, ...rows];
    const validatedRows = validateRows(_rows);

    setRows(validatedRows);
  }

  function getDrawer(
    isDrawerVisible: boolean,
    drawerMode: DrawerMode,
    selectedSurveyEmployeeId?: string,
  ) {
    let selectedRow: Row | undefined;
    let formFields;

    switch (drawerMode) {
      case DrawerMode.edit:
        /**
         * 25/5/2022 daniel.kwok
         * In this case, selectedSurveyEmployeeId will be a _id, set by Box
         */
        selectedRow = rows.find(
          (data) => data._id === selectedSurveyEmployeeId,
        );

        if (!selectedRow)
          return (
            <p>
              {t(
                SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString,
              )}
            </p>
          );
        formFields = Object.keys(selectedRow).map((key) => {
          return {
            name: key as string,
            value: selectedRow && selectedRow[key],
          };
        });

        form.setFields(formFields);

        return (
          <Drawer
            title={`Edit ${getFormattedName(selectedRow)}`}
            visible={isDrawerVisible}
            onClose={() => {
              setDrawerMode(0);
            }}
            destroyOnClose={true}
          >
            {selectedRow ? (
              <Form
                form={form}
                layout="vertical"
                labelCol={{
                  style: {
                    opacity: 0.8,
                  },
                }}
                onFinish={(v: Row) => {
                  onEditRow(v);
                  message.success(
                    `${t(SurveyCampaignFormKeys.surveyCampaignForm_updatedString)} ${getFormattedName(v)}`,
                  );

                  setDrawerMode(DrawerMode.closed);
                  setSelectedSurveyEmployeeId("");

                  form?.resetFields();
                  form.setFields(formFields);
                }}
              >
                <Form.Item name="participantId" hidden />
                <Form.Item name="errors" hidden />
                <Form.Item label="ID" name="_id">
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString,
                  )}
                  name="fname"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="John" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString,
                  )}
                  name="lname"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Doe" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString,
                  )}
                  name="email"
                  rules={[
                    /**
                     * 1/6/2022 daniel.kwok
                     * Not sure if company emailDomain validation is required here.
                     * Might need to cross check
                     */
                    {
                      required: true,
                      type: "email",
                      validator: (_, value) => {
                        for (let emailDomain of context?.company
                          ?.emailDomains || []) {
                          const regex = new RegExp(`.+@${emailDomain}$`);
                          if (value.match(regex)) {
                            return Promise.resolve();
                          }
                        }

                        return Promise.reject();
                      },
                      message: `Email must end with one of ${context?.company?.emailDomains.toString()}. E.g. johndoe@${context?.company?.emailDomains[0]}`,
                    },
                  ]}
                >
                  <Input placeholder="johndoe@email.com" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString,
                  )}
                  name="gender"
                  rules={[
                    {
                      required: true,
                      type: "enum",
                      enum: [Gender.Male, Gender.Female],
                    },
                  ]}
                >
                  <Select showSearch placeholder={"Male"} id="gender">
                    {
                      /** TODO daniel.kwok
                       * convert to i18tn compatible?
                       * -sherlyn
                       */
                      ["Male", "Female"].map((gender) => {
                        return (
                          <Select.Option key={gender} value={gender}>
                            {gender}
                          </Select.Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString,
                  )}
                  name="position"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Manager" />
                </Form.Item>
                <Form.Item
                  label={t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString,
                  )}
                  name="team"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Finance" />
                </Form.Item>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 5,
                  }}
                >
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <>
                            {t(
                              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt,
                            )}
                          </>
                        ),
                        onOk: () => {
                          onDeleteRow(selectedRow);
                          message.success(
                            `${t(SurveyCampaignFormKeys.surveyCampaignForm_deletedString)} ${getFormattedName(selectedRow)}`,
                          );
                        },
                      });
                    }}
                    type="link"
                  >
                    <>
                      {t(
                        SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn,
                      )}
                    </>
                  </Button>
                  <Button htmlType="submit" type="primary">
                    <>
                      {t(
                        SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn,
                      )}
                    </>
                  </Button>
                </div>
              </Form>
            ) : null}
          </Drawer>
        );

      case DrawerMode.add:
        selectedRow = {
          _id: shortId(),
          fname: "",
          lname: "",
          email: "",
          gender: "" as Gender,
          position: "",
          team: "",
          errors: [],
        };
        formFields = Object.keys(selectedRow).map((key) => {
          return {
            name: key as string,
            value: selectedRow && selectedRow[key],
          };
        });

        form.setFields(formFields);
        return (
          <Drawer
            title={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString,
            )}
            visible={isDrawerVisible}
            onClose={() => {
              setDrawerMode(0);
            }}
            destroyOnClose={true}
          >
            <Form
              form={form}
              layout="vertical"
              labelCol={{
                style: {
                  opacity: 0.8,
                },
              }}
              onFinish={(v: Row) => {
                onAddRow(v);
                /**
                 * TODO daniel.kwok 5/5/2022
                 * Prevent duplicated entries
                 */

                message.success(
                  `${t(SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString)} ${getFormattedName(v)}`,
                );

                setDrawerMode(DrawerMode.closed);

                form?.resetFields();
                form.setFields(formFields);
              }}
            >
              <Form.Item name="participantId" hidden />
              <Form.Item name="errors" hidden />
              <Form.Item label="ID" name="_id">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString,
                )}
                name="fname"
                rules={[{ required: true }]}
              >
                <Input placeholder="John" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString,
                )}
                name="lname"
                rules={[{ required: true }]}
              >
                <Input placeholder="Doe" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString,
                )}
                name="email"
                rules={[
                  /**
                   * 1/6/2022 daniel.kwok
                   * Not sure if company emailDomain validation is required here.
                   * Might need to cross check
                   */
                  {
                    required: true,
                    type: "email",
                    validator: (_, value) => {
                      for (let emailDomain of context?.company?.emailDomains ||
                        []) {
                        const regex = new RegExp(`.+@${emailDomain}$`);
                        if (value.match(regex)) {
                          return Promise.resolve();
                        }
                      }

                      return Promise.reject();
                    },
                    message: `${t(SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString)} ${context?.company?.emailDomains.toString()} ${t(SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString)} ${context?.company?.emailDomains[0]}`,
                  },
                ]}
              >
                <Input placeholder="johndoe@email.com" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString,
                )}
                name="gender"
                rules={[
                  {
                    required: true,
                    type: "enum",
                    enum: [Gender.Male, Gender.Female],
                  },
                ]}
              >
                <Select showSearch placeholder={"Male"}>
                  {
                    /** TODO daniel.kwok
                     * convert to i18tn compatible?
                     * -sherlyn
                     */
                    ["Male", "Female"].map((gender) => {
                      return (
                        <Select.Option key={gender} value={gender}>
                          {gender}
                        </Select.Option>
                      );
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString,
                )}
                name="position"
                rules={[{ required: true }]}
              >
                <Input placeholder="Manager" />
              </Form.Item>
              <Form.Item
                label={t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString,
                )}
                name="team"
                rules={[{ required: true }]}
              >
                <Input placeholder="Finance" />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          {t(
                            SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt,
                          )}
                        </>
                      ),
                      onOk: () => {
                        onDeleteRow(selectedRow);
                        message.success(
                          `${t(SurveyCampaignFormKeys.surveyCampaignForm_deletedString)} ${getFormattedName(selectedRow)}`,
                        );
                      },
                    });
                  }}
                  type="link"
                >
                  <>
                    {t(
                      SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn,
                    )}
                  </>
                </Button>
                <Button htmlType="submit" type="primary">
                  <>
                    {t(
                      SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn,
                    )}
                  </>
                </Button>
              </div>
            </Form>
          </Drawer>
        );

      default:
        break;
    }
  }

  function getFormattedName(se?: Row) {
    if (!se) return `Invalid survey employee`;
    return `${se?.fname} ${se?.lname}, ${se?.position}`;
  }

  function getErrorCount(rows: Row[]): number {
    return rows.filter((row) => row.errors.length > 0).length;
  }

  function onFinish() {
    /**
     * 1/6/2022 daniel.kwok
     * Cast row to surveyemployee
     */
    const surveyEmployees = rows.map((row) => {
      const se: SurveyEmployee = {
        _id: row._id,
        fname: row.fname,
        lname: row.lname,
        email: row.email,
        gender: row.gender,
        position: row.position as Positions,
        team: row.team,
      };

      return se;
    });
    props.onChangeSurveyEmployees(surveyEmployees);
    props.onNext();
  }

  async function onSave() {
    const surveyEmployees = rows.map((row) => {
      const se: SurveyEmployee = {
        _id: row._id,
        fname: row.fname,
        lname: row.lname,
        email: row.email,
        gender: row.gender,
        position: row.position as Positions,
        team: row.team,
      };

      return se;
    });

    const req: CreateSurveyResponseFromSurveyCampaignIdRequest = {
      surveyCampaignId: props.surveyCampaign?._id,
      surveyEmployees: surveyEmployees,
    };
    createSurveyResponsesFromSurveyCampaignId(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        message.success("Updated");
        window.history.back();
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const errorCount = getErrorCount(rows);

  return (
    <div>
      <h1>
        {t(
          SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString,
        )}
      </h1>
      <p>
        {" "}
        {t(
          SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString,
        )}{" "}
        <a download href={config.assets.employees_template_csv_url}>
          {" "}
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink,
          )}{" "}
        </a>
        {t(
          SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString,
        )}
      </p>
      <input
        type="file"
        onChange={(e) => {
          /**
           * 1/6/2022 daniel.kwok
           * It's possible for user to manually create employees, but then decide to upload.
           * The correct behavior would be to override it.
           * However, warn user before proceeding first.
           */
          if (rows.length > 0) {
            Modal.confirm({
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt,
                  )}
                </>
              ),
              onOk: () => {
                parseFile(e);
              },
            });
          } else {
            parseFile(e);
          }

          function parseFile(e: React.ChangeEvent<HTMLInputElement>) {
            const HEADERS = [
              "First Name",
              "Last Name",
              "Position",
              "Email",
              "Gender",
              "Team",
            ];
            const file = e.target.files && e.target.files[0];

            if (file) {
              const reader = new FileReader();
              const rABS = !!reader?.readAsBinaryString;
              reader.onload = (e) => {
                try {
                  setRows([]);
                  const bstr = e?.target?.result;
                  const wb = read(bstr, { type: rABS ? "binary" : "array" });

                  const sheet = Object.values(wb.Sheets)[0];

                  const headers = ["A1", "B1", "C1", "D1", "E1", "F1"].map(
                    (char) => {
                      const col = sheet[char];
                      return col?.v;
                    },
                  );

                  console.log({ headers, HEADERS });

                  if (JSON.stringify(headers) !== JSON.stringify(HEADERS)) {
                    throw new Error(`Wrong template`);
                  }

                  const STARTING_ROW = 2;
                  const ENDING_ROW = Math.max(
                    ...Object.keys(sheet)
                      .map((key) => Number(key.replace(/^\D+/g, "")))
                      .filter((n) => n),
                  );

                  let _rows: Row[] = [];
                  for (let row = STARTING_ROW; row <= ENDING_ROW; row++) {
                    /**
                     * 1/6/2022 daniel.kwok
                     *
                     * Possible for a cell to be empty.
                     * Hence need fallback value of empty string, ''
                     */
                    const r: Row = {
                      _id: shortId(),
                      fname: sheet[`A${row}`]?.v || "",
                      lname: sheet[`B${row}`]?.v || "",
                      position: sheet[`C${row}`]?.v || "",
                      email: sheet[`D${row}`]?.v || "",
                      gender: sheet[`E${row}`]?.v || "",
                      team: sheet[`F${row}`]?.v || "",
                      errors: [],
                    };
                    _rows.push(r);
                  }

                  const validatedRows = validateRows(_rows).sort((a, b) => {
                    return a.errors.length > b.errors.length ? -1 : 0;
                  });

                  setRows(validatedRows);
                } catch (err) {
                  console.log(err);
                  message.error(
                    typeof err === "string"
                      ? err
                      : typeof err === "object"
                        ? err?.toString()
                        : `Something went wrong`,
                  );
                }
              };

              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }
          }
        }}
      />
      <p style={{ color: "grey" }}>
        <>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString,
          )}
        </>
      </p>

      {getDrawer(isDrawerVisible, drawerMode, selectedSurveyEmployeeId)}

      {rows.length > 0 ? (
        errorCount > 0 ? (
          <Alert
            showIcon
            message={`${errorCount} ${t(SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString)}`}
            type="error"
          />
        ) : (
          <Alert
            showIcon
            message={t(
              SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt,
            )}
            type="success"
          />
        )
      ) : null}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          onClick={() => {
            setDrawerMode(DrawerMode.add);
          }}
          data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.add_an_employee_btn_id}
        >
          <>{t(SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn)}</>
        </Button>
      </div>
      <div>
        <Table
          rowKey="_id"
          size="small"
          columns={columns}
          dataSource={rows}
          scroll={{
            x: columns.length * 100,
          }}
          pagination={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <Button onClick={() => props.onBack()} type="link">
          <>{t(SurveyCampaignFormKeys.surveyCampaignForm_backBtn)}</>
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 30,
            gap: 10,
          }}
        >
          {props.isEditMode ? (
            <Button
              onClick={() => onSave()}
              disabled={errorCount > 0 || rows.length === 0}
            >
              <>{t(SurveyCampaignFormKeys.surveyCampaignForm_saveBtn)}</>
            </Button>
          ) : null}
          <Button
            onClick={(e) => {
              onFinish();
            }}
            type="primary"
            disabled={errorCount > 0 || rows.length === 0}
          >
            <>{t(SurveyCampaignFormKeys.surveyCampaignForm_nextBtn)}</>
          </Button>
        </div>
      </div>
    </div>
  );
}
