import { EditEmailTemplatePageKeys } from "./keys";

export const englishValues = {
  [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]:
    "Edit email template",
  [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: "Name",
  [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]:
    "Linked survey template",
  [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: "Subject",
  [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: "Content",
  [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: "Variables",
  [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]:
    "Introduce dynamic values into your email template.",
  [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]:
    "Variable name",
  [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]:
    "Description",
  [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: "Cancel",
  [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: "Save",
  [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]:
    "This will only affect upcoming emails, and not past emails.",
};

export const malayValues = {
  [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]:
    "Edit templat e-mel",
  [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: "Nama",
  [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]:
    "Templat tinjauan berpautan",
  [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: "Subjek",
  [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: "Kandungan",
  [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: "Pembolehubah",
  [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]:
    "Masukkan kandungan dinamik ke dalam templat e-mel anda.",
  [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]:
    "Nama pembolehubah",
  [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]:
    "Penghuraian",
  [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: "Batal",
  [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: "Simpan",
  [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]:
    "Tindakan ini akan mengubah e-mel yang baharu sahaja, dan tidak akan menjejaskan e-mel sebelum ini.",
};

export const chineseSimplifiedValues = {
  [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]:
    "编辑电子邮件模板",
  [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: "名称",
  [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]:
    "关联调查模板",
  [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: "主题",
  [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: "内容",
  [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: "变量",
  [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]:
    "在您的电子邮件模板中插入动态内容。",
  [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]:
    "变量名称",
  [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]: "描述",
  [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: "取消",
  [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: "保存",
  [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]:
    "此操作将仅更改新电子邮件，不会影响之前的电子邮件。",
};

export const chineseTraditionalValues = {
  [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]:
    "編輯電子郵件模板",
  [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: "名稱",
  [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]:
    "關聯調查模板",
  [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: "主題",
  [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: "內容",
  [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: "變量",
  [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]:
    "在您的電子郵件模板中插入動態內容。",
  [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]:
    "變量名稱",
  [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]: "描述",
  [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: "取消",
  [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: "保存",
  [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]:
    "此操作將僅更改新電子郵件，不會影響之前的電子郵件。",
};
